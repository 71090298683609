import React from 'react'
import styles from './Divider.module.scss'
import PropTypes from 'prop-types'

export const Divider = props => (
  <div
    className={`${styles.wrapper} ${props.border ? styles._border : ''} ${
      props.small ? styles._small : ''
    }`}
  />
)

Divider.propTypes = {
  border: PropTypes.bool,
  small: PropTypes.bool,
}

Divider.defaultProps = {
  border: false,
  small: false,
}
