import React from 'react'

export default class HomePage extends React.Component {
  componentDidMount() {
    window.location.href = 'https://www.icipresent.com/'
  }

  render() {
    return <div className="page">Redirecting...</div>
  }
}
