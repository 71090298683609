import React, { useCallback, useState } from 'react'
import { LayoutFixed, MenuSecondary } from 'ui'
import { ExpeditionForm } from '../components'
import { SupplierNavigation } from 'features/suppliers/components'
import { getMenuLinks } from 'features/suppliers/configuration/@commons'
import { NotificationManager } from 'services/notifications'
import {
  getExpeditionConfiguration,
  updateExpeditionConfiguration,
} from 'services/suppliers'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

const Expedition = props => {
  const [state, setState] = useState({
    config: {
      shippingTime: 0,
      info: '',
      useUnknownShipper: false,
      useColissimo: false,
    },
    allShippers: [],
    activeShippers: [],
    loading: false,
  })

  const onDataLoaded = ({ result }) => {
    setState({
      ...state,
      config: result.config,
      allShippers: result.allShippers,
      activeShippers: result.activeShippers,
    })
  }

  const onFormSubmit = (config, activeShippers) => {
    setState({
      ...state,
      loading: true,
    })

    return waitForApi(
      updateExpeditionConfiguration(supplierKey(), config, activeShippers).then(
        () => getExpeditionConfiguration(supplierKey())
      )
    )
      .then(onDataSuccess)
      .then(onSubmitSuccess)
      .catch(onError)
  }

  const onDataSuccess = ({ result }) => {
    setState({
      ...state,
      config: result.config,
      allShippers: result.allShippers,
      activeShippers: result.activeShippers,
      loading: false,
    })
  }

  const onSubmitSuccess = () => {
    NotificationManager.addSuccess(`La configuration a été enregistrée.`)
  }

  const onError = () => {
    setState({
      ...state,
      loading: false,
    })
    NotificationManager.addError(
      `Une erreur est survenue. Notre support est informé et reviendra vers vous.`
    )
  }

  const supplierKey = () => {
    return props.params.uniqueKey
  }

  return (
    <LayoutFixed
      nav={
        <SupplierNavigation
          supplierKey={supplierKey()}
          title={'Configuration'}
        />
      }
      menu={<MenuSecondary links={getMenuLinks(supplierKey())} />}
      title="Mes expéditions"
      loadData={useCallback(
        async () => getExpeditionConfiguration(props.params.uniqueKey),
        [props.params.uniqueKey]
      )}
      onDataLoaded={onDataLoaded}
      loading={state.loading}
    >
      <ExpeditionForm
        config={state.config}
        allShippers={state.allShippers}
        activeShippers={state.activeShippers}
        onSubmit={onFormSubmit}
      />
    </LayoutFixed>
  )
}

export const FeatureSuppliersConfigurationExpedition = withRouter(Expedition)
