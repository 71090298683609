import React from 'react'
import PropTypes from 'prop-types'
import styles from './Intro.module.scss'
import { Button, Title } from 'ui'

export const Intro = props => (
  <div className={styles.container}>
    <div className={styles.inner}>
      <div className={styles.content}>
        <Title>Gestion des délais d'expédition</Title>
        <br />
        Bonjour à tous !
        <br />
        <br />
        Notre système évolue : merci de renseigner votre délai d'expédition
        maximum dans votre interface. Ceci nous permettra de mieux suivre les
        expéditions de vos commandes, en nous adaptant à votre rythme
        d'expédition et aux besoins de chacun.
        <br />
        <br />
        Ce délai sera aussi diffusé sur notre site ecommerce et mis à jour
        automatiquement, facilitant notre travail en interne et vous permettant
        de gérer cette information par vous-même, sans délai.
        <br />
        <br /> N'hésitez pas à nous contacter pour toute question !
        <br />
        Bonne journée à tous,
        <br />
        <br />
        L'équipe Ici Présent! ❤️
      </div>
      <div className={styles.cta}>
        <Button primary onClick={props.onValid}>
          Configurer mon délai
        </Button>
      </div>
    </div>
  </div>
)

Intro.propTypes = {
  onValid: PropTypes.func.isRequired,
}
