import React from 'react'
import store from 'stores/SupplierThreadPageStore'

export default class Contact extends React.Component {
  send = e => {
    e.preventDefault()
    store.sendReply()
  }

  render() {
    return (
      <div id="reply">
        <h2>Répondre</h2>
        <form onSubmit={this.send}>
          <textarea
            className="text-field"
            required={true}
            value={this.props.replyContent}
            onChange={e => store.replyContentChanged(e.target.value)}
          ></textarea>
          <label>
            <input
              type="checkbox"
              onChange={e => store.replyInternalChanged(e.target.checked)}
            />
            <span>Échange interne</span>
          </label>
          <div>
            Cochez cette option si vous souhaitez communiquer entre
            administrateurs uniquement. Le client ne pourra pas consulter ce
            message et les administrateurs seront notifiés.
          </div>
          <br />
          <button className="btn">Envoyer</button>
        </form>
      </div>
    )
  }
}
