import React from 'react'
import PropTypes from 'prop-types'
import styles from './HistoryItem.module.scss'
import { getMonthName } from 'utils/time'
import { toPascalCase } from 'utils/string'
import { Button, IconExport } from 'ui'

const HistoryItem = props => {
  const { historyItem, onExport } = props

  const monthName = toPascalCase(getMonthName(historyItem.month))
  const today = new Date()
  const itemDate = new Date(historyItem.year, historyItem.month)
  const currentMonth = today < itemDate
  const buttonTitle = currentMonth
    ? "Attention, ce mois n'est pas terminé !"
    : `Télécharger les commandes du mois de ${monthName} ${historyItem.year}`

  const isInvoicedOnReceived =
    (historyItem.year == 2023 && historyItem.month >= 9) ||
    historyItem.year > 2023

  const cssClass = `${styles.historyItem} ${
    isInvoicedOnReceived ? styles.invoicedOnReceived : ''
  }`

  return (
    <div className={cssClass}>
      <div className={styles.month}>{monthName}</div>
      <div className={styles.sent}>Expédiées : {historyItem.sent}</div>
      <div className={styles.received}>
        Réceptionnées : {historyItem.received}
      </div>
      <div className={styles.actions}>
        <Button
          small
          icon={<IconExport width={16} />}
          onClick={() => onExport(historyItem.year, historyItem.month)}
          title={buttonTitle}
        >
          Exporter
        </Button>
      </div>
    </div>
  )
}

HistoryItem.propTypes = {
  historyItem: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  onExport: PropTypes.func.isRequired,
}

export default HistoryItem
