import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import ShippingInfo from './SupplierThreadPage/ShippingInfo'
import Message from './SupplierThreadPage/Message'
import Contact from './SupplierThreadPage/Contact'
import ThreadStatus from './SupplierThreadPage/ThreadStatus.js'
import store from 'stores/SupplierThreadPageStore'
import './SupplierThreadPage.scss'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  componentDidMount() {
    store.reset()
    store.subscribe(this.setState.bind(this))
    store.setState({
      uniqueKey: this.props.params.uniqueKey,
      threadKey: this.props.params.threadKey,
    })
    store.retrieveThread()
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  messages() {
    return this.state.messages.map(m => <Message key={m.id} {...m} />)
  }

  render() {
    return (
      <div className="page" id="supplier-thread-page">
        <Helmet>
          <title>Messagerie - Ici Présent!</title>
        </Helmet>
        <MainMenu
          supplierMenu={this.state.uniqueKey}
          errors={this.state.errors}
        />

        <div className="group">
          <div className="feature" id="explanation">
            <h1 className="h1">
              Échanges avec {this.state.shipping.firstName}{' '}
              {this.state.shipping.lastName}
            </h1>
          </div>

          <ShippingInfo {...this.state.shipping} />

          <ThreadStatus closed={this.state.closed} />

          {this.messages()}
          <Contact />
        </div>
      </div>
    )
  }
}

const SupplierThreadPage = withRouter(Page)
export default SupplierThreadPage
