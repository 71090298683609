import React, { useCallback, useState } from 'react'
import { LayoutFixed, MenuSecondary } from 'ui'
import { CompanyForm } from '../components'
import { SupplierNavigation } from 'features/suppliers/components'
import { getMenuLinks } from 'features/suppliers/configuration/@commons'
import { NotificationManager } from 'services/notifications'
import {
  getCompanyConfiguration,
  getSiretInformation,
  SUPPLIERS_COMPANY_ERRORS,
  updateCompanyConfiguration,
} from 'services/suppliers'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

const SIRET_INFO_MISSING =
  "Nous n'avons trouvé aucune information sur ce numéro SIRET. Veuillez compléter le formulaire manuellement"

const SIRET_INFO_FOUND =
  'Les informations trouvées à partir de votre SIRET on été ajoutées.'

const Company = props => {
  const [state, setState] = useState({
    cgpAcceptedDate: '',
    config: {},
    loading: false,
  })

  const onSiretRequest = value => {
    const newState = {
      ...state,
      config: {
        ...state.config,
        siret: value,
      },
      loading: true,
    }

    return waitForApi(getSiretInformation(value))
      .then(result => onSiretSuccess(result, newState))
      .catch(() => onSiretError(newState))
  }

  const onSiretSuccess = (result, newState) => {
    setState({
      ...newState,
      config: {
        ...newState.config,
        ...result.infos,
      },
      loading: false,
    })
    NotificationManager.addInfo(SIRET_INFO_FOUND)
  }

  const onSiretError = newState => {
    setState({ ...newState, loading: false })
    NotificationManager.addWarning(SIRET_INFO_MISSING)
  }

  const onFormSubmit = (config, cgpAccepted) => {
    const supplierKey = props.params.uniqueKey
    setState({ ...state, loading: true })

    return waitForApi(
      updateCompanyConfiguration(supplierKey, config, cgpAccepted).then(() =>
        getCompanyConfiguration(supplierKey)
      )
    )
      .then(onDataSuccess)
      .then(onSubmitSuccess)
      .catch(onSubmitError)
  }

  const onDataSuccess = result => {
    setState({
      ...state,
      ...result,
      error: null,
      loading: false,
    })
  }

  const onSubmitSuccess = () => {
    NotificationManager.addSuccess(`La configuration a été enregistrée.`)
  }

  const onSubmitError = errors => {
    setState({ ...state, loading: false })
    errors.map(e => NotificationManager.addError(SUPPLIERS_COMPANY_ERRORS[e]))
  }

  return (
    <LayoutFixed
      nav={
        <SupplierNavigation
          supplierKey={props.params.uniqueKey}
          title={'Configuration'}
        />
      }
      menu={<MenuSecondary links={getMenuLinks(props.params.uniqueKey)} />}
      loadData={useCallback(
        async () => getCompanyConfiguration(props.params.uniqueKey),
        [props.params.uniqueKey]
      )}
      onDataLoaded={data => setState({ ...state, ...data })}
      loading={state.loading}
      title="Mon entreprise"
    >
      <CompanyForm
        cgpAcceptedDate={state.cgpAcceptedDate}
        config={state.config}
        onSiretRequest={onSiretRequest}
        onSubmit={onFormSubmit}
      />
    </LayoutFixed>
  )
}

export const FeatureSuppliersConfigurationCompany = withRouter(Company)
