import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import SupplierForm from './SupplierForm'
import store from 'stores/Admin/NewSupplierStore'

export default class NewSupplierPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
    this._setState = this.setState.bind(this)
  }

  componentDidMount() {
    store.subscribe(this._setState)
  }

  componentWillUnmount() {
    store.unsubscribe(this._setState)
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          name_required: 'Merci de fournir un nom pour ce fournisseur.',
          emails_required:
            'Merci de fournir une addresse mail pour ce fournisseur.',
          name_taken: 'Ce fournisseur existe déjà.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page admin-page" id="admin-supplier-new-page">
        <Helmet>
          <title>Fournisseurs - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />
        <div className="group">
          <h1 className="h1">Nouveau fournisseur</h1>

          <div id="new-supplier" className="feature">
            <SupplierForm {...this.state} store={store} />
          </div>
        </div>
      </div>
    )
  }
}
