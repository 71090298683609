import React from 'react'
import PropTypes from 'prop-types'
import { formatToDate } from 'utils/time'
import {
  Annotation,
  Badge,
  ButtonLink,
  Card,
  Divider,
  Heading,
  Logo,
  Message,
} from 'ui'
import styles from './ThreadAdminCard.module.scss'

export const ThreadAdminCard = ({ thread }) => {
  const { messagesCount, warnLevel } = thread

  return (
    <Card
      padding={false}
      light
      actions={[
        <ButtonLink external={true} to={thread.shipping.link}>
          Voir le suivi de commande
        </ButtonLink>,
        <ButtonLink primary external={true} to={thread.discussionLink}>
          Voir la discussion
        </ButtonLink>,
      ]}
    >
      <div className={styles.resume}>
        <div className={styles.picture}>
          {thread.shipping.image && (
            <img
              className={styles.pictureSource}
              src={thread.shipping.image}
              alt={thread.supplier.name}
            />
          )}
          {!thread.shipping.image && (
            <div className={styles.pictureDefault}>
              <Logo />
            </div>
          )}
        </div>

        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.infos}>
              <Heading level={4}>{thread.shipping.product}</Heading>
              <Annotation>{thread.supplier.name}</Annotation>
            </div>
            <div className={styles.badges}>
              {warnLevel > 0 && (
                <Badge
                  {...getWarnType(warnLevel)}
                  small
                  label={getWarnLabel(warnLevel)}
                />
              )}
              <Badge
                small
                label={`${messagesCount} message${
                  messagesCount > 1 ? 's' : ''
                }`}
              />
            </div>
          </div>

          <Divider border />

          <div className={styles.message}>
            <Message
              full
              {...thread.message}
              createdAt={formatToDate(thread.message.createdAt, false)}
              name={thread.shipping.name}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

function getWarnType(level) {
  if (level === 1) return { info: true }
  if (level === 2) return { warning: true }
  if (level === 3) return { error: true }
  return {}
}

function getWarnLabel(level) {
  if (level === 1) return '1 relance'
  if (level === 2) return '2 relances'
  if (level === 3) return 'Admins relancés'
  return
}

ThreadAdminCard.propTypes = {
  thread: PropTypes.shape({
    uniqueKey: PropTypes.string,
    message: PropTypes.shape({
      createdAt: PropTypes.string,
      content: PropTypes.string,
      profile: PropTypes.number,
    }),
    messagesCount: PropTypes.number,
    warnLevel: PropTypes.number,
    discussionLink: PropTypes.string,
    shipping: PropTypes.shape({
      image: PropTypes.string,
      link: PropTypes.string,
      name: PropTypes.string,
      product: PropTypes.string,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
}
