import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BBoxesNotSent = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes physiques non expédiées"
    deliveryMethod={1}
    sent={false}
  />
)
