import config from 'config'
import { resolvesAfter } from 'utils/promise'
import { LOADER_MINIMUM_TIME } from 'utils/time'

export const fetch = async (url, options = {}) => {
  options.credentials = 'include'
  let resp = await window.fetch(url, options)
  if (resp.status === 401) {
    window.location.href = '/login'
  }

  return resp
}

export const get = (path, body) => {
  return fetch(`${config.apiUrl}/${path}`, {
    method: 'GET',
    body: JSON.stringify(body),
  }).then(handleResponse)
}

export const post = (path, body) => {
  return fetch(`${config.apiUrl}/${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(handleResponse)
}

const handleResponse = response => {
  if (!response.ok) return Promise.reject(response.statusText)

  return response.json().then(data => {
    if (!data.success) return Promise.reject(data.errors)
    return data
  })
}

export const waitForApi = req => resolvesAfter(req, LOADER_MINIMUM_TIME)

const Methods = {
  get,
  post,
  waitForApi,
}

export default Methods
