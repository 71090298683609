import React from 'react'
import styles from './OrderB2BCardHeader.module.scss'
import { formatToDateAndHour } from 'utils/time'
import { Badge, OrderB2BStatus } from 'ui'

export const OrderB2BCardHeader = props => {
  const { order: o } = props

  const displayNotSent =
    o.isPhysical &&
    !o.markedToSendAt &&
    ['active', 'incoming'].includes(o.status)

  const displaySending =
    o.isPhysical &&
    o.markedToSendAt &&
    o.nbBoxesSent < o.giftBoxNumber &&
    ['active', 'incoming'].includes(o.status)

  const displaySent =
    o.isPhysical &&
    o.markedToSendAt &&
    o.nbBoxesSent === o.giftBoxNumber &&
    ['active', 'incoming'].includes(o.status)

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.company}>{o.customerCompany}</div>
        <div className={styles.infos}>{formatToDateAndHour(o.createdAt)}</div>
      </div>
      <div className={styles.badges}>
        <div className={styles.badge}>
          <OrderB2BStatus status={o.status} />
        </div>
        {displayNotSent && (
          <div className={styles.badge}>
            <Badge small warning label={'Non expédiée'} />
          </div>
        )}
        {displaySending && (
          <div className={styles.badge}>
            <Badge small info label={"En cours d'expédition"} />
          </div>
        )}
        {displaySent && (
          <div className={styles.badge}>
            <Badge small label={'Expédiée'} />
          </div>
        )}
      </div>
      <div className={styles.id}>#{o.id}</div>
    </div>
  )
}
