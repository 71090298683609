import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from './Cell'

export const Line = ({ cells, header }) => {
  return (
    <tr>
      {cells.map((c, i) => (
        <Cell key={i} header={header} {...c} />
      ))}
    </tr>
  )
}

Line.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.bool,
}

Line.defaultProps = {
  header: false,
}
