import React from 'react'
import { IconCart, IconCash } from 'ui'

export const MENU_LINKS = [
  {
    to: `/a/monitoring/sales`,
    icon: <IconCash />,
    label: `Ventes`,
    primary: true,
  },
  {
    to: `/a/monitoring/purchases`,
    icon: <IconCart />,
    label: `Achats`,
    primary: true,
  },
]

export const COLUMNS = [
  { label: '', align: 'right', width: 180 },
  { label: 'TOTAL', align: 'right', width: 110, highlight: true },
  { label: 'Janv.', align: 'right', width: 110 },
  { label: 'Fev.', align: 'right', width: 110 },
  { label: 'Mar.', align: 'right', width: 110 },
  { label: 'Avr.', align: 'right', width: 110 },
  { label: 'Mai', align: 'right', width: 110 },
  { label: 'Juin', align: 'right', width: 110 },
  { label: 'Juil.', align: 'right', width: 110 },
  { label: 'Aou.', align: 'right', width: 110 },
  { label: 'Sept.', align: 'right', width: 110 },
  { label: 'Oct.', align: 'right', width: 110 },
  { label: 'Nov.', align: 'right', width: 110 },
  { label: 'Dec.', align: 'right', width: 110 },
]
