import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class SupplierMessagesPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      uniqueKey: null,
      threads: [],
      pageCount: 1,
      currentPage: 1,
    }
  }

  async retrieveThreads() {
    let body = JSON.stringify({
      uniqueKey: this.state.uniqueKey,
      page: this.state.currentPage,
    })

    let resp = await fetch(`${config.apiUrl}/api/suppliers/messages/list`, {
      method: 'POST',
      body: body,
    })

    let data = await resp.json()

    if (data.success) {
      this.setState({
        threads: data.threads,
        pageCount: data.pageCount,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  changeCurrentPage(currentPage) {
    this.setState({ currentPage })
    this.retrieveThreads()
  }

  async closeThread(threadKey) {
    this.setState(state => {
      let thread = state.threads.find(t => t.uniqueKey == threadKey)
      thread.closed = true
      return state
    })

    let body = JSON.stringify({
      supplierKey: this.state.uniqueKey,
      threadKey: threadKey,
    })

    let resp = await fetch(`${config.apiUrl}/api/suppliers/messages/close`, {
      method: 'POST',
      body: body,
    })

    let data = await resp.json()

    if (!data.success) {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new SupplierMessagesPageStore()
export default store
