import React from 'react'
import {
  IconHome,
  IconSuccess,
  IconActionClose,
  IconTrash,
  IconClock,
  IconPause,
} from 'ui'

export const MENU_LINKS = [
  {
    to: `/a/suppliers/all`,
    icon: <IconHome />,
    label: 'Tous les artisans',
  },
  {
    to: `/a/suppliers/active`,
    icon: <IconSuccess />,
    label: 'Actifs',
  },
  {
    to: `/a/suppliers/late`,
    icon: <IconClock />,
    label: 'En retard',
  },
  { divider: true },
  {
    to: `/a/suppliers/off`,
    icon: <IconPause />,
    label: 'En pause',
  },
  {
    to: `/a/suppliers/inactive`,
    icon: <IconActionClose />,
    label: 'Inactifs',
  },
  {
    to: `/a/suppliers/deleted`,
    icon: <IconTrash />,
    label: 'Supprimés',
  },
]
