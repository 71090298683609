import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextFieldButton.module.scss'
import { TextField, Button } from 'ui'

export class TextFieldButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valid: false,
      value: '',
    }
  }

  onLoad = (value, valid) => {
    this.setState({ value, valid })
    if (this.props.onLoad) this.props.onLoad(value, valid)
  }

  onChange = (value, valid) => {
    if (value !== this.state.value) this.setState({ value, valid })
  }

  onEnter = (value, valid) => {
    if (this.state.valid) this.props.onSubmit(value, valid)
  }

  onSubmit = e => {
    this.props.onSubmit(this.state.value, this.state.valid)
    e.preventDefault()
  }

  render() {
    const {
      children,
      icon,
      light,
      hideLabelOnMobile,
      onLoad,
      onSubmit,
      primary,
      title,
      width,
      ...rest
    } = this.props
    const { valid } = this.state

    return (
      <div className={styles.control} style={{ width }}>
        <div className={styles.textfield}>
          <TextField
            radiusRight={false}
            onLoad={this.onLoad}
            onChange={this.onChange}
            onBlur={this.onChange}
            onEnter={this.onEnter}
            light={light}
            {...rest}
          />
        </div>
        <div className={styles.button}>
          <Button
            primary={primary}
            radiusLeft={false}
            onClick={this.onSubmit}
            disabled={!valid}
            icon={icon}
            hideLabelOnMobile={hideLabelOnMobile}
            title={title}
            light={light}
          >
            {children}
          </Button>
        </div>
      </div>
    )
  }
}

TextFieldButton.propTypes = {
  autoValidate: PropTypes.bool,
  focused: PropTypes.bool,
  hideLabelOnMobile: PropTypes.bool,
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  onLoad: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  required: PropTypes.bool,
  resetable: PropTypes.bool,
  step: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'tel']),
  validate: PropTypes.bool,
  width: PropTypes.number,
}

TextFieldButton.defaultProps = {
  autoValidate: false,
  focused: false,
  hideLabelOnMobile: false,
  icon: null,
  initialValue: '',
  light: false,
  max: null,
  maxLength: null,
  min: null,
  minLength: null,
  onLoad: null,
  primary: false,
  required: false,
  resetable: false,
  step: null,
  title: null,
  type: 'text',
  validate: false,
  width: null,
}
