import React from 'react'
import styles from './OrderB2CCardHeader.module.scss'
import { formatToDateAndHour } from 'utils/time'

export const OrderB2CCardHeader = props => (
  <div className={styles.header}>
    <div className={styles.wrapper}>
      <div className={styles.name}>
        {props.order.customer.firstName} {props.order.customer.lastName}
      </div>
      <div className={styles.infos}>
        {formatToDateAndHour(props.order.createdAt)}
      </div>
    </div>
    <div className={styles.price}>{props.order.price}</div>
  </div>
)
