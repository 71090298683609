import React from 'react'
import { LayoutFixed, Button, Selectbox, MenuSecondary } from 'ui'
import { ListActions, SupplierNavigation } from 'features/suppliers/components'
import { getInvoice } from 'services/suppliers'
import { getMenuLinks } from './@commons'
import { Content } from 'features/suppliers/invoices/components'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

class InvoicesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      invoiceTypes: [],
      invoices: [],
      periods: [],
      activePeriod: {},
      supplier: {},
    }
  }

  loadData = () => {
    return waitForApi(
      getInvoice({
        uniqueKey: this.getSupplierKey(),
        year: this.getYear(),
        month: this.getMonth(),
      })
    )
  }

  onDataLoaded = data => {
    this.setState({
      loaded: true,
      invoiceTypes: data.invoices.items?.map(i => i.invoiceType),
      invoices: data.invoices.items,
      periods: data.invoices.allPeriods,
      activePeriod: data.invoices.currentPeriod,
      supplier: data.invoices.supplier,
    })
    if (this.isLatest() && this.state.periods) {
      this.redirectToLatestPeriod()
    }
  }

  getSupplierKey() {
    return this.props.params.uniqueKey
  }

  isLatest() {
    return this.props.params.latest
  }

  getYear() {
    return this.props.params.year
  }

  getMonth() {
    return this.props.params.month
  }

  getInvoiceTypeFromParams() {
    return this.props.params.type
  }

  getActiveInvoiceType() {
    let type = this.getInvoiceTypeFromParams()
    if (type) return this.state.invoiceTypes?.find(t => t.name === type)
  }

  onPeriodChange = v => {
    let period = this.state.periods.find(p => p.label === v),
      key = this.getSupplierKey()

    this.changePeriod(key, period)
  }

  changePeriod = (key, period) => {
    setTimeout(() => {
      this.props.navigate(
        `/suppliers/${key}/invoices/${period.year}/${period.month}/resume`,
        { replace: true }
      )
    }, 0)
  }

  redirectToLatestPeriod = () => {
    const latestPeriod = this.state.periods[0],
      key = this.getSupplierKey()
    this.changePeriod(key, latestPeriod)
  }

  render() {
    const { loaded, activePeriod, invoices, invoiceTypes, periods, supplier } =
      this.state

    const hasAutoInvoicing = supplier ? supplier.autoInvoicing : false
    const invoicesEmpty = invoices == null || invoices.length === 0
    const periodsEmpty = periods == null || periods.length === 0

    const showMenu = !invoicesEmpty && hasAutoInvoicing && loaded
    const showPrint = !invoicesEmpty && hasAutoInvoicing && loaded
    const showPeriods = !periodsEmpty && hasAutoInvoicing && loaded
    const showEmptyMessage = invoicesEmpty && hasAutoInvoicing && loaded
    const showContent = loaded

    return (
      <LayoutFixed
        title="Facturation"
        actions={
          hasAutoInvoicing && (
            <ListActions
              left={[
                showPeriods ? (
                  <Selectbox
                    id={'periods'}
                    label={'Période'}
                    onChange={this.onPeriodChange}
                    values={periods?.map(p => p.label)}
                    initialValue={activePeriod.label}
                  />
                ) : null,
              ]}
              right={[
                showPrint ? (
                  <Button primary onClick={() => window.print()}>
                    Imprimer
                  </Button>
                ) : null,
              ]}
            />
          )
        }
        nav={
          <SupplierNavigation
            supplierKey={this.getSupplierKey()}
            supplierName={supplier.name}
            title={'Facturation'}
          />
        }
        menu={
          showMenu ? (
            <MenuSecondary
              links={getMenuLinks(
                this.getSupplierKey(),
                activePeriod,
                invoiceTypes
              )}
            />
          ) : null
        }
        loadData={this.loadData}
        onDataLoaded={this.onDataLoaded}
      >
        {showContent && (
          <Content
            invoices={invoices}
            summary={activePeriod.summary}
            activeInvoiceType={this.getActiveInvoiceType()}
            hasAutoInvoicing={hasAutoInvoicing}
            showEmptyMessage={showEmptyMessage}
          />
        )}
      </LayoutFixed>
    )
  }
}

export const FeatureSuppliersInvoicesList = withRouter(InvoicesList)
