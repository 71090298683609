export const initialState = {
  box: {
    error: false,
    exceptions: [],
    loading: false,
    item: null,
    redirectUrl: null,
  },
  information: null,
  currentProduct: null,
  currentVariant: null,
  currentFilters: {
    themes: [],
    regions: [],
    suppliers: [],
  },
  filters: {
    error: false,
    loading: false,
    themes: [],
    regions: [],
    suppliers: [],
  },
  modalOpened: false,
  products: {
    limit: 12,
    error: false,
    loading: false,
    pagination: null,
    list: [],
  },
  save: {
    error: false,
    exceptions: [],
    loading: false,
    url: null,
  },
  vendors: [],
  selection: [],
}
