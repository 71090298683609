import React from 'react'
import PropTypes from 'prop-types'
import styles from './Grid.module.scss'

export const Grid = props => {
  const { cols, xs, s, m, l, xl, alignItems, items } = props

  let classes = styles[`_col-${cols}`]
  if (xs) classes += ` ${styles[`_xs-${xs}`]}`
  if (s) classes += ` ${styles[`_s-${s}`]}`
  if (m) classes += ` ${styles[`_m-${m}`]}`
  if (l) classes += ` ${styles[`_l-${l}`]}`
  if (xl) classes += ` ${styles[`_xl-${xl}`]}`

  if (alignItems === 'center') classes += ` ${styles._center}`
  if (alignItems === 'end') classes += ` ${styles._end}`

  return (
    <div className={`${styles.wrapper} ${classes}`}>
      {items.map((item, index) => (
        <div key={index} className={styles.item}>
          {item}
        </div>
      ))}
    </div>
  )
}

const within1And12 = (props, propName, componentName) => {
  componentName = componentName || 'ANONYMOUS'

  if (props[propName]) {
    let value = props[propName]
    if (typeof value === 'number') {
      return value >= 1 && value <= 12
        ? null
        : new Error(
            propName + ' in ' + componentName + ' is not within 1 to 12'
          )
    }
  }
  return null
}

Grid.displayName = 'Grid'

Grid.propTypes = {
  cols: within1And12,
  alignItems: PropTypes.oneOf(['start', 'center', 'end']),
  xs: within1And12,
  s: within1And12,
  m: within1And12,
  l: within1And12,
  xl: within1And12,
  items: PropTypes.arrayOf(PropTypes.element),
}

Grid.defaultProps = {
  cols: 1,
  alignItems: 'start',
  xs: null,
  s: null,
  m: null,
  l: null,
  xl: null,
  items: [],
}
