import React from 'react'
import PropTypes from 'prop-types'
import { MenuPrimary } from 'ui'

export const PackagersMenu = props => {
  const { title } = props
  const links = [
    { to: `/packagers/tracking/prepafacile`, label: "Suivi PrépaFacile" },
    { to: `/packagers/tracking/colissimo`, label: "Suivi Colissimo" },
    { to: `/account`, label: 'Mon compte' },
  ]

  return <MenuPrimary links={links} title={title} />
}

PackagersMenu.propTypes = {
  title: PropTypes.string,
}

PackagersMenu.defaultProps = {
  title: null,
}
