import React from 'react'
import { Helmet } from 'react-helmet'
import './DownloadEAN13Page.scss'
import jsbarcode from 'jsbarcode'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  componentDidMount() {
    let code = this.props.params.code
    jsbarcode('.code', code, { format: 'EAN13' })
  }

  codes() {
    let codes = []

    for (let i = 0; i < 63; i++) {
      codes.push(<svg key={i} className="code"></svg>)
    }

    return codes
  }

  render() {
    return (
      <div id="DownloadEAN13Page">
        <Helmet>
          <title>Téléchargement - Ici Présent!</title>
        </Helmet>
        {this.codes()}
      </div>
    )
  }
}

const DownloadEAN13Page = withRouter(Page)
export default DownloadEAN13Page
