import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonLink,
  Checkbox,
  Divider,
  Grid,
  LayoutFixed,
  Title,
} from 'ui'
import { Header } from 'features/customers/redeem/components'
import { setInformation } from 'features/customers/redeem/slice'

import styles from './Information.module.scss'
import {
  getAddressItems,
  getInfoItems,
  getStreetItems,
} from '../../@commons/coords'

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  company: '',
  email: '',
  phone: '',
  street: '',
  zipcode: '',
  city: '',
  cguAccepted: false,
  newsletterAccepted: false,
}

export const Information = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refForm = useRef(null)
  const box = useSelector(state => state.redeem.box.item)
  const selection = useSelector(state => state.redeem.selection)
  const information = useSelector(state => state.redeem.information)
  const [formInfo, setFormInfo] = useState(
    information ? information : INITIAL_STATE
  )
  const [validateCGU, setValidateCGU] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [emailConfirm] = useState(formInfo.email)

  useEffect(() => {
    if (!box) navigate('/redeem')
    else if (selection.length === 0) navigate('/redeem/selection')
  }, []) // eslint-disable-line

  useEffect(() => {
    // Make it asynchronous 'cause checkValidity returns false on first load
    setTimeout(() => {
      setFormValidity()
    }, 0)
  }, [formInfo])

  const onFieldChange = value => {
    setFormInfo({ ...formInfo, ...value })
    setFormValidity()
    if (information) dispatch(setInformation(null))
  }

  const setFormValidity = () => {
    const isValid = refForm?.current?.checkValidity() || false
    setIsValid(isValid)
  }

  const submit = e => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(setInformation(formInfo))
    navigate('/redeem/confirmation')
  }

  const checksItems = [
    <Checkbox
      id={'FormCGU'}
      required
      validate={validateCGU}
      checked={formInfo.cguAccepted}
      onChange={() => {
        setValidateCGU(true)
        onFieldChange({ cguAccepted: !formInfo.cguAccepted })
      }}
      value={'cgu'}
    >
      J'accepte les{' '}
      <a
        className={styles.cguLink}
        href="https://www.icipresent.com/pages/conditions-generales-de-vente"
        target="_blank"
        rel="noopener noreferrer"
      >
        CGV
      </a>
    </Checkbox>,

    <Checkbox
      id={'FormNewsletter'}
      checked={formInfo.newsletterAccepted}
      onChange={() =>
        onFieldChange({ newsletterAccepted: !formInfo.newsletterAccepted })
      }
      value={'newsletter'}
    >
      Je souhaite recevoir des informations sur la démarche, les offres et les
      artisans
    </Checkbox>,
  ]

  return (
    <LayoutFixed
      title={'Précisez vos coordonnées'}
      nav={<Header stepper gifts={box?.gifts} />}
      opposite={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <Title level={1}>Vos coordonnées</Title>
        </div>
        <div className={styles.baseline}>
          Complétez les informations ci-dessous pour que nos artisans
          partenaires puissent vous envoyer les produits que vous avez
          sélectionné. <br />
          Attention, seulement les adresses en France métropolitaine sont
          acceptées.
        </div>

        <Divider />

        <form ref={refForm} className={styles.form}>
          <div className={styles.fields}>
            <Grid
              cols={1}
              s={2}
              items={getInfoItems(
                formInfo,
                emailConfirm,
                onFieldChange,
                setFormValidity
              )}
            />

            <Divider />

            <Grid cols={1} items={getStreetItems(formInfo, onFieldChange)} />

            <Divider />

            <Grid
              cols={1}
              s={2}
              items={getAddressItems(formInfo, onFieldChange)}
            />

            <Divider />

            {checksItems.map((item, key) => (
              <div key={key} className={styles.check}>
                {item}
              </div>
            ))}
          </div>

          <div className={styles.footer}>
            <div className={styles.cta}>
              <ButtonLink to={'/redeem/selection'}>Retour</ButtonLink>
            </div>
            <div className={styles.cta}>
              <Button primary disabled={!isValid} onClick={submit}>
                Suivant
              </Button>
            </div>
          </div>
        </form>
      </div>
    </LayoutFixed>
  )
}
