/*
 * BaseStore can be used to create simple stores for react pages.
 *
 * Write a store that implements a `setDefaults` method to set its
 * `state` property, instantiate the store as a singleton, subscribe
 * to it in your root component on componentDidMount, and unsubscribe
 * on componentWillUnmount.
 *
 * You now can write any method to manage the state, import the store
 * in any subcomponent and call its actions.
 *
 * Example:
 *
 *  // store.js
 *  import "stores/BaseStore";
 *  class Store {
 *    setDefaults() {
 *      this.state = { text: '' };
 *    }
 *
 *    textChanged(value) {
 *      this.setState({text: value});
 *    }
 *  }
 *
 *  var store = new Store();
 *  export default store;
 *
 *
 *  // component.js
 *  import React from 'react';
 *  import store from 'store';
 *
 *  export default class StepsMenu extends React.Component {
 *    constructor(props) {
 *      super(props);
 *      this.state = store.state;
 *    }
 *
 *    componentDidMount() {
 *      store.subscribe(this.setState.bind(this));
 *    }
 *
 *    componentWillUnmount() {
 *      store.unsubscribe();
 *    }
 *
 *    render() {
 *      return (
 *        <div>
 *          <input type="text" value={this.state.text} onChange={e => store.textChanged(e.target.value)} />
 *        </div>
 *      );
 *    }
 *  }
 *
 */
export default class BaseStore {
  constructor() {
    this.setDefaults();
  }

  // setDefaults is the default setDefaults. Yo dawg.
  setDefaults() {
    this.state = {};
  }

  // subscribe allows to register component callback for store change.
  //
  // The callback is typically the `setState` method of the component.
  subscribe(cb) {
    this.cb = cb;
  }

  // unsubscribe allows to to reset the store.
  unsubscribe() {
    this.cb = null;
    this.setDefaults();
  }

  //emitChange notifies components a change has occured.
  async emitChange() {
    if (this.cb) {
      this.cb(this.state);
    }
  }

  // setState is a helper to change state and triggers emitChange at once.
  //
  // It's also meant to reflect react's setState api.
  setState(obj, cb) {
    if (typeof obj === 'function') {
      let newState = obj(Object.assign({}, this.state));
      this.state = newState;
    } else {
      Object.keys(obj).forEach(key => {
        this.state[key] = obj[key];
      });
    }

    this.emitChange().then(() => {
      if (cb) cb(this.state);
    });
  }

  reset() {
    this.setDefaults();
  }
}
