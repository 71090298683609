import React from 'react'
import Icon from './Icon'

export const IconCopy = props => (
  <Icon {...props}>
    <rect x="4" y="3" width="12" height="14" rx="3" />
    <rect
      x="8"
      y="7"
      width="12"
      height="14"
      rx="3"
      stroke="white"
      fill="white"
    />
    <rect x="10" y="9" width="12" height="14" rx="3" />
  </Icon>
)
