import React from 'react'
import PropTypes from 'prop-types'
import {
  Badge,
  Card,
  IconChevronRight,
  Logo,
  ShippingIcon,
  Subheading,
} from 'ui'
import styles from './CustomerShippingResume.module.scss'

export const CustomerShippingResume = ({ shipping: s }) => {
  const status = getStatus(s)

  return (
    <Card padding={false} light to={`#${s.id}`}>
      <div className={styles.wrapper}>
        <div className={styles.picture}>
          {s.image && (
            <img className={styles.pictureSource} src={s.image} alt={s.name} />
          )}
          {!s.image && (
            <div className={styles.pictureDefault}>
              <Logo />
            </div>
          )}
        </div>
        <div className={styles.resume}>
          <Subheading level={4} className={styles.supplier}>
            <div className={styles.supplierName}>{s.supplierName}</div>
          </Subheading>
          <div className={styles.name}>{s.name}</div>
          <div className={styles.status}>
            <Badge
              small
              error={status === 'canceled'}
              label={STATUS_BY_KEY[status]}
              icon={<ShippingIcon small status={status} />}
            />
            <div className={styles.action}>
              <IconChevronRight stroke={'#72758e'} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

const STATUS_BY_KEY = {
  received: 'Commandé',
  accepted: 'Accepté',
  sent: 'Expédié',
  delivered: 'Reçu',
  canceled: 'Annulé',
}

const getStatus = s => {
  if (s.cancelReason) return 'canceled'
  if (s.receivedAt) return 'delivered'
  if (s.sentAt) return 'sent'
  if (s.acceptedAt) return 'accepted'
  return 'received'
}
CustomerShippingResume.propTypes = {
  shipping: PropTypes.shape({
    id: PropTypes.number.isRequired,
    acceptedAt: PropTypes.string,
    cancelReason: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    receivedAt: PropTypes.string,
    sentAt: PropTypes.string,
    supplierName: PropTypes.string.isRequired,
  }),
}
