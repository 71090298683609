import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import styles from './LayoutFixed.module.scss'
import { withRouter } from 'hocs/withRouter'
import { Empty, Error, Loader, Pagination } from 'ui'
import { withFade } from 'ui/hoc'
import { setRouteParam } from 'utils/route'
import { waitForApi } from 'utils/api'

const ERROR_DEFAULT =
  "Veuillez recharger la page pour obtenir des données à jour. Si le problème persiste, n'hésitez pas à nous contacter."

const FadeContent = withFade(props => props.children)

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      empty: props.empty,
      error: props.error,
      loading: props.loading,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadData()
    }

    if (prevProps.location.search !== this.props.location.search) {
      this.loadData()
    }

    if (prevProps.loadData !== this.props.loadData) {
      this.loadData()
    }

    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading })
    }

    if (prevProps.empty !== this.props.empty) {
      this.setState({ empty: this.props.empty })
    }

    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error })
    }
  }

  loadData = () => {
    const { loadData } = this.props

    if (loadData) {
      this.setState({ loading: true })
      return waitForApi(loadData())
        .then(this.onDataSuccess)
        .catch(this.onDataError)
    }
  }

  onDataSuccess = data => {
    const { onDataLoaded } = this.props

    if (onDataLoaded) onDataLoaded(data) // Always before the state assignation to execute container callback before
    this.setState({ data, loading: false })
  }

  onDataError = () => {
    this.setState({
      error: ERROR_DEFAULT,
      loading: false,
    })
  }

  onPageChange = page => {
    this.props.navigate(setRouteParam(this.props.location, 'page', page))
  }

  render() {
    const {
      actions,
      children,
      footer,
      menu,
      nav,
      offscreen,
      fullWidth,
      opposite,
    } = this.props
    const { data, empty, error, loading } = this.state

    const showError = error?.length > 0
    const showContent = !showError && !loading
    const showOffscreen = !showError && !loading && offscreen
    const showEmpty =
      !loading && (empty || (showContent && data?.pagination?.nbItems === 0))
    const showPagination = showContent && data?.pagination?.nbPages > 1
    const showActions = showContent || showEmpty

    let modifiers = ''
    if (!fullWidth) modifiers += ` ${styles._boxed}`
    if (opposite) modifiers += ` ${styles._opposite}`

    return (
      <div className={`${styles.layout} ${modifiers}`}>
        {nav && <header className={styles.nav}>{nav}</header>}

        <Helmet titleTemplate="%s - Ici Présent!" defaultTitle="Ici Présent!">
          <title>{this.props.title}</title>
        </Helmet>

        <div className={styles.wrapper}>
          {menu && <nav className={styles.menu}>{menu}</nav>}

          <div className={styles.content}>
            {actions && (
              <aside className={styles.aside}>
                {showActions && (
                  <FadeContent className={styles.asideFader}>
                    <div className={styles.actions}>{actions}</div>
                  </FadeContent>
                )}
              </aside>
            )}

            <main className={styles.main}>
              {loading && (
                <FadeContent className={styles.loader}>
                  <Loader />
                </FadeContent>
              )}

              {!loading && (
                <FadeContent className={styles.inner}>
                  <div className={styles.scroller}>
                    <div className={styles.box}>
                      {showEmpty && <Empty />}
                      {showError && <Error message={error} />}
                      {showContent && children}
                    </div>

                    {showPagination && (
                      <div className={styles.pagination}>
                        <Pagination
                          onChange={this.onPageChange}
                          pagination={data.pagination}
                        />
                      </div>
                    )}
                  </div>
                </FadeContent>
              )}
            </main>
          </div>
        </div>

        {footer && <footer className={styles.footer}>{footer}</footer>}

        {showOffscreen && offscreen}
      </div>
    )
  }
}

export const LayoutFixed = withRouter(Layout)

LayoutFixed.displayName = 'LayoutFixed'

LayoutFixed.propTypes = {
  actions: PropTypes.element,
  empty: PropTypes.bool,
  error: PropTypes.string,
  footer: PropTypes.element,
  fullWidth: PropTypes.bool,
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  offscreen: PropTypes.element,
  onDataLoaded: PropTypes.func,
  opposite: PropTypes.bool,
  menu: PropTypes.element,
  nav: PropTypes.element,
  title: PropTypes.string.isRequired,
}

LayoutFixed.defaultProps = {
  actions: null,
  empty: false,
  error: null,
  footer: null,
  fullWidth: false,
  loadData: null,
  loading: false,
  offscreen: null,
  onDataLoaded: null,
  opposite: false,
  menu: null,
  nav: null,
}
