import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BEboxesActives = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes E-Coffrets actives"
    deliveryMethod={0}
    status={'active'}
  />
)
