import React from 'react'
import Icon from './Icon'

export const IconCompany = props => (
  <Icon {...props}>
    <path d="M1 4L12.5809 1.51837C13.826 1.25158 15 2.20069 15 3.47398V23H1V4Z" />
    <path d="M6 19H10V23H6V19Z" />
    <path d="M19 14H23" />
    <path d="M19 10H23" />
    <path d="M19 18H23" />
    <rect x="15" y="5" width="8" height="18" />
    <path d="M19 0V5" />
    <path d="M4 15H7" />
    <path d="M9 15H12" />
    <path d="M9 7H12" />
    <path d="M4 7H7" />
    <path d="M4 11H7" />
    <path d="M9 11H12" />
  </Icon>
)
