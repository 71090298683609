import React from 'react'
import { FeatureAdminReviewsList } from './List'

export const FeatureAdminReviewsInactive = props => (
  <FeatureAdminReviewsList
    {...props}
    active={false}
    removeAfterAction={true}
    title="Avis clients inactifs"
  />
)
