import { Button, Modal } from 'ui'
import { Filters } from '.'

export const FiltersModal = props => {
  return (
    <Modal
      opened={props.opened}
      closable
      title="Filtrer les cadeaux"
      onClose={props.onClose}
      actions={[
        <Button primary onClick={props.onClose}>
          Valider
        </Button>,
      ]}
    >
      <Filters />
    </Modal>
  )
}
