import api from 'utils/api'

export const getUnansweredThreads = params =>
  api.post('api/admin/support/threads/unanswered', params)

export const getUnresolvedThreads = params =>
  api.post('api/admin/support/threads/unresolved', params)

export const initThread = params =>
  api.post('api/suppliers/messages/init', params)
