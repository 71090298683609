import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import styles from './Stepper.module.scss'

export const Stepper = ({ gifts }) => {
  const box = useSelector(state => state.redeem.box.item)
  const selection = useSelector(state => state.redeem.selection)
  const information = useSelector(state => state.redeem.information)
  const { pathname } = useLocation()

  const links = [
    {
      to: '/redeem/selection',
      title: gifts > 1 ? 'Vos cadeaux' : 'Votre cadeau',
      disabled: !box,
    },
    {
      to: '/redeem/information',
      title: 'Vos coordonnées',
      disabled: !box || selection.length !== gifts,
    },
    {
      to: '/redeem/confirmation',
      title: 'Récapitulatif',
      disabled: !box || selection.length !== gifts || !information,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <ol className={styles.list}>
        {links.map(l => {
          let className = styles.item
          if (pathname === l.to) className += ' ' + styles._active
          if (l.disabled) className += ' ' + styles._disabled

          return (
            <li key={l.to} className={className}>
              <Link className={styles.link} to={l.to}>
                <span className={styles.text}>{l.title}</span>
              </Link>
            </li>
          )
        })}
      </ol>
    </div>
  )
}
