import React, { Component } from 'react'
import styles from './Stats.module.scss'
import { Figure, Grid, LayoutFixed, Title, MenuSecondary } from 'ui'
import { getReviewsStats } from 'services/reviews'
import { AdminNavigation } from 'features/admin/components'
import { MENU_LINKS } from '../@commons'
import { withRouter } from 'hocs/withRouter'

class ReviewsStats extends Component {
  constructor(props) {
    super(props)
    this.state = { stats: null }
  }

  renderTotal() {
    const { stats } = this.state

    return (
      <div>
        <div className={styles.title}>
          <Title hLevel={2}>Au total</Title>
        </div>
        <div className={styles.list}>
          {this.renderItem('Avis postés', stats.active)}
          {this.renderItem('Avis désactivés', stats.inactive)}
          {this.renderItem("Réponses d'artisan", stats.answered)}
          {this.renderItem('Note moyenne', stats.avgRating)}
        </div>
      </div>
    )
  }

  render30d() {
    const { stats } = this.state

    return (
      <div>
        <div className={styles.title}>
          <Title hLevel={2}>30 derniers jours</Title>
        </div>
        <div className={styles.list}>
          {this.renderItem('Avis postés', stats.active30d)}
          {this.renderItem('Avis désactivés', stats.inactive30d)}
          {this.renderItem("Réponses d'artisan", stats.answered30d)}
          {this.renderItem('Note moyenne', stats.avgRating30d)}
        </div>
      </div>
    )
  }

  renderItem(label, value) {
    return (
      <div className={styles.item}>
        <Figure label={label} value={value.toString()} />
      </div>
    )
  }

  render() {
    const { stats } = this.state

    return (
      <LayoutFixed
        menu={<MenuSecondary links={MENU_LINKS} />}
        nav={<AdminNavigation title={'Avis Clients'} />}
        loadData={getReviewsStats}
        onDataLoaded={data => this.setState({ ...data })}
        title="Avis Clients"
      >
        {stats && (
          <Grid cols={1} s={2} items={[this.renderTotal(), this.render30d()]} />
        )}
      </LayoutFixed>
    )
  }
}

export const FeatureAdminReviewsStats = withRouter(ReviewsStats)
