import React from 'react'
import styles from './ReviewCardBody.module.scss'
import { REVIEWS_DEACTIVATION_REASONS } from 'services/reviews'
import { Badge, IconClock } from 'ui'
import { withStatus } from 'ui/hoc'
import {formatToDate} from "utils/time";

const Deactivated = withStatus(props => (
  <div className={`${styles.deactivated} ${props.className}`}>
    <div className={styles.title}>Raison de la désactivation</div>
    <div className={styles.reason}>
      {REVIEWS_DEACTIVATION_REASONS.find(
        r => r.value === props.review.inactiveReason
      )?.label || 'Aucune raison précisée'}
    </div>
  </div>
))

export const ReviewCardBody = props => (
  <div className={styles.body}>
    {!props.review.active && (
      <div className={styles.inactive}>
        <Deactivated {...props} light error />
      </div>
    )}

    <div className={styles.description}>
      {props.review.quarantine && (
        <div className={styles.badge}>
          <Badge
            warning
            small
            label={'Quarantaine'}
            icon={<IconClock width={14} height={14} />}
          />
        </div>
      )}
      <div className={styles.title}>Note : {props.review.rating}</div>
      {props.review.comment.length > 0 && (
        <div className={styles.comment}>{props.review.comment}</div>
      )}
    </div>
    {props.review.answer.length > 0 && (
      <div className={styles.answer}>
        <div className={styles.title}>Réponse de l'artisan</div>
        {props.review.answeredAt !== '' && (
          <div className={styles.date}>{formatToDate(props.review.answeredAt)}</div>
        )}
        <div className={styles.comment}>{props.review.answer}</div>
      </div>
    )}
  </div>
)
