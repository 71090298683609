import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import Product from './ProductsPage/Product.js'
import store from 'stores/Admin/ProductsStore'
import { Link } from 'react-router-dom'
import './ProductsPage.scss'

export default class ProductsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  componentDidMount() {
    store.subscribe(this.setState.bind(this))
    store.load()
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  products() {
    return this.state.products.map(s => <Product key={s.id} {...s} />)
  }

  render() {
    return (
      <div className="page admin-page" id="admin-products-page">
        <Helmet>
          <title>Coffrets-Cadeaux - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />

        <div className="group admin-list">
          <h1 className="h1">Liste des coffrets-cadeaux</h1>

          <p className="new-link">
            <Link to="/a/products/new">Nouveau coffret</Link>
          </p>
          <div id="products" className="feature">
            {this.products()}
          </div>
        </div>
      </div>
    )
  }
}
