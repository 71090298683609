let parserRegexp = /^(.*:\/\/)([^:/]+)(:[^/]+)?(.*?)(\?.*?)?(#.*)?$/;

export default class Url {
  constructor(initial) {
    if (!initial) initial = window.location.href;
    initial.replace(
      parserRegexp,
      function(match, protocol, domain, port, path, params, anchor) {
        [this._protocol, this._domain, this._port] = [protocol, domain, port];
        [this._path, this._params, this._anchor] = [path, params, anchor];
      }.bind(this),
    );

    this.protocol = this._protocol ? this._protocol.replace(/:\/\//, '') : '';
    this.domain = this._domain || '';
    this.port = this._port ? this._port.replace(/:/, '') : '';
    this.path = this._path || '';
    this.params = this._params ? this._params.replace(/^\?/, '') : '';
    this.anchor = this._anchor ? this._anchor.replace(/#/, '') : '';
  }

  getParams() {
    let params = {};
    this.params.split('&').forEach(function(pair) {
      pair.replace(/(.*)=(.*)/, (match, name, value) => (params[name] = decodeURIComponent(value)));
    });

    return params;
  }

  setParams(params) {
    this.params = '';

    Object.keys(params).forEach(name => {
      this.params = `${this.params}${name}=${params[name]}&`;
    });

    this.params = this.params.replace(/&$/, '');
    return this;
  }

  toString() {
    let port = this.port ? `:${this.port}` : '';
    let params = this.params ? `?${this.params}` : '';
    let anchor = this.anchor ? `#${this.anchor}` : '';

    return `${this.protocol}://${this.domain}${port}${
      this.path
    }${params}${anchor}`;
  }

  relativePath() {
    let params = this.params ? `?${this.params}` : '';
    let anchor = this.anchor ? `#${this.anchor}` : '';

    return `${this.path}${params}${anchor}`;
  }

  isRelative() {
    return this.protocol === '';
  }
}
