import React from 'react'
import PropTypes from 'prop-types'
import { withFade } from 'ui/hoc'

import styles from './Loader.module.scss'

export const Loader = withFade(({ opposite }) => {
  const modifiers = opposite ? styles._opposite : ''
  return (
    <svg className={`${styles.loader} ${modifiers}`} viewBox="0 0 38 38">
      <g fill="none" transform="translate(1 1)">
        <circle strokeOpacity=".2" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  )
})

Loader.propTypes = {
  opposite: PropTypes.bool,
}

Loader.defaultProps = {
  opposite: false,
}
