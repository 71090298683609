import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MENU_LINKS } from '../@commons'
import { Deactivation } from '../components'
import { getReviews, activateReview, deactivateReview } from 'services/reviews'
import { getRouteParam, setRouteParam } from 'utils/route'
import { formatDate } from 'utils/time'
import {
  Button,
  Grid,
  LayoutFixed,
  Modal,
  Removable,
  ReviewCard,
  MenuSecondary,
} from 'ui'
import { AdminActions, AdminNavigation } from 'features/admin/components'
import { withRouter } from 'hocs/withRouter'

class ReviewsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deactivationId: 0,
      deactivationReason: 0,
      pagination: {},
      reviews: [],
    }
  }

  loadData = () => {
    const { active, quarantine, ratings } = this.props
    const date = this.props.date ? formatDate(this.props.date) : null
    const page = parseInt(getRouteParam(this.props.location, 'page')) || 1
    const query = this.query()

    return getReviews({
      page,
      active,
      query,
      date,
      quarantine,
      ratings,
    })
  }

  onDataLoaded = data => {
    const { result } = data
    const mappedReviews =
      result.reviews?.map(r => ({ ...r, removed: false })) || []
    this.setState({
      ...data,
      deactivationId: 0,
      reviews: mappedReviews,
    })
  }

  onActivate = id => {
    // Set Happy Path state
    const reviews = [...this.state.reviews]
    const review = reviews.find(r => r.id === id)

    if (this.props.removeAfterAction) review.removed = true
    else review.active = true

    this.setState({ reviews })

    // Call API for change (due to happy path, no callback if everything's correct)
    return activateReview(id).catch(this.onError)
  }

  onDeactivate = deactivationId => {
    this.setState({ deactivationId })
  }

  onCancelDeactivation = () => {
    this.setState({ deactivationId: 0 })
  }

  onReasonChange = deactivationReason => {
    this.setState({ deactivationReason })
  }

  onValidDeactivation = () => {
    const id = this.state.deactivationId
    const reason = this.state.deactivationReason

    // Set Happy Path state
    const reviews = [...this.state.reviews]
    const review = reviews.find(r => r.id === id)

    if (this.props.removeAfterAction) review.removed = true
    else {
      review.active = false
      review.inactiveReason = reason
      review.quarantine = false
    }

    this.setState({ reviews, deactivationId: 0 })

    // Call API for change (due to happy path, no callback if everything's correct)
    return deactivateReview(id, reason).catch(this.onError)
  }

  onFilter = filter => {
    let location = setRouteParam(this.props.location, 'query', filter)
    location = setRouteParam(location, 'page', 1)
    this.props.navigate(location)
  }

  query() {
    return getRouteParam(this.props.location, 'query') || ''
  }

  render() {
    const { deactivationId, pagination, reviews } = this.state
    const showModalDeactivation = deactivationId !== 0

    return (
      <LayoutFixed
        title={this.props.title}
        actions={
          <AdminActions
            query={this.query()}
            onFilter={this.onFilter}
            total={pagination.nbItems}
          />
        }
        menu={<MenuSecondary links={MENU_LINKS} />}
        nav={<AdminNavigation title={'Avis Clients'} />}
        offscreen={this.renderModal(showModalDeactivation)}
        loadData={this.loadData}
        onDataLoaded={this.onDataLoaded}
      >
        <Grid
          cols={1}
          items={reviews.map(r => (
            <Removable key={r.id} removed={r.removed}>
              <ReviewCard
                key={r.id}
                review={r}
                onActivate={this.onActivate}
                onDeactivate={this.onDeactivate}
              />
            </Removable>
          ))}
        />
      </LayoutFixed>
    )
  }

  renderModal(opened) {
    const { deactivationReason } = this.state

    return (
      <Modal
        opened={opened}
        closable
        onClose={this.onCancelDeactivation}
        title={'Désactiver un avis'}
        actions={[
          <Button onClick={this.onCancelDeactivation}>Annuler</Button>,
          <Button
            onClick={this.onValidDeactivation}
            primary
            disabled={deactivationReason === 0}
          >
            Désactiver
          </Button>,
        ]}
      >
        <Deactivation
          onChange={this.onReasonChange}
          value={deactivationReason}
        />
      </Modal>
    )
  }
}

export const FeatureAdminReviewsList = withRouter(ReviewsList)

FeatureAdminReviewsList.propTypes = {
  removeAfterAction: PropTypes.bool,
  active: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  quarantine: PropTypes.bool,
  title: PropTypes.string,
  ratings: PropTypes.arrayOf(PropTypes.number),
}

FeatureAdminReviewsList.defaultProps = {
  removeAfterAction: false,
  active: null,
  date: null,
  ratings: [],
}
