import React from 'react'
import PropTypes from 'prop-types'
import styles from './CheckboxList.module.scss'
import { Checkbox } from 'ui'

export class CheckboxList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { values: [] }
  }

  componentDidMount() {
    this.setState({ values: this.props.values })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      const fromProps = [...this.props.values].sort().join(',')
      const fromState = [...this.state.values].sort().join(',')
      if (fromProps === fromState) return

      this.setState({ values: this.props.values })
      if (this.props.onChange) this.props.onChange(this.props.values)
    }
  }

  onChange = (value, checked) => {
    let values = [...this.state.values]
    const index = values.indexOf(value)

    if (checked && index === -1) values.push(value)
    else if (!checked && index > -1) values.splice(index, 1)

    this.setState({ values })
    if (this.props.onChange) this.props.onChange(values)
  }

  render() {
    const { disabled, id, options } = this.props

    return (
      <div className={styles.wrapper}>
        {options.map(o => (
          <div className={styles.item} key={o.value}>
            <Checkbox
              id={id}
              checked={this.state.values.indexOf(o.value) > -1}
              value={o.value}
              disabled={disabled}
              onChange={this.onChange}
            >
              {o.label}
            </Checkbox>
          </div>
        ))}
      </div>
    )
  }
}

CheckboxList.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
    })
  ),
  values: PropTypes.arrayOf(PropTypes.string),
}

CheckboxList.defaultProps = {
  disabled: false,
  onChange: null,
  options: [],
  values: [],
}
