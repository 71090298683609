import React from 'react'
import PropTypes from 'prop-types'
import styles from './Dropdown.module.scss'
import { IconChevronDown, IconChevronUp } from 'ui'

export const Dropdown = props => {
  const {
    children,
    iconStart,
    isOpened,
    label,
    value,
    onBackgroundClick,
    onButtonClick,
    onContentClick,
  } = props

  const modifiers = isOpened ? styles._opened : ''
  const iconEnd = isOpened ? <IconChevronUp /> : <IconChevronDown />

  return (
    <div className={`${styles.container} ${modifiers}`}>
      <div className={styles.bg} onClick={onBackgroundClick}></div>
      <button className={styles.button} onClick={onButtonClick}>
        {iconStart && <span className={styles.iconStart}>{iconStart}</span>}
        <span className={styles.label}>{label}</span>
        {value && <span className={styles.value}>{value}</span>}
        <span className={styles.iconEnd}>{iconEnd}</span>
      </button>

      <div className={styles.content} onClick={onContentClick}>
        {children}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  iconStart: PropTypes.element,
  isOpened: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onBackgroundClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onContentClick: PropTypes.func.isRequired,
  value: PropTypes.number,
}

Dropdown.defaultProps = {
  iconStart: null,
  isOpened: false,
  onContentClick: null,
  value: null,
}
