import React from 'react'
import styles from './Colissimo.module.scss'
import { LayoutFixed, Grid, Modal, Button, TextField } from 'ui'
import { PackagersMenu } from '../../components'
import { ColissimoOrdersList } from '../components'
import { getOrders, addTracking } from 'services/packagers'
import { NotificationManager } from 'services/notifications'
import { waitForApi } from 'utils/api'
import { getRouteParam } from 'utils/route'
import { withRouter } from 'hocs/withRouter'

const FORM_NOT_COMPLETE = 'Merci de compléter tous les champs requis.'
const FORM_COMPLETE = 'Le numéro de suivi a été attaché à la commande'
const ERROR_DEFAULT =
  "Veuillez recharger la page pour obtenir des données à jour. Si le problème persiste, n'hésitez pas à nous contacter."

const INITIAL_TRACKING_FORM = {
  orderId: null,
  trackingNumber: null,
  nbBoxesSent: 0,
}

class TrackingColissimo extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      trackingFormOpened: false,
      trackingFormValid: false,
      trackingFormData: { ...INITIAL_TRACKING_FORM },
      result: { orders: [] },
    }
  }

  loadData = () => {
    const page = parseInt(getRouteParam(this.props.location, 'page')) || 1
    return waitForApi(getOrders({ page }))
  }

  onAddTrackingNumber = o => {
    const trackingFormData = {
      ...this.state.trackingFormData,
      orderId: o.id,
    }
    this.setState({ trackingFormData, trackingFormOpened: true })
  }

  onModalCancel = () => {
    this.setState({ trackingFormOpened: false })
  }

  onModalValid = () => {
    const isValid = [...this.formRef.current].reduce(
      (acc, value) => acc && value.checkValidity(),
      true
    )

    if (!isValid) {
      NotificationManager.addError(FORM_NOT_COMPLETE)
      this.setState({ trackingFormValid: true })
    } else this.addTracking()
  }

  addTracking = () => {
    this.setState({ loading: true })

    waitForApi(
      addTracking(this.state.trackingFormData).then(() => this.loadData())
    )
      .then(this.onAddTrackingSuccess)
      .catch(this.onAddTrackingError)
  }

  onAddTrackingError = () => {
    NotificationManager.addError(ERROR_DEFAULT)
  }

  onAddTrackingSuccess = result => {
    this.setState({
      ...result,
      trackingFormOpened: false,
      trackingFormValid: false,
      trackingFormData: INITIAL_TRACKING_FORM,
    })

    NotificationManager.addSuccess(FORM_COMPLETE)
  }

  createOrderError = error => {
    this.setState({ loading: false })
    if (error.length > 0) NotificationManager.addError(error)
  }

  onTrackingNumberChange = trackingNumber => {
    const trackingFormData = {
      ...this.state.trackingFormData,
      trackingNumber: trackingNumber,
    }
    this.setState({ trackingFormData })
  }

  onNbBoxesChange = nbBoxes => {
    const trackingFormData = {
      ...this.state.trackingFormData,
      nbBoxesSent: parseInt(nbBoxes),
    }
    this.setState({ trackingFormData })
  }

  renderModal() {
    const { trackingFormOpened, trackingFormValid } = this.state

    return (
      <Modal
        opened={trackingFormOpened}
        closable={true}
        title={'Ajouter un numéro de suivi'}
        small
        onClose={() => this.setState({ trackingFormOpened: false })}
        actions={[
          <Button onClick={this.onModalCancel}>Annuler</Button>,
          <Button primary onClick={this.onModalValid}>
            Valider
          </Button>,
        ]}
      >
        {trackingFormOpened && (
          <form ref={this.formRef} className={styles.trackingForm}>
            <Grid
              items={[
                <TextField
                  id={'TrackingNumber'}
                  label={'Numéro de suivi (13 chiffres et lettres)'}
                  required
                  validate={trackingFormValid}
                  focused={true}
                  onChange={this.onTrackingNumberChange}
                  onEnter={this.onModalValid}
                  type={'tracking_number'}
                />,
                <TextField
                  id={'NbBoxesSent'}
                  label={'Nombre de coffret(s) expédié(s)'}
                  required
                  validate={trackingFormValid}
                  onChange={this.onNbBoxesChange}
                  onEnter={this.onModalValid}
                  type={'number'}
                />,
              ]}
            ></Grid>
          </form>
        )}
      </Modal>
    )
  }

  render() {
    const {
      result: { orders },
    } = this.state

    return (
      <LayoutFixed
        nav={<PackagersMenu title={'Suivi Colissimo'} />}
        loadData={this.loadData}
        onDataLoaded={data => this.setState({ ...data })}
        offscreen={this.renderModal()}
        title="Suivi Colissimo"
      >
        <ColissimoOrdersList
          orders={orders}
          onAddTrackingNumber={this.onAddTrackingNumber}
        />
      </LayoutFixed>
    )
  }
}

export const FeaturePackagersTrackingColissimo = withRouter(TrackingColissimo)
