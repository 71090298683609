import React from 'react'
import PropTypes from 'prop-types'
import styles from './Collapsible.module.scss'

export class Collapsible extends React.Component {
  constructor(props) {
    super(props)
    this.elementRef = React.createRef()
    this.transition = null
    this.state = {
      animating: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible && prevProps.visible && !this.state.animating) {
      this.collapse()
    } else if (
      this.props.visible &&
      !prevProps.visible &&
      !this.state.animating
    ) {
      this.expand()
    }
  }

  collapse() {
    this.setState({ animating: true })
    const ref = this.elementRef.current
    this.transition = ref.style.transition
    ref.style.transition = ''
    requestAnimationFrame(this.animateCollapse)
  }

  animateCollapse = () => {
    const ref = this.elementRef.current
    ref.style.height = ref.scrollHeight + 'px'
    ref.style.transition = this.transition
    requestAnimationFrame(this.endCollapse)
  }

  endCollapse = () => {
    const ref = this.elementRef.current
    ref.style.height = 0 + 'px'
    this.setState({ animating: false })
  }

  expand() {
    const ref = this.elementRef.current
    this.setState({ animating: true })
    ref.style.height = ref.scrollHeight + 'px'
    ref.addEventListener('transitionend', this.endExpand)
  }

  endExpand = () => {
    const ref = this.elementRef.current
    ref.removeEventListener('transitionend', this.endExpand)
    ref.style.height = null
    this.setState({ animating: false })
  }

  render() {
    return (
      <div ref={this.elementRef} className={`${styles.container}`}>
        {this.props.children}
      </div>
    )
  }
}

Collapsible.propTypes = {
  visible: PropTypes.bool,
}

Collapsible.defaultProps = {
  visible: false,
}
