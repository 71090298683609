import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BBoxes = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes Entreprise Physiques"
    deliveryMethod={1}
  />
)
