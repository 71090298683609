import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class NewThemeStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      name: '',
      imageUrl: '',
    }
  }

  nameChanged(name) {
    this.setState({ name })
  }

  imageUrlChanged(imageUrl) {
    this.setState({ imageUrl })
  }

  async submit() {
    let params = {
      name: this.state.name,
      imageUrl: this.state.imageUrl,
    }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/themes/create`, {
      method: 'POST',
      body,
    })

    let data = await resp.json()

    if (data.success) {
      alert('Le thème a bien été créé.')
      window.location.href = '/a/themes'
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new NewThemeStore()
export default store
