import { CustomerShippingCard, Divider } from 'ui'

export const ShippingList = ({ shippings, onContact, onReceived, onReview }) =>
  shippings.map(s => (
    <div id={s.id} key={s.id}>
      <Divider />
      <CustomerShippingCard
        shipping={s}
        onContact={() => onContact(s)}
        onReceived={() => onReceived(s)}
        onReview={() => onReview(s)}
      />
    </div>
  ))
