import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ButtonLink,
  Card,
  Checkbox,
  Grid,
  LayoutFixed,
  Loader,
  Subheading,
  Title,
} from 'ui'
import { Header, ProductThumbnail } from 'features/customers/redeem/components'
import { NotificationManager } from 'services/notifications'
import { saveRedeem } from 'features/customers/redeem/slice'
import useDidMountEffect from 'hooks/useDidMountEffect'
import { ContactDetails } from '../../@commons/ContactDetails'

import styles from './Confirmation.module.scss'

const INTERNAL_ERROR = 'Une erreur est survenue'
const REDEEM_SAVED = 'Votre commande a été enregistrée'

export const Confirmation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const refForm = useRef(null)
  const box = useSelector(state => state.redeem.box.item)
  const selection = useSelector(state => state.redeem.selection)
  const info = useSelector(state => state.redeem.information)
  const save = useSelector(state => state.redeem.save)
  const [valid, setValid] = useState(false)
  const [ack, setAck] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const shippings = selection
    .map(({ product: { vendorInfos } }) => vendorInfos)
    .reduce(
      (acc, item) => (acc.find(i => i.id === item.id) ? acc : [...acc, item]),
      []
    )

  useEffect(() => {
    if (!box) navigate('/redeem')
    else if (selection.length === 0) navigate('/redeem/selection')
    else if (!info) navigate('/redeem/information')
  }, []) // eslint-disable-line

  useEffect(() => {
    setValid(refForm.current.checkValidity())
  }, [ack])

  useDidMountEffect(() => {
    if (!save.url) return

    NotificationManager.addSuccess(REDEEM_SAVED)
    navigate('/redeem/thanks')
  }, [save.url]) // eslint-disable-line

  useDidMountEffect(() => {
    if (!save.error) return

    setSubmitted(false)
    NotificationManager.addError(INTERNAL_ERROR)
  }, [save.error])

  useDidMountEffect(() => {
    if (save.exceptions?.length === 0) return

    setSubmitted(false)
    save.exceptions.map(ex => NotificationManager.addError(ex))
  }, [save.exceptions])

  const submit = e => {
    setSubmitted(true)

    e.preventDefault()
    e.stopPropagation()

    dispatch(saveRedeem())
  }

  const modifiers = save.loading ? styles._loading : ''
  const hasInfo = info && Object.keys(info).length

  return (
    <LayoutFixed
      title={'Votre récapitulatif'}
      nav={<Header stepper gifts={box?.gifts} />}
      opposite={true}
    >
      <div className={`${styles.wrapper} ${modifiers}`}>
        <div className={styles.toptitle}>
          <Title level={1}>Votre récapitulatif</Title>
        </div>
        <div className={styles.baseline}>
          Une dernière étape et c'est fini ! Vérifiez que toutes vos
          informations sont correctes, pour que les bons produits soient livrés
          au bon endroit !
        </div>
        <div className={styles.inner}>
          <div className={styles.loader}>
            <Loader />
          </div>
          <div className={styles.content}>
            <div className={styles.gifts}>
              <div className={styles.title}>
                <Title level={2}>
                  {selection.length > 1 ? 'Vos cadeaux' : 'Votre cadeau'}
                </Title>
              </div>
              <div className={styles.giftsList}>
                <Grid
                  cols={1}
                  s={3}
                  items={selection.map(i => (
                    <ProductThumbnail
                      product={i.product}
                      variant={i.variant}
                      readonly
                      showVendor
                    />
                  ))}
                />
              </div>
            </div>

            {shippings.length > 0 && (
              <div className={styles.shippings}>
                <div className={styles.title}>
                  <Title level={2}>Informations de livraison</Title>
                </div>
                <div className={styles.shippingsContent}>
                  {shippings.map(({ name, shippingTime, info }) => (
                    <div key={name} className={styles.shippingItem}>
                      <div className={styles.shippingTitle}>
                        <Subheading>{name}</Subheading>
                      </div>
                      <div className={styles.expedition}>
                        Expédié sous {shippingTime}{' '}
                        {shippingTime > 1 ? 'jours ouvrés' : 'jour ouvré'}
                      </div>
                      {info && (
                        <div className={styles.shippingInfo}>{info}</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className={styles.info}>
              <div className={styles.title}>
                <Title level={2}>Vos coordonnées</Title>
              </div>
              {hasInfo && <ContactDetails details={info} />}

              <form ref={refForm} className={styles.form}>
                <div className={styles.ack}>
                  <Checkbox
                    id={'FormAck'}
                    required
                    onChange={() => setAck(!ack)}
                    value={'ack'}
                  >
                    Je reconnais que ces informations sont correctes et qu'une
                    fois validée, ma commande ne pourra plus être modifiée
                  </Checkbox>
                </div>
                <div className={styles.alert}>
                  <Card warning light>
                    Attention ! Une fois validée, votre commande ne pourra plus
                    être modifiée.
                  </Card>
                </div>
                <div className={styles.ctas}>
                  <div className={styles.cta}>
                    <ButtonLink to={'/redeem/information'}>Retour</ButtonLink>
                  </div>
                  <div className={styles.cta}>
                    <Button
                      primary
                      disabled={!valid || submitted}
                      onClick={submit}
                    >
                      Je valide ma commande
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LayoutFixed>
  )
}
