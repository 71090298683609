import React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from 'dates'
import store from 'stores/Admin/BoxesStore'

/*
 * Properties:
 *
 * * shippingId           [Number]
 * * name                 [String]
 * * uniqueKey            [String]
 * * expectedDeliveryFrom [String]
 * * confirmedAt          [String]
 * * cancelReason         [String]
 * * trackingRef          [String]
 * * boxId                [Number]
 */
export default class Supplier extends React.Component {
  cancel() {
    let reason = window.prompt("Raison de l'annulation")
    if (reason) {
      store.cancelShipping(this.props.shippingId, reason, this.props.boxId)
    }
  }

  expectedDeliveryFrom() {
    if (!this.props.cancelReason) {
      return <span>({formatDate(this.props.expectedDeliveryFrom)})&nbsp;</span>
    }
  }

  trackingRef() {
    if (this.props.trackingRef) {
      return ` (suivi: "${this.props.trackingRef}")`
    }
  }

  button() {
    if (this.props.cancelReason) {
      return <span>Annulé: {this.props.cancelReason}</span>
    } else {
      if (this.props.confirmedAt) {
        return (
          <button
            className="btn danger"
            onClick={e => this.cancel()}
            title="Attention: Cette commande a déjà été confirmée par le fournisseur."
          >
            Annuler
          </button>
        )
      } else {
        return (
          <button className="btn" onClick={e => this.cancel()}>
            Annuler
          </button>
        )
      }
    }
  }

  render() {
    return (
      <div className="supplier">
        <Link to={`/suppliers/${this.props.uniqueKey}`}>{this.props.name}</Link>
        &nbsp;
        {this.expectedDeliveryFrom()}
        {this.trackingRef()}
        {this.button()}
      </div>
    )
  }
}
