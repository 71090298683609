import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class ProductsStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      products: [],
    }
  }

  async load() {
    let resp = await fetch(`${config.apiUrl}/api/admin/products/list`, {
      method: 'POST',
    })
    let data = await resp.json()

    if (data.success) {
      this.setState({ products: data.products })
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new ProductsStore()
export default store
