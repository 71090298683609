import { useState } from 'react'
import {
  Button,
  Card,
  Divider,
  Modal,
  RadioList,
  Subheading,
  TextArea,
} from 'ui'

const INITIAL_RATING_STATE = 5
const INITIAL_COMMENT_STATE = ''

export const FormReviewModal = ({ shipping, onCancel, onSubmit }) => {
  const [rating, setRating] = useState(INITIAL_RATING_STATE)
  const [comment, setComment] = useState(INITIAL_COMMENT_STATE)

  const cancel = () => {
    onCancel()
    setRating(INITIAL_RATING_STATE)
    setComment(INITIAL_COMMENT_STATE)
  }

  const submit = () => {
    onSubmit(rating, comment)
    setRating(INITIAL_RATING_STATE)
    setComment(INITIAL_COMMENT_STATE)
  }

  const isDisabled = !rating || comment === ''
  return (
    <Modal
      opened={!!shipping}
      title={`C'est noté ! Qu'avez-vous pensé de ${
        shipping ? shipping.supplierName : ''
      } ?`}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={isDisabled} onClick={submit}>
          Envoyer
        </Button>,
      ]}
      onClose={cancel}
    >
      {shipping && (
        <div>
          <Subheading>Quelle note leur donneriez-vous ?</Subheading>
          <Divider small />
          <RadioList
            id={'Rating'}
            inline
            onChange={v => setRating(parseInt(v))}
            value={rating?.toString()}
            required
            options={[
              {
                label: '1',
                value: '1',
              },
              {
                label: '2',
                value: '2',
              },
              {
                label: '3',
                value: '3',
              },
              {
                label: '4',
                value: '4',
              },
              {
                label: '5',
                value: '5',
              },
            ]}
          />
          <Divider />

          <Subheading>Avez-vous un commentaire ?</Subheading>
          <Divider small />
          <TextArea
            id="Comment"
            label={`Votre commentaire`}
            rows={4}
            autoResize
            required
            initialValue={comment}
            onChange={v => setComment(v)}
          />

          <Divider />

          <Card info light>
            <div>Votre avis sera publié sur notre site après modération.</div>

            <div>
              <b>Votre anonymat est respecté :</b> seuls votre prénom et la
              première lettre de votre nom apparaîtront sur la publication.
              Veillez à ne mettre aucune information personnelle dans votre
              publication.
            </div>
          </Card>
        </div>
      )}
    </Modal>
  )
}
