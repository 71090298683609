import React from 'react'
import PropTypes from 'prop-types'
import { Badge, FormGroup, Grid } from 'ui'

export const B2BOrderTracking = ({ order }) => {
  const getTrackingItemsBadges = () => {
    let badges = order.trackingItems.map(item => (
      <div key={item.number}>
        <Badge
          key={item.url}
          small
          label={`${item.number} (${item.nbBoxes} coffret${
            item.nbBoxes > 1 ? 's' : ''
          })`}
          to={item.url}
          external={true}
        />
      </div>
    ))
    return badges
  }

  return (
    <FormGroup title={'Expéditions'}>
      <Grid items={getTrackingItemsBadges()} s={3} />
    </FormGroup>
  )
}

B2BOrderTracking.prototype = {
  order: PropTypes.shape({
    trackingItems: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        url: PropTypes.string,
        nbBoxes: PropTypes.number,
      })
    ),
  }),
}
