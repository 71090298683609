import React from 'react'
import { Badge, Card, Grid } from 'ui'
import styles from './Form.module.scss'
import PropTypes from 'prop-types'

export const Form = props => {
  return (
    <form ref={props.innerRef}>
      <div className={styles.header}>
        <div className={styles.titles}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.subTitle}>{props.subTitle}</div>
        </div>
        <div className={styles.badges}>
          {props.badges?.map(badge => {
            return (
              <div className={styles.badge} key={badge.label}>
                <Badge small {...badge} />
              </div>
            )
          })}
        </div>
      </div>
      {props.children}
    </form>
  )
}

Form.prototype = {
  innerRef: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  badges: PropTypes.array,
}

export const FormGroup = props => {
  return (
    <div className={styles.group}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>
        <Card light large>
          {props.children}
        </Card>
      </div>
    </div>
  )
}

FormGroup.prototype = {
  title: PropTypes.string,
}

export const FormSection = props => {
  let nbCols = 1
  let items = [props.children]

  if (props.children.length) {
    nbCols = props.children.length
    items = [...props.children]
  }

  let modifiers = ''
  if (props.gutter) modifiers += ` ${styles._gutter}`

  return (
    <div className={`${styles.section}${modifiers}`}>
      <Grid cols={1} s={nbCols} items={items} alignItems={'center'} />
    </div>
  )
}

FormSection.prototype = {
  gutter: PropTypes.bool,
}
