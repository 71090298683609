import React from 'react'
import Icon from './Icon'

export const IconFilter = props => (
  <Icon {...props}>
    <path d="M3 17H13" />
    <path d="M10 7H20" />
    <circle cx="17" cy="17" r="3" />
    <circle cx="6" cy="7" r="3" />
  </Icon>
)
