import React from 'react'
import store from 'stores/SupplierThreadPageStore'

/*
 * Properties:
 *
 * * closed [Boolean]
 *
 */
export default class ThreadStatus extends React.Component {
  render() {
    if (this.props.closed) {
      return (
        <div className="feature closed" id="close">
          <p>
            Cette discussion a été fermée. Vous pouvez néanmoins continuer à y
            envoyer des messages, la discussion sera réouverte si un nouveau
            message du client arrive.
          </p>
        </div>
      )
    } else {
      return (
        <div className="feature" id="close">
          <p>
            <button className="btn" onClick={e => store.closeThread()}>
              Marquer comme résolu
            </button>
          </p>
          <p className="text-help">
            Fermez cette discussion pour ne plus recevoir de mails de relance à
            son sujet. Le client ne recevra pas de notification.
          </p>
        </div>
      )
    }
  }
}
