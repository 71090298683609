import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class EditBoxPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      id: null,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
      zipcode: '',
      city: '',
    }
  }

  async load(id) {
    let params = {
      id,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(
      `${config.apiUrl}/api/admin/boxes/shipping-address`,
      {
        method: 'POST',
        body,
      }
    )
    let data = await resp.json()

    if (data.success) {
      let { firstName, lastName, phone, email, address, zipcode, city } =
        data.shippingInfo
      this.setState({
        id,
        firstName,
        lastName,
        phone,
        email,
        address,
        zipcode,
        city,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  firstNameChanged(firstName) {
    this.setState({ firstName })
  }

  lastNameChanged(lastName) {
    this.setState({ lastName })
  }

  phoneChanged(phone) {
    this.setState({ phone })
  }

  emailChanged(email) {
    this.setState({ email })
  }

  addressChanged(address) {
    this.setState({ address })
  }

  zipcodeChanged(zipcode) {
    this.setState({ zipcode })
  }

  cityChanged(city) {
    this.setState({ city })
  }

  async submit() {
    let params = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      email: this.state.email,
      address: this.state.address,
      zipcode: this.state.zipcode,
      city: this.state.city,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/boxes/update`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      alert('commande mise à jour')
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new EditBoxPageStore()
export default store
