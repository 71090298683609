import React from 'react'
import { formatDateTime } from 'dates'
import store from 'stores/SupplierReviewsPageStore.js'

/*
 * Properties:
 *
 * * shipping     [Object]
 * * customer     [Object]
 * * rating       [Number]
 * * comment      [String]
 * * answer       [String]
 */
export default class Review extends React.Component {
  constructor(props) {
    super(props)
    this.state = { shown: false, content: '' }
  }

  comment() {
    if (this.props.comment) {
      return (
        <blockquote className="comment">
          {this.props.comment.split('\n').map((l, i) => (
            <p key={i}>{l}</p>
          ))}
        </blockquote>
      )
    }
  }

  submit = e => {
    e.preventDefault()
    store.sendAnswer(this.props.shipping.id, this.state.content)
    this.setState({ content: '', shown: false })
  }

  form() {
    return (
      <form onSubmit={this.submit}>
        <label>
          Réponse
          <textarea
            className="no-transition text-field"
            onChange={e => this.setState({ content: e.target.value })}
            value={this.state.content}
          />
        </label>

        <p>
          <b>
            Votre droit de réponse est public et diffusé sur notre site. Veillez
            à ne saisir aucune information personnelle concernant votre client
            ou vous-même.
          </b>
        </p>

        <p>
          <button className="btn">Répondre</button>
        </p>
      </form>
    )
  }

  answer() {
    if (this.props.answer) {
      return (
        <div>
          <p>Votre réponse:</p>

          <blockquote className="comment">
            {this.props.answer.split('\n').map((l, i) => (
              <p key={i}>{l}</p>
            ))}
          </blockquote>
        </div>
      )
    } else {
      if (this.state.shown) {
        return this.form()
      } else {
        return (
          <p>
            <button
              className="btn link"
              onClick={e => this.setState({ shown: true })}
            >
              Droit de réponse
            </button>
          </p>
        )
      }
    }
  }

  render() {
    return (
      <div className="Rating">
        <header>
          Le {formatDateTime(this.props.shipping.receivedAt)},{' '}
          <a href={`mailto:${this.props.customer.email}`}>
            {this.props.customer.firstName} {this.props.customer.lastName}
          </a>{' '}
          a mentionné:
        </header>
        {this.comment()}
        <p className="rating">
          <em>Note</em>: {this.props.rating}
        </p>

        {this.answer()}
      </div>
    )
  }
}
