import React from 'react'
import moment from 'moment'
import { formatDate } from 'dates'

/*
 * Properties:
 *
 * * id                    [Int]
 * * createdAt             [String]
 * * expectedDeliveryFrom  [String]
 * * sent                  [Boolean]
 * * sentAt                [String]
 * * acceptedAt            [String]
 * * additionalInfo        [String]
 * * trackingRef           [String]
 * * firstName             [String]
 * * lastName              [String]
 * * phoneNumber           [String]
 * * email                 [String]
 * * address               [String]
 * * zipcode               [String]
 * * city                  [String]
 */
export default class ShippingInfo extends React.Component {
  trackingRef() {
    if (this.props.trackingRef) {
      return <h2>Référence suivi : {this.props.trackingRef}</h2>
    }
  }

  deliveryDates() {
    if (this.props.sent) {
      return (
        <>
          <p>Accepté le : {formatDate(moment(this.props.acceptedAt))}</p>
          <p>Expédié le : {formatDate(moment(this.props.sentAt))}</p>
        </>
      )
    }
  }

  additionalInfo() {
    if (this.props.additionalInfo) {
      return <p>Produit : {this.props.additionalInfo}</p>
    }
  }

  render() {
    return (
      <div id="shipping-info">
        <div className="customer">
          <p>
            {this.props.firstName} {this.props.lastName}
          </p>
          <p>
            {this.props.email} / {this.props.phoneNumber}
          </p>
          <p>
            {this.props.address} {this.props.zipcode} {this.props.city}
          </p>
        </div>

        <div className="order">
          {this.trackingRef()}
          <p>Numéro de commande : {this.props.id}</p>
          {this.additionalInfo()}
          <p>Commandé le : {formatDate(moment(this.props.createdAt))}</p>
          {this.deliveryDates()}
        </div>
      </div>
    )
  }
}
