import React from 'react'
import { getUnansweredThreads } from 'services/messages'
import { FeatureAdminSupportThreads } from '../components'

export const FeatureAdminSupportThreadsUnanswered = () => (
  <FeatureAdminSupportThreads
    title={'Messages sans réponse'}
    callback={getUnansweredThreads}
  />
)
