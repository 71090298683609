import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BBoxesClosed = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes physiques clôturées"
    deliveryMethod={1}
    status={'closed'}
  />
)
