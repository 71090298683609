import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, FormSection } from 'ui'
import styles from './B2BOrderActivation.module.scss'

export const B2BOrderActivation = ({ order }) => {
  return (
    <FormGroup title={"Période d'activation"}>
      <FormSection>
        <div>
          <div className={styles.label}>Début</div>
          <div>{order.activatedAt}</div>
        </div>
        <div>
          <div className={styles.label}>Fin</div>
          <div>{order.activatedTo}</div>
        </div>
      </FormSection>
    </FormGroup>
  )
}

B2BOrderActivation.prototype = {
  order: PropTypes.shape({
    activatedAt: PropTypes.string.isRequired,
    activatedTo: PropTypes.string.isRequired,
  }),
}
