import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class UserStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      pageCount: 10,
      currentPage: 1,
      search: '',
      users: [],
    }
  }

  async load(page, search) {
    let params = {
      page,
      search,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/users/list`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      let { users, currentPage, search, pageCount } = data
      this.setState({ users, currentPage, search, pageCount })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  searchChanged(search) {
    this.setState({ search })
  }

  async revoke(email) {
    let params = {
      email,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/users/revoke`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      this.setState(state => {
        state.users = state.users.filter(u => u.email !== email)
        return state
      })
      alert("L'utilisateur a bien été supprimé.")
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new UserStore()
export default store
