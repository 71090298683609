import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ButtonLink,
  Grid,
  LayoutFixed,
  MenuSecondary,
  SupplierCard,
  IconExport,
  IconPlus,
} from 'ui'
import { AdminActions, AdminNavigation } from 'features/admin/components'
import { getRouteParam, setRouteParam } from 'utils/route'
import { exportSuppliers, getAdminSuppliers } from 'services/suppliers'
import { MENU_LINKS } from 'features/admin/suppliers/@commons'
import { waitForApi } from 'utils/api'
import { sendData } from 'download'
import { NotificationManager } from 'services/notifications'
import { withRouter } from 'hocs/withRouter'
import { MIME_CSV } from 'utils/file'

const DATA_EXPORTED = 'Les données ont été exportées'

class SuppliersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      pagination: {},
      result: { suppliers: [] },
    }
  }

  loadData = () => {
    const { active, deleted, late, off } = this.props
    const page = this.page()
    const query = this.query()

    return getAdminSuppliers({ active, deleted, late, off, page, query })
  }

  onExport = () => {
    this.setState({ loading: true })

    return waitForApi(exportSuppliers())
      .then(this.onExportSuccess)
      .catch(this.onError)
  }

  onExportSuccess = ({ result }) => {
    this.setState({ loading: false })
    sendData(result.file, MIME_CSV, result.name)
    NotificationManager.addSuccess(DATA_EXPORTED)
  }

  onError = error => this.setState({ error, loading: false })

  onFilter = filter => {
    let location = setRouteParam(this.props.location, 'q', filter)
    location = setRouteParam(location, 'page', 1)

    this.props.navigate(location)
  }

  page() {
    return parseInt(getRouteParam(this.props.location, 'page')) || 1
  }

  query() {
    return getRouteParam(this.props.location, 'q') || ''
  }

  getActions() {
    const { showAdd, showExport } = this.props

    const actions = []
    if (showExport) {
      actions.push(
        <Button
          light
          icon={<IconExport />}
          onClick={this.onExport}
          hideLabelOnMobile
        >
          Exporter
        </Button>
      )
    }
    if (showAdd) {
      actions.push(
        <ButtonLink
          primary
          to={`/a/suppliers/new`}
          icon={<IconPlus />}
          hideLabelOnMobile
        >
          Créer
        </ButtonLink>
      )
    }
    return actions
  }

  render() {
    const {
      error,
      loading,
      pagination: { nbItems },
      result: { suppliers },
    } = this.state

    return (
      <LayoutFixed
        title={'Artisans'}
        actions={
          <AdminActions
            query={this.query()}
            onFilter={this.onFilter}
            total={nbItems}
            actions={this.getActions()}
          />
        }
        nav={<AdminNavigation title={'Artisans'} />}
        menu={<MenuSecondary links={MENU_LINKS} />}
        error={error}
        loading={loading}
        loadData={this.loadData}
        onDataLoaded={data => this.setState({ ...data })}
      >
        <Grid
          cols={1}
          l={2}
          items={suppliers.map(s => (
            <SupplierCard key={s.id} supplier={s} />
          ))}
        />
      </LayoutFixed>
    )
  }
}

export const FeatureAdminSuppliersList = withRouter(SuppliersList)

FeatureAdminSuppliersList.propTypes = {
  active: PropTypes.bool,
  deleted: PropTypes.bool,
  late: PropTypes.bool,
  off: PropTypes.bool,
  showAdd: PropTypes.bool,
  showExport: PropTypes.bool,
}

FeatureAdminSuppliersList.defaultProps = {
  active: null,
  deleted: null,
  late: null,
  off: null,
  showAdd: false,
  showExport: false,
}
