import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentSummary.module.scss'
import { Card, Figure } from 'ui'
import { InvoicingChange } from '../../components'

export const ContentSummary = props => {
  const { hasAutoInvoicing, summary, showEmptyMessage } = props

  const showTotalVentesDirectes = summary.totalVentesDirectes !== 0
  const showTotalCoffretsCadeaux = summary.totalCoffretsCadeaux !== 0

  const showTotalEtiquettesVentesDirectes =
    summary.totalEtiquettesVentesDirectes !== 0
  const showTotalEtiquettesCoffretsCadeaux =
    summary.totalEtiquettesCoffretsCadeaux !== 0

  const noSells = !showTotalVentesDirectes && !showTotalCoffretsCadeaux
  const noServices =
    !showTotalEtiquettesVentesDirectes && !showTotalEtiquettesCoffretsCadeaux

  return (
    <section className={styles.summary} id="summary">
      {!hasAutoInvoicing && (
        <Card error light>
          Attention : la facturation automatisée n'est pas encore disponible
          pour vous, car vous vendez des produits avec différents taux de TVA.
          Veuillez donc nous faire parvenir une facture tous les mois avec vos
          expéditions du mois passé à facturation@icipresent.com.
          <br />
          L'équipe Ici Présent! ❤️
        </Card>
      )}
      {hasAutoInvoicing && showEmptyMessage && (
        <div className={styles.empty}>
          Aucune facture n'est disponible pour le moment.
        </div>
      )}

      {hasAutoInvoicing && !showEmptyMessage && (
        <div>
          <InvoicingChange />

          <header className={styles.solde}>
            <Figure
              label={'Solde du mois'}
              value={`${summary.balance >= 0 ? '+' : ''}${summary.balance} €`}
            />
            {summary.ongoing && (
              <div className={styles.ongoing}>
                Solde des encours :{' '}
                {`${summary.ongoing >= 0 ? '+' : ''}${summary.ongoing} €`}
              </div>
            )}
          </header>

          <Card light>
            <section className={styles.details}>
              {!noSells && (
                <div className={styles.ventes}>
                  <h2 className={styles.subtitle}>Ventes</h2>
                  {showTotalVentesDirectes && (
                    <div className={styles.directes}>
                      <Figure
                        small
                        label={'Ventes directes'}
                        value={`+${summary.totalVentesDirectes} €`}
                      />
                    </div>
                  )}
                  {showTotalCoffretsCadeaux && (
                    <div className={styles.coffrets}>
                      <Figure
                        small
                        label={'Coffrets cadeaux'}
                        value={`+${summary.totalCoffretsCadeaux} €`}
                      />
                    </div>
                  )}
                </div>
              )}
              {!noServices && (
                <div className={styles.services}>
                  <h2 className={styles.subtitle}>Services</h2>
                  {showTotalEtiquettesVentesDirectes && (
                    <div className={styles.edirectes}>
                      <Figure
                        small
                        label={'Etiquettes Ventes directes'}
                        value={`-${summary.totalEtiquettesVentesDirectes} €`}
                      />
                    </div>
                  )}
                  {showTotalEtiquettesCoffretsCadeaux && (
                    <div className={styles.ecoffrets}>
                      <Figure
                        small
                        label={'Etiquettes Coffrets cadeaux'}
                        value={`-${summary.totalEtiquettesCoffretsCadeaux} €`}
                      />
                    </div>
                  )}
                </div>
              )}
            </section>
          </Card>
        </div>
      )}
    </section>
  )
}

ContentSummary.propTypes = {
  summary: PropTypes.object,
  hasAutoInvoicing: PropTypes.bool,
  showEmptyMessage: PropTypes.bool,
}
