import BaseStore from 'stores/BaseStore'
import config from 'config.js'
import { fetch } from 'utils/api'

class SupplierReviewsPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      uniqueKey: '',
      pageCount: 0,
      page: 1,
      errors: [],
      reviews: [],
    }
  }

  async load(uniqueKey, page) {
    let params = {
      uniqueKey,
      page,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/suppliers/reviews/list`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      this.setState({
        reviews: data.reviews,
        pageCount: data.pageCount,
        uniqueKey,
        page,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  async sendAnswer(shippingId, answer) {
    let params = {
      uniqueKey: this.state.uniqueKey,
      shippingId,
      answer,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/suppliers/reviews/answer`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      this.setState(state => {
        state.reviews.find(r => r.shipping.id === shippingId).answer = answer
        return state
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new SupplierReviewsPageStore()
export default store
