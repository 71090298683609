import React from 'react'
import styles from './ReviewCardHeader.module.scss'
import { Link } from 'ui'
import { ReviewCardActions } from './ReviewCardActions'
import { formatToDate} from 'utils/time'

export const ReviewCardHeader = props => (
  <div className={styles.header}>
    <div className={styles.wrapper}>
      <div className={styles.name}>
        {props.review.customer.firstName} {props.review.customer.lastName}
      </div>
      <div className={styles.infos}>
        {formatToDate(props.review.createdAt)} pour&nbsp;
        <Link to={`/suppliers/${props.review.supplier.uniqueKey}`}>
          {props.review.supplier.name}
        </Link>
      </div>
    </div>
    <div className={styles.id}>#{props.review.id}</div>
    <div className={styles.actions}>
      <ReviewCardActions {...props} />
    </div>
  </div>
)
