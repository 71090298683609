import React from 'react'
import { Link } from 'ui'
import styles from './OrderB2CCardInfos.module.scss'

const SHOPIFY_ORDER_URL =
  'https://admin.shopify.com/store/les-petites-boites/orders/'

export const OrderB2CCardInfos = ({ order }) => {
  const shopifyValue = order.shopifyId || 'Aucun'

  let orderLink = null,
    shopifyLink = null
  if (order.shopifyInternalId) {
    orderLink = `/orders/${order.shopifyInternalId}`
    shopifyLink = `${SHOPIFY_ORDER_URL}${order.shopifyInternalId}`
  }

  return (
    <div className={styles.infos}>
      {renderInfosBasicData('ID', order.id, orderLink)}
      {renderInfosBasicData('Prénom', order.customer.firstName)}
      {renderInfosBasicData('ID Shopify', shopifyValue, shopifyLink)}
      {renderInfosBasicData('Nom', order.customer.lastName)}
      {renderInfosBasicData('Coffrets', order.boxes?.length)}
      {renderInfoEmailData('Email', order.customer.email)}
    </div>
  )
}

const renderInfosBasicData = (label, value, link = null) => {
  const content = link ? (
    <Link external to={link}>
      {value}
    </Link>
  ) : (
    value
  )

  return (
    <div className={styles.item}>
      <div className={styles.itemLabel}>{label}</div>
      <div className={styles.itemValue}>{content}</div>
    </div>
  )
}

const renderInfoEmailData = (label, value) => {
  const mailTo = 'mailto:' + value

  return (
    <div className={styles.item}>
      <div className={styles.itemLabel}>{label}</div>
      <div className={styles.itemValue} title={value}>
        <Link to={mailTo}>{value}</Link>
      </div>
    </div>
  )
}
