import React from 'react'
import { IconFlag } from 'ui'

export const getMenuLinks = (key, period, invoiceTypes) => {
  let links = [
    {
      to: `/suppliers/${key}/invoices/${period.year}/${period.month}/resume`,
      label: 'Résumé',
      icon: <IconFlag fill={'#4e4b66'} />,
      primary: true,
    },
    {
      divider: true,
    },
  ]

  links.push(
    ...invoiceTypes.map(it => {
      return {
        to: `/suppliers/${key}/invoices/${period.year}/${period.month}/${it.name}`,
        label: it.label,
      }
    })
  )

  return links
}
