import React from 'react'
import PropTypes from 'prop-types'
import styles from './ExpeditionForm.module.scss'
import { Button, Card, Checkbox, CheckboxList, TextArea, TextField } from 'ui'

const SHIPPING_TIME_MIN = 1
const SHIPPING_TIME_MAX = 15 // because we send an email to know if the it's received 15 days after the expedition

export class ExpeditionForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      config: { ...props.config },
      allShippers: { ...props.allShippers },
      activeShippers: [...props.activeShippers],
      changed: false,
    }
  }

  canSubmit() {
    const hasShipper =
      this.state.config.useColissimo ||
      this.state.activeShippers.length > 0 ||
      this.state.config.useUnknownShipper

    return hasShipper && this.isShippingValid()
  }

  isShippingValid = () => {
    const {
      config: { shippingTime },
    } = this.state

    return (
      shippingTime >= SHIPPING_TIME_MIN && shippingTime <= SHIPPING_TIME_MAX
    )
  }

  onShippersChange = activeShippers => {
    this.setState({ activeShippers })
  }

  onUseUnknownShipperChange = (_, useUnknownShipper) => {
    const config = { ...this.state.config, useUnknownShipper }
    this.setState({ config })
  }

  onShippingTimeChange = value => {
    const config = { ...this.state.config, shippingTime: parseInt(value) }
    this.setState({ config })
  }

  onInfoChange = value => {
    const config = { ...this.state.config, info: value }
    this.setState({ config })
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.config, this.state.activeShippers)
  }

  render() {
    const { allShippers, activeShippers, config } = this.props

    return (
      <form className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.intro}>
            Renseignez ici toutes les informations nécessaires au bon
            déroulement de vos expéditions.
          </div>
          <div className={styles.card}>
            <Card large light>
              <div className={styles.title}>Vos informations</div>
              <div className={styles.block}>
                <div className={styles.text}>
                  Sous combien de jours ouvrés vos colis sont-ils expédiés ?
                </div>
                <div className={styles.field}>
                  <TextField
                    id={'ShippingTime'}
                    label={"Délai d'expédition (jours ouvrés)"}
                    max={SHIPPING_TIME_MAX}
                    min={SHIPPING_TIME_MIN}
                    step={1}
                    type="number"
                    required
                    autoValidate
                    validate={!config.shippingTime}
                    initialValue={config.shippingTime.toString()}
                    onChange={this.onShippingTimeChange}
                  />
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.text}>
                  Souhaitez-vous ajouter des informations supplémentaires (type
                  de livraison ou autre) ?
                </div>
                <div className={styles.field}>
                  <TextArea
                    id={'FormInfos'}
                    label={'Informations'}
                    rows={4}
                    initialValue={config.info}
                    autoResize={true}
                    onChange={this.onInfoChange}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.card}>
            <Card large light>
              <div className={styles.title}>Vos transporteurs</div>

              <div className={styles.block}>
                {config.useColissimo && (
                  <div className={styles.colissimo}>
                    <Card info light padding>
                      Vous avez souscrit à notre option Colissimo, vous n'avez
                      donc pas à choisir de transporteur.
                    </Card>
                  </div>
                )}
                {!config.useColissimo && (
                  <div className={styles.shippers}>
                    <CheckboxList
                      id={'FormShippers'}
                      onChange={this.onShippersChange}
                      options={allShippers}
                      values={activeShippers}
                    />

                    <div className={styles.notListed}>
                      <Card info light padding>
                        <Checkbox
                          id={'FormUseUnknownShipper'}
                          onChange={this.onUseUnknownShipperChange}
                          checked={config.useUnknownShipper}
                          value={''}
                        >
                          J'utilise un autre transporteur
                        </Checkbox>
                      </Card>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className={styles.valid}>
            <Button
              primary
              disabled={!this.canSubmit()}
              onClick={this.onSubmit}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

ExpeditionForm.propTypes = {
  config: PropTypes.shape({
    shippingTime: PropTypes.number,
    info: PropTypes.string,
    useUnknownShipper: PropTypes.bool,
  }).isRequired,
  allShippers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  activeShippers: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
}
