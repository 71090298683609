import React from 'react'
import { getShippingStats } from 'services/shippings'
import { Stats } from './components'
import { LayoutFixed, MenuSecondary } from 'ui'
import { SupplierNavigation } from 'features/suppliers/components'
import { getMenuLinks } from 'features/suppliers/shippings/@commons'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

class ShippingsStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      result: { shippingStats: null, stats: {}, supplier: {} },
    }
  }

  loadData = () => {
    return waitForApi(getShippingStats({ uniqueKey: this.supplierKey() }))
  }

  supplierKey() {
    return this.props.params.uniqueKey
  }

  render() {
    const {
      result: { stats, shippingStats, supplier },
    } = this.state
    return (
      <LayoutFixed
        title="Statistiques de commandes"
        nav={
          <SupplierNavigation
            supplierKey={this.supplierKey()}
            supplierName={supplier.name}
            title={'Commandes'}
          />
        }
        menu={<MenuSecondary links={getMenuLinks(this.supplierKey(), stats)} />}
        loadData={this.loadData}
        onDataLoaded={data => this.setState({ ...data })}
      >
        {shippingStats && <Stats shippingStats={shippingStats} />}
      </LayoutFixed>
    )
  }
}

export const FeatureSuppliersShippingsStats = withRouter(ShippingsStats)
