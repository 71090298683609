import styles from './OffForm.module.scss'

import { useRef, useState, useEffect } from 'react'
import { Button, Card, DateSelector } from 'ui'
import { formatDate, formatToDate } from 'utils/time'

export const OffForm = ({ config, onSubmit }) => {
  const refForm = useRef()
  const [from, setFrom] = useState(config?.from || undefined)
  const [to, setTo] = useState(config?.to || undefined)
  const [canSubmit, setCanSubmit] = useState(false)

  const isActive = config.active
  const hasOffPeriod = !!config.from && !!config.to

  const d = new Date()
  const minDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  minDate.setDate(minDate.getDate() + 1)

  const now = formatDate(d)
  const isOff =
    hasOffPeriod &&
    formatDate(config.from) <= now &&
    formatDate(config.to) >= now

  const willBeOff = hasOffPeriod && now < formatDate(config.from)

  useEffect(() => {
    setCanSubmit(refForm.current.checkValidity() && to >= from && config.active)
  }, [from, to]) // eslint-disable-line

  return (
    <form ref={refForm}>
      <div className={styles.header}>
        Vous pouvez désactiver vos offres sur notre plateforme en précisant les
        dates ci-dessous, par exemple pour vos congés, rupture de stock, ...
      </div>

      {!isActive && (
        <div className={styles.alert}>
          <Card error light>
            Votre compte est actuellement désactivé, vous ne pouvez donc pas
            programmer de mise en pause.
          </Card>
        </div>
      )}
      {isOff && (
        <div className={styles.alert}>
          <Card info light>
            Vous êtes actuellement en pause sur notre plateforme jusqu'au{' '}
            {formatToDate(config.to, false)}.
          </Card>
        </div>
      )}
      {willBeOff && (
        <div className={styles.alert}>
          <Card info light>
            Vous serez en pause sur notre plateforme du{' '}
            {formatToDate(config.from, false)} au{' '}
            {formatToDate(config.to, false)}.
          </Card>
        </div>
      )}

      <div className={styles.form}>
        <Card large light>
          <div className={styles.title}>Vos dates de mise en pause</div>
          <div className={styles.block}>
            <div className={styles.text}>Date de début</div>
            <div className={styles.field}>
              {config && (
                <DateSelector
                  value={from}
                  onChange={setFrom}
                  disabled={!config.active}
                  min={minDate}
                />
              )}
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.text}>Date de fin (inclue)</div>
            <div className={styles.field}>
              {config && (
                <DateSelector
                  value={to}
                  onChange={setTo}
                  disabled={!config.active}
                  min={minDate}
                />
              )}
            </div>
          </div>
          <div className={styles.footer}>
            {hasOffPeriod && (
              <div className={styles.cta}>
                <Button
                  light
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onSubmit({ from: null, to: null })
                  }}
                >
                  Annuler ma mise en pause
                </Button>
              </div>
            )}
            <div className={styles.cta}>
              <Button
                primary
                disabled={!canSubmit}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onSubmit({ from: formatDate(from), to: formatDate(to) })
                }}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </form>
  )
}
