import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class BoxesStore extends BaseStore {
  setDefaults() {
    this.state = {
      pageCount: 0,
      currentPage: 1,
      totalResults: 0,
      boxes: [],
      products: [],
      errors: [],
      search: {
        field: 'all',
        query: '',
      },
      redeemed: 99,
      deliveryMethod: 99,
      plan: 99,
      product: 99,
    }
  }

  async load() {
    let params = {
      page: this.state.currentPage,
      search: this.state.search.field,
      query: this.state.search.query,
      redeemed: this.state.redeemed,
      deliveryMethod: this.state.deliveryMethod,
      giftNumber: this.state.giftNumber,
      product: this.state.product,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/boxes/list`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      this.setState({
        boxes: data.boxes,
        products: data.products,
        pageCount: data.pageCount,
        totalResults: data.totalResults,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  async pageChanged(currentPage) {
    this.setState({ currentPage })
    await this.load()
  }

  async searchFieldChanged(searchField) {
    this.setState(state => {
      state.search.field = searchField
      return state
    })
  }

  searchQueryChanged(searchQuery) {
    window.clearTimeout(this._searchQueryTimeout)

    this.setState(state => {
      state.search.query = searchQuery
      return state
    })
  }

  async delete(id) {
    const message = 'Attention, une suppression est définitive. Continuer?'
    if (window.confirm(message)) {
      let params = { id }
      let body = JSON.stringify(params)
      let resp = await fetch(`${config.apiUrl}/api/admin/boxes/delete`, {
        method: 'POST',
        body,
      })
      let data = await resp.json()

      if (data.success) {
        this.setState(state => {
          state.boxes = state.boxes.filter(box => box.id !== id)
          return state
        })
      } else {
        this.setState({ errors: data.errors })
      }
    }
  }

  async cancelShipping(shippingId, cancelReason, boxId) {
    let params = { shippingId, cancelReason }
    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/boxes/cancel-shipping`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      this.setState(state => {
        state.boxes.forEach(b => {
          if (b.id === boxId) {
            b.suppliers.find(s => s.shippingId === shippingId).cancelReason =
              cancelReason
          }
        })

        return state
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new BoxesStore()
export default store
