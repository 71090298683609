import React from 'react'
import PropTypes from 'prop-types'
import { Figure, FormGroup, FormSection } from 'ui'

export const B2BOrderSatisfaction = ({ stats, order }) => {
  const averageRating =
    stats?.averageRating && stats?.nbReviews > 0
      ? `${stats.averageRating}/5`
      : 'N/A'

  return (
    <FormGroup title={'Satisfaction'}>
      <FormSection gutter>
        <Figure
          small
          label={"Taux d'utilisation"}
          value={`${stats.useRate} %`}
        />
        <Figure
          small
          label={'Nombre de coffrets utilisés'}
          value={`${stats.nbBoxesUsed}/${order.giftBoxNumber}`}
        />
      </FormSection>
      <FormSection>
        <Figure small label={'Note moyenne'} value={`${averageRating}`} />
        <Figure
          small
          label={"Nombre d'avis postés"}
          value={`${stats.nbReviews}`}
        />
      </FormSection>
    </FormGroup>
  )
}

B2BOrderSatisfaction.prototype = {
  order: PropTypes.shape({
    giftBoxNumber: PropTypes.number.isRequired,
  }),
  stats: PropTypes.shape({
    useRate: PropTypes.number,
    nbBoxesUsed: PropTypes.number,
    averageRating: PropTypes.number,
    nbReviews: PropTypes.number,
  }),
}
