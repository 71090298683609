import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from 'ui'
import styles from './ExportB2BModal.module.scss'

export const ExportB2BModal = props => {
  let items = [
    <div className={styles.col}>
      <div className={styles.label}>
        Récupérez un fichier CSV qui contient tous les codes d'activation des
        coffrets
      </div>
      <div className={styles.btn}>
        <Button primary onClick={() => props.onExportCodes(props.order.id)}>
          Codes d'activation
        </Button>
      </div>
    </div>,
  ]

  if (!props.order.isPhysical) {
    items.push(
      <div className={styles.col}>
        <div className={styles.label}>
          Recevez un email avec les PDFs des E-Coffrets
        </div>
        <div className={styles.btn}>
          <Button primary onClick={() => props.onExportBoxes(props.order.id)}>
            E-coffrets
          </Button>
        </div>
      </div>
    )
  }

  if (!!props.order.activatedAt) {
    items.push(
      <div className={styles.col}>
        <div className={styles.label}>
          Récupérez un fichier CSV qui contient tous les avis
        </div>
        <div className={styles.btn}>
          <Button primary onClick={() => props.onExportReviews(props.order.id)}>
            Avis
          </Button>
        </div>
      </div>
    )
  }

  return <Grid cols={1} s={items.length} items={items} />
}

ExportB2BModal.prototype = {
  onExportBoxes: PropTypes.func,
  onExportCodes: PropTypes.func.isRequired,
  onExportReviews: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isPhysical: PropTypes.bool.isRequired,
    activatedAt: PropTypes.string,
  }),
}
