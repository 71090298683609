import React from 'react'
import Icon from './Icon'

export const IconAlert = props => (
  <Icon {...props}>
    <circle cx="12" cy="12" r="11" />
    <path d="M12 7V12" />
    <path d="M12 16V16.5" />
  </Icon>
)
