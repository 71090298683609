import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import store from 'stores/Admin/NewUserStore'

export default class NewUserPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
  }

  componentWillUnmount() {
    store.unsubscribe(this.updated)
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          email_taken: 'Un utilisateur existe déjà pour cette adresse mail.',
          unknown_supplier: "Il n'y a pas de fournisseur ayant cet ID.",
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  supplierFields() {
    if (!this.state.isAdmin) {
      return (
        <div className="input-group">
          <p>
            <b>Note</b>: Si vous ajoutez l'adresse mail d'un fournisseur dans
            les champs "email" de la fiche d'édition du fournisseur, un compte
            sera automatiquement créé sous une heure pour cette adresse mail, et
            un message y sera envoyé pour lui communiquer ses accès.
          </p>
          <p>
            Vous pouvez toutefois créer un compte ici pour le fournisseur, un
            second compte ne sera pas créé lorsque vous ajouterez l'adresse
            email dans les champs de la fiche fournisseur.
          </p>
          <label>
            ID du fournisseur
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.state.supplierId}
              onChange={e => store.supplierIdChanged(e.target.value)}
            />
          </label>
          <p className="text-help">
            Cet identifiant est le nombre suivant le nom du fournisseur, dans la
            liste des fournisseurs en admin.
          </p>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="page admin-page" id="NewUserPage">
        <Helmet>
          <title>Utilisateurs - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />
        <div className="group">
          <h1 className="h1">Nouvel utilisateur</h1>

          <p>
            Après avoir créé un utilisateur, celui-ci recevra un email contenant
            un mot de passe initial, pouvant être changé après connexion.
          </p>

          <div id="new-user" className="feature">
            <form
              onSubmit={e => {
                e.preventDefault()
                store.submit()
              }}
            >
              <div className="input-group">
                <label>
                  Adresse email
                  <input
                    type="text"
                    required={true}
                    className="text-field"
                    value={this.state.email}
                    onChange={e => store.emailChanged(e.target.value)}
                  />
                </label>
              </div>

              <div className="input-group">
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.isAdmin}
                    onChange={e => store.isAdminChanged(e.target.checked)}
                  />
                  Admin
                </label>
              </div>

              {this.supplierFields()}

              <p>
                <button className="btn">Créer</button>
              </p>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
