import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompanyForm.module.scss'
import { SUPPLIERS_DOCUMENTS_URL, SUPPLIERS_VAT } from 'services/suppliers'
import {
  Button,
  Card,
  TextFieldButton,
  TextField,
  IconPhone,
  IconMail,
  Checkbox,
  Link,
  Badge,
  Title,
  Selectbox,
} from 'ui'

export class CompanyForm extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      validate: false,
      cgpAccepted: props.cgpAcceptedDate.length > 0,
      config: { ...props.config },
    }
  }

  componentDidMount() {
    this.setState({
      config: this.props.config,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.config !== prevProps.config) {
      this.setState({ config: this.props.config })
    }
  }

  onChange(value) {
    const config = { ...this.state.config, ...value }
    this.setState({ config })
  }

  canSubmit() {
    return this.formRef?.current?.checkValidity() || false
  }

  onSubmit = e => {
    this.props.onSubmit(
      { ...this.state.config, vat: this.state.config.vat },
      this.state.cgpAccepted
    )

    e.preventDefault()
  }

  onCGPChange = (_, cgpAccepted) => {
    this.setState({ cgpAccepted })
  }

  onVatChange = value => {
    const vat = parseFloat(value)
    const config = { ...this.state.config, vat }

    if (isNaN(vat)) {
      config.vat = null
    }

    this.setState({ config })
  }

  render() {
    const { cgpAccepted, config, validate } = this.state
    const { cgpAcceptedDate } = this.props

    let vatValue = ''
    if (cgpAccepted) {
      vatValue = config.vat?.toString() || 'multi'
    }
    const multiVat = vatValue === 'multi'

    return (
      <form className={styles.container} ref={this.formRef}>
        <div className={styles.inner}>
          {!cgpAccepted && (
            <div className={styles.intro}>
              <Card large error light>
                <Title>
                  Une petite seconde{' '}
                  <span role="img" aria-label="S'il vous plaît">
                    🙏
                  </span>
                </Title>
                <div className={styles.introContent}>
                  Merci à vous de prendre le temps de compléter l'ensemble de
                  ces informations avant de pouvoir utiliser votre plateforme,
                  elles nous serons utiles tout au long de notre partenariat.
                  <br />
                  <br />
                  L'équipe Ici Présent!{' '}
                  <span role="img" aria-label="Qui vous adore !">
                    ❤️
                  </span>
                </div>
              </Card>
            </div>
          )}
          <div className={styles.card}>
            <Card large light>
              <div className={styles.block}>
                <div className={styles.title}>Votre numéro SIRET</div>
                <div className={styles.group}>
                  <div className={styles.smallfield}>
                    Renseignez votre numéro SIRET pour compléter les champs avec
                    les informations gouvernementales.
                  </div>
                  <div className={styles.smallfield}>
                    <TextFieldButton
                      id={'FormSiret'}
                      label={'Numéro SIRET'}
                      initialValue={config.siret}
                      onSubmit={this.props.onSiretRequest}
                      type={'number'}
                      required
                      autoValidate
                      min={10000000000000}
                      max={99999999999999}
                      validate={validate}
                    >
                      Valider
                    </TextFieldButton>
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.title}>Votre société</div>
                {multiVat && (
                  <div className={styles.warning}>
                    <Card warning light>
                      Attention : si vous vendez des produits avec différents
                      taux de TVA (exemple avec fromage + vin), la facturation
                      automatisée n'est pas encore disponible pour vous.
                      Veuillez donc nous faire parvenir une facture tous les
                      mois avec vos expéditions du mois passé.
                    </Card>
                  </div>
                )}
                <div className={styles.group}>
                  <div className={styles.field}>
                    <TextField
                      id={'FormCompanyName'}
                      label={'Nom de la société'}
                      initialValue={config.companyName}
                      onChange={value => this.onChange({ companyName: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.field}>
                    <Selectbox
                      initialValue={vatValue}
                      id={'FormCompanyVat'}
                      label={'TVA applicable (en %)'}
                      values={SUPPLIERS_VAT}
                      onChange={this.onVatChange}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.field}>
                    <TextField
                      id={'FormCompanyVatNumber'}
                      label={'Numéro de TVA Intracommunautaire'}
                      initialValue={config.vatNumber}
                      onChange={value => this.onChange({ vatNumber: value })}
                      required={config?.vat !== 0}
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.field}>
                    <TextField
                      id={'FormAddress1'}
                      label={'Adresse (numéro, type de rue, libellé)'}
                      initialValue={config.address1}
                      onChange={value => this.onChange({ address1: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.field}>
                    <TextField
                      id={'FormAddress2'}
                      label={"Complément d'adresse"}
                      initialValue={config.address2}
                      onChange={value => this.onChange({ address2: value })}
                    />
                  </div>
                  <div className={styles.smallfield}>
                    <TextField
                      id={'FormZipCode'}
                      label={'Code postal'}
                      initialValue={config.zipCode}
                      onChange={value => this.onChange({ zipCode: value })}
                      required
                      min={1}
                      max={99999}
                      type={'number'}
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.smallfield}>
                    <TextField
                      id={'FormCity'}
                      label={'Ville'}
                      initialValue={config.city}
                      onChange={value => this.onChange({ city: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.card}>
            <Card large light>
              <div className={styles.block}>
                <div className={styles.title}>Contact administratif</div>
                <div className={styles.desc}>
                  Contact dédié pour le suivi administratif : conditions du
                  partenariat, facturation, etc.
                </div>
                <div className={styles.group}>
                  <div className={styles.smallfield}>
                    <TextField
                      id={'FormLastName'}
                      label={'Nom'}
                      initialValue={config.lastName}
                      onChange={value => this.onChange({ lastName: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.smallfield}>
                    <TextField
                      id={'FormFirstName'}
                      label={'Prénom'}
                      initialValue={config.firstName}
                      onChange={value => this.onChange({ firstName: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.smallfield}>
                    <TextField
                      icon={<IconPhone />}
                      id={'FormPhone'}
                      label={'Numéro de téléphone'}
                      initialValue={config.phone}
                      type={'tel'}
                      onChange={value => this.onChange({ phone: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                  <div className={styles.smallfield}>
                    <TextField
                      icon={<IconMail />}
                      id={'FormEmail'}
                      label={'Email'}
                      initialValue={config.email}
                      type={'email'}
                      onChange={value => this.onChange({ email: value })}
                      required
                      autoValidate
                      validate={validate}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className={styles.card}>
            <Card large light>
              <div className={styles.cgv}>
                <div className={styles.checkbox}>
                  <Checkbox
                    id="FormCGP"
                    value={'cgp'}
                    onChange={this.onCGPChange}
                    checked={cgpAccepted}
                    disabled={cgpAccepted && cgpAcceptedDate.length > 0}
                    required
                    validate={validate}
                  >
                    J'ai lu et j'accepte les{' '}
                    <Link
                      to={SUPPLIERS_DOCUMENTS_URL}
                      external
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Conditions Générales de Partenariat
                    </Link>
                  </Checkbox>
                </div>
                {cgpAccepted && cgpAcceptedDate && (
                  <div className={styles.accepted}>
                    <Badge info label={`Accepté le ${cgpAcceptedDate}`} small />
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className={styles.valid}>
            <Button
              primary
              onClick={this.onSubmit}
              disabled={!this.canSubmit()}
            >
              Valider les informations
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

CompanyForm.propTypes = {
  cgpAcceptedDate: PropTypes.string.isRequired,
  config: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    vat: PropTypes.number,
    vatNumber: PropTypes.string,
    siret: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    companyName: PropTypes.string,
  }).isRequired,
  onSiretRequest: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
