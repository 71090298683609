import React from 'react'
import PropTypes from 'prop-types'
import styles from './ColissimoOrdersList.module.scss'
import { Grid, OrderB2BPackagerCard } from 'ui'

export const ColissimoOrdersList = props => {
  const isEmpty = !props.orders || props.orders.length === 0

  let modifiers = ''
  if (isEmpty) modifiers += ` ${styles._empty}`

  return (
    <section className={`${styles.list}${modifiers}`}>
      <div className={styles.head}>
        <span className={styles.title}>Suivi des expéditions Colissimo</span>
        <span className={styles.annotation}>
          Liste des commandes avec des coffrets-cadeaux non expédiés
        </span>
      </div>

      <Grid
        cols={1}
        items={props.orders?.map(o => (
          <OrderB2BPackagerCard
            key={o.id}
            order={o}
            onAddTrackingNumber={o => {
              props.onAddTrackingNumber(o)
            }}
          />
        ))}
      />
    </section>
  )
}

ColissimoOrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
}
