import React from 'react'
import PropTypes from 'prop-types'
import { ShippingStates } from 'services/shippings'
import { Badge, ShippingIcon } from 'ui'

export const ShippingBadge = props => (
  <Badge
    icon={<ShippingIcon status={props.status} />}
    label={ShippingStates[props.status].singular}
  />
)

ShippingBadge.propTypes = {
  status: PropTypes.oneOf([
    'accepted',
    'canceled',
    'delivered',
    'forthcoming',
    'received',
    'sent',
  ]).isRequired,
}
