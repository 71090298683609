import React from 'react'
import PropTypes from 'prop-types'
import styles from './Radio.module.scss'

export class Radio extends React.Component {
  constructor(props) {
    super(props)
    this.radioRef = React.createRef()
    this.state = { valid: false, validate: false, checked: false }
  }

  componentDidMount() {
    const state = {
      valid: this.radioRef.current.validity.valid,
      checked: this.props.checked,
      validate: this.state.validate,
    }
    this.setState(state)
    if (this.props.validate) state.validate = true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }

    if (prevProps !== this.props) {
      this.setState({
        valid: this.radioRef.current.validity.valid,
      })
    }
  }

  onCheck = () => {
    const state = { valid: this.radioRef.current.validity.valid, checked: true }
    if (this.props.autoValidate) state.validate = true
    this.setState(state)
    if (this.props.onCheck) this.props.onCheck(this.props.value)
  }

  render() {
    const { disabled, id, value, required } = this.props
    const { valid, validate, checked } = this.state
    let modifiers = ''
    if (disabled) modifiers += ` ${styles._disabled}`
    if (validate) {
      modifiers += valid ? ` ${styles._valid}` : ` ${styles._invalid}`
    }

    return (
      <label className={`${styles.label} ${modifiers}`}>
        <span className={styles.control}>
          <input
            ref={this.radioRef}
            name={id}
            className={styles.input}
            checked={checked}
            type="radio"
            value={value}
            disabled={disabled}
            onChange={this.onCheck}
            required={required}
          />
          <span className={styles.chip}></span>
        </span>

        <span className={styles.text}>{this.props.children}</span>
      </label>
    )
  }
}

Radio.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.bool,
  autoValidate: PropTypes.bool,
}

Radio.defaultProps = {
  checked: false,
  disabled: false,
  required: false,
  validate: false,
}
