import React from 'react'
import styles from './ShippingCardHeader.module.scss'
import { ShippingStates } from 'services/shippings'
import { Button, IconAlert, Badge } from 'ui'
import { ShippingCardActions } from './ShippingCardActions'

export const ShippingCardHeader = props => {
  const { shipping, showOpenButton } = props
  const displayCreatedDate =
    shipping.createdAt === shipping.expectedFrom &&
    [
      ShippingStates.received.value,
      ShippingStates.accepted.value,
      ShippingStates.sent.value,
      ShippingStates.forthcoming.value,
    ].includes(shipping.status)

  const displayReceivedDate = [ShippingStates.delivered.value].includes(
    shipping.status
  )

  const displaySentDate = [
    ShippingStates.sent.value,
    ShippingStates.delivered.value,
  ].includes(shipping.status)

  const displayCancel =
    ShippingStates.canceled.value === shipping.status &&
    shipping.cancelReason?.length > 0

  const displayExpectedFrom =
    !displayCancel &&
    !displaySentDate &&
    shipping.createdAt !== shipping.expectedFrom

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.name}>
          {shipping.firstname} {shipping.lastname}
        </div>
        {displayCancel && (
          <div className={styles.cancel}>{shipping.cancelReason}</div>
        )}
        <div className={styles.date}>
          {displayCreatedDate && <span>Commandé le {shipping.createdAt}</span>}
          {displayExpectedFrom && (
            <span>Attendu le {shipping.expectedFrom}</span>
          )}

          {displayReceivedDate && shipping.markedReceived === true && (
            <span>Marqué reçu le {shipping.receivedAt}</span>
          )}
          {displayReceivedDate && !shipping.markedReceived && (
            <span>Reçu le {shipping.receivedAt}</span>
          )}

          {displaySentDate && shipping.sentAt && (
            <span>, expédié le {shipping.sentAt}</span>
          )}
        </div>

        {shipping.isLate && (
          <div className={styles.alert}>
            <Badge
              error
              small
              label={'En retard'}
              icon={<IconAlert stroke={'white'} width={14} height={14} />}
            />
          </div>
        )}
      </div>
      {showOpenButton && (
        <div className={styles.show}>
          <Button small onClick={props.onShowClick}>
            {props.opened ? 'Retour' : 'Infos'}
          </Button>
        </div>
      )}
      <div className={styles.actions}>
        {
          <ShippingCardActions
            {...props}
            onTrackingAddClick={props.onTrackingAddClick}
          />
        }
      </div>
    </div>
  )
}
