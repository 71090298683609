import React from 'react'
import styles from './SepaForm.module.scss'
import { FileUpload } from 'ui'
import { MIME_CSV } from 'utils/file'

export function SepaForm(props) {
  function onChange(value) {
    props.onExport({ ...value })
  }

  return (
    <form className={styles.form}>
      <FileUpload
        onChange={value => onChange(value)}
        accept={[MIME_CSV]}
        primary
      >
        Générer un fichier de paiement SEPA
      </FileUpload>
    </form>
  )
}
