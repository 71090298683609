import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink, useLocation } from 'react-router-dom'
import styles from './MenuPrimary.module.scss'
import {
  IconMenuLeft,
  Logo,
  Modal,
  Subtitle,
  Badge,
  useEnv as useEnvDI,
} from 'ui'

const Nav = props => (
  <nav className={styles.nav}>
    <ul className={styles.list}>
      {props.links.map((link, index) => (
        <li key={index} className={styles.item}>
          <NavLink
            to={link.to}
            className={({ isActive }) =>
              `${styles.link} ${isActive ? styles._active : ''}`
            }
          >
            {link.label}
            {link.isNew && (
              <span className={styles.new}>
                <Badge small error label={'New!'} />
              </span>
            )}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
)

const LogoBlock = ({ url }) => (
  <div className={styles.logo}>
    {!url && <Logo />}
    {url && (
      <Link to={url}>
        <Logo />
      </Link>
    )}
  </div>
)

export const MenuPrimary = ({ useEnv = useEnvDI, ...props }) => {
  const [opened, setOpened] = useState(false)
  const env = useEnv()
  useLocation()

  let modifiers = styles[`_${env}`]

  return (
    <div>
      <div className={`${styles.mobile} ${modifiers}`}>
        <div className={styles.mobileHeader}>
          {props.links.length > 0 && (
            <button
              className={styles.mobileHeaderButton}
              onClick={() => setOpened(!opened)}
            >
              <IconMenuLeft
                id={'Menu'}
                label={'Accéder au menu général'}
                width={24}
                height={24}
              />
            </button>
          )}

          {props.links.length === 0 && (
            <div className={styles.mobileHeaderLogo}>
              <LogoBlock url={props.logoUrl} />
            </div>
          )}

          {props.title && (
            <div className={styles.mobileHeaderTitle}>
              <Subtitle level={1}>{props.title}</Subtitle>
            </div>
          )}
        </div>
        <div className={styles.mobileInfos}>{props.infos && props.infos}</div>
      </div>
      <div className={`${styles.desktop} ${modifiers}`}>
        <LogoBlock url={props.logoUrl} />
        <Nav links={props.links} />
        {props.infos && props.infos}
      </div>
      <div className={styles.modal}>
        <Modal
          opened={opened}
          closable={true}
          closeToLeft={true}
          onClose={() => setOpened(!opened)}
          title={
            <div style={{ height: '36px' }}>
              <Logo />
            </div>
          }
        >
          <Nav links={props.links} />
        </Modal>
      </div>
    </div>
  )
}

MenuPrimary.propTypes = {
  infos: PropTypes.element,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      isNew: PropTypes.bool,
    })
  ),
  logoUrl: PropTypes.string,
  title: PropTypes.string,
}

MenuPrimary.defaultProps = {
  infos: null,
  links: [],
  logoUrl: null,
  title: null,
}
