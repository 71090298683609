import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import Box from './BoxesPage/Box.js'
import Pagination from 'components/Pagination'
import Search from './BoxesPage/Search.js'
import Filters from './BoxesPage/Filters'
import store from 'stores/Admin/BoxesStore'
import UrlParser from 'urlParser'
import './BoxesPage.scss'

export default class BoxesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  filtersFromParams() {
    let url = new UrlParser()
    let params = url.getParams()

    let currentPage = parseInt(params.page, 10) || 1
    let search = {
      field: params.field || 'all',
      query: params.query || '',
    }

    let redeemed = params.redeemed
    redeemed = redeemed ? parseInt(redeemed, 10) : 99
    let deliveryMethod = params.deliveryMethod
    deliveryMethod = deliveryMethod ? parseInt(deliveryMethod, 10) : 99
    let giftNumber = params.giftNumber
    giftNumber = giftNumber ? parseInt(giftNumber, 10) : 99
    let product = params.product
    product = product ? parseInt(product, 10) : 99

    return [currentPage, search, redeemed, deliveryMethod, giftNumber, product]
  }

  componentDidMount() {
    store.subscribe(this.setState.bind(this))
    let [currentPage, search, redeemed, deliveryMethod, giftNumber, product] =
      this.filtersFromParams()

    store.setState({
      currentPage,
      search,
      redeemed,
      deliveryMethod,
      giftNumber,
      product,
    })
    store.load()
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  pageChanged(page) {
    let url = new UrlParser()
    let params = url.getParams()
    params.page = page
    url.setParams(params)
    window.location.href = url.toString()
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  boxes() {
    return this.state.boxes.map(b => <Box key={b.id} {...b} />)
  }

  totalResults() {
    if (this.state.totalResults > 0) {
      if (this.state.totalResults > 1) {
        return (
          <span className="total-results">
            {this.state.totalResults} resultats.
          </span>
        )
      } else {
        return <span className="total-results">1 resultat.</span>
      }
    } else {
      return <span className="total-results">Pas de résultats.</span>
    }
  }

  pagination() {
    if (this.state.pageCount > 1) {
      return (
        <Pagination
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          onChange={page => this.pageChanged(page)}
        />
      )
    }
  }

  render() {
    return (
      <div className="page admin-page" id="admin-boxes-page">
        <Helmet>
          <title>Boxes - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />

        <div className="group">
          <h1 className="h1">Liste des commandes</h1>

          <div id="boxes" className="feature">
            <Search {...this.state.search} />
            <Filters {...this.state} />
            {this.pagination()}
            <p className="header">{this.totalResults()}</p>
            {this.boxes()}
            {this.pagination()}
          </div>
        </div>
      </div>
    )
  }
}
