import React from 'react'
import { Link } from 'ui'
import styles from './OrderB2CCardBoxes.module.scss'

export const OrderB2CCardBoxes = ({ order }) => {
  const boxes = order.boxes

  return (
    <div className={styles.boxes}>
      <div className={styles.title}>Coffrets achetés</div>

      {boxes !== null &&
        boxes.map((box, i) => (
          <div className={styles.item} key={i}>
            <div className={styles.itemID}>{box.id}</div>
            <div className={styles.itemCategory}>{box.category}</div>
            <div className={styles.itemLabel}>
              {box.link ? <Link to={box.link}>{box.label}</Link> : box.label}
            </div>
          </div>
        ))}
    </div>
  )
}
