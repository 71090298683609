import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import store from 'stores/Admin/EditBoxPageStore.js'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    window.scrollTo(0, 0)
    store.subscribe(this.updated)
    store.load(parseInt(this.props.params.id))
  }

  componentWillUnmount() {
    store.unsubscribe(this.updated)
    store.reset()
  }

  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page" id="EditBoxPage">
        <Helmet>
          <title>Boxes - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />

        <div className="group">
          <h1 className="h1">Modifier les informations client</h1>

          <p>
            <b>Important</b>: notez qu'à chaque modification des coordonnées, un
            mail sera envoyé aux fournisseurs de cette commande qui n'ont pas
            encore confirmé leur date d'expédition. Un mail sera également
            envoyé au client pour l'informer de ces changements.
          </p>

          <form
            onSubmit={e => {
              e.preventDefault()
              store.submit()
            }}
          >
            <div className="input-group">
              <label>
                Prénom
                <input
                  type="text"
                  className="text-field"
                  value={this.state.firstName}
                  onChange={e => store.firstNameChanged(e.target.value)}
                  required={true}
                />
              </label>

              <label>
                Nom
                <input
                  type="text"
                  className="text-field"
                  value={this.state.lastName}
                  onChange={e => store.lastNameChanged(e.target.value)}
                  required={true}
                />
              </label>
            </div>

            <div className="input-group">
              <label>
                Téléphone
                <input
                  type="text"
                  className="text-field"
                  value={this.state.phone}
                  onChange={e => store.phoneChanged(e.target.value)}
                  required={true}
                />
              </label>

              <label>
                Email
                <input
                  type="text"
                  className="text-field"
                  value={this.state.email}
                  onChange={e => store.emailChanged(e.target.value)}
                  required={true}
                />
              </label>
            </div>

            <div className="input-group">
              <label>
                Adresse
                <input
                  type="text"
                  className="text-field"
                  value={this.state.address}
                  onChange={e => store.addressChanged(e.target.value)}
                  required={true}
                />
              </label>

              <label>
                Code Postal
                <input
                  type="text"
                  className="text-field"
                  value={this.state.zipcode}
                  onChange={e => store.zipcodeChanged(e.target.value)}
                  required={true}
                />
              </label>

              <label>
                Ville
                <input
                  type="text"
                  className="text-field"
                  value={this.state.city}
                  onChange={e => store.cityChanged(e.target.value)}
                  required={true}
                />
              </label>
            </div>

            <p>
              <button className="btn">Modifier</button>
            </p>
          </form>
        </div>
      </div>
    )
  }
}

const EditBoxPage = withRouter(Page)
export default EditBoxPage
