import React from 'react'
import PropTypes from 'prop-types'
import styles from './Removable.module.scss'

export class Removable extends React.Component {
  constructor(props) {
    super(props)
    this.elementRef = React.createRef()
    this.state = {
      removed: props.removed,
      removing: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.removed &&
      !prevProps.removed &&
      !this.state.removed &&
      !this.state.removing
    ) {
      this.elementRef.current.style.height = `${this.elementRef.current.offsetHeight}px`
      this.setState({ removing: true })
    } else if (
      !this.props.removed &&
      prevProps.removed &&
      this.state.removed &&
      !this.state.removing
    ) {
      this.elementRef.current.style.height = 'auto'
      this.setState({ removed: false })
    }
  }

  onAnimationEnd = () => {
    this.setState({ removing: false, removed: true })
    if (this.props.onRemoved) this.props.onRemoved()
  }

  render() {
    const { children } = this.props
    const modifiers = `${this.state.removing ? styles._removing : ''} ${
      this.state.removed ? styles._removed : ''
    }`

    return (
      <>
        {!this.state.removed && (
          <div
            ref={this.elementRef}
            className={`${styles.container} ${modifiers}`}
            onAnimationEnd={this.onAnimationEnd}
          >
            {children}
          </div>
        )}
      </>
    )
  }
}

Removable.propTypes = {
  onRemoved: PropTypes.func,
  removed: PropTypes.bool,
}

Removable.defaultProps = {
  removed: false,
}
