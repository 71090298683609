import React from 'react'
import { Navigate } from 'react-router'
import { NotificationManager } from 'services/notifications'
import { acceptIntro } from 'services/suppliers'
import { LayoutFixed } from 'ui'
import { Intro } from './components'
import { waitForApi } from 'utils/api'
import { withRouter } from 'hocs/withRouter'

class SupplierIntro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accepted: false,
    }
  }

  onValid = () => {
    waitForApi(acceptIntro(this.props.params.uniqueKey))
      .then(this.onValidSuccess)
      .catch(this.onError)
  }

  onValidSuccess = () => {
    this.setState({ accepted: true })
  }

  onError = () => {
    NotificationManager.addError('Une erreur est survenue')
  }

  render() {
    return this.state.accepted === false ? (
      <LayoutFixed title="Intro">
        <Intro onValid={this.onValid} uniqueKey={this.props.params.uniqueKey} />
      </LayoutFixed>
    ) : (
      <Navigate
        to={`/suppliers/${this.props.params.uniqueKey}/config/expedition`}
      />
    )
  }
}

export const FeatureSupplierIntro = withRouter(SupplierIntro)
