import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BBoxesSending = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes physiques en cours d'expédition"
    deliveryMethod={1}
    sending={true}
  />
)
