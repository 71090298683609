import React from 'react'
import { LayoutFixed } from 'ui'
import { PackagersMenu } from '../../components'
import { PrepaFacileForm, PrepaFacileResults } from '../components'
import { importPrepaFacile } from 'services/packagers'
import { waitForApi } from 'utils/api'

const ERROR_INTERNAL =
  'Une erreur interne est survenue, veuillez recharger la page pour obtenir des données à jour'

export class FeaturePackagersTrackingPrepaFacile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      assigned: null,
      exceptions: [],
    }
  }

  onDataSuccess = data => {
    this.setState({
      error: null,
      loading: false,
      exceptions: data.exceptions,
      assigned: data.result?.nbAssignedItems ?? 0,
    })
  }

  onSubmitError = () => {
    this.setState({
      error: ERROR_INTERNAL,
      loading: false,
    })
  }

  onFormSubmit = file => {
    this.setState({ loading: true })

    return waitForApi(importPrepaFacile(file))
      .then(this.onDataSuccess)
      .catch(this.onSubmitError)
  }

  isFileSelected = () => {
    return this.state.assigned !== null
  }

  render() {
    const { assigned, exceptions, error, loading } = this.state
    return (
      <LayoutFixed
        nav={<PackagersMenu title={"Suivi PrépaFacile"} />}
        loading={loading}
        error={error}
        title="Suivi PrépaFacile"
      >
        <PrepaFacileForm
          onSubmit={this.onFormSubmit}
          isFileSelected={this.isFileSelected()}
        />
        <PrepaFacileResults assigned={assigned} exceptions={exceptions} />
      </LayoutFixed>
    )
  }
}
