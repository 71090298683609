import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import styles from './LayoutFullscreen.module.scss'
import { Loader } from 'ui'
import { withFade } from 'ui/hoc'

const FadeContent = withFade(props => props.children)

export const LayoutFullscreen = props => (
  <div className={styles.layout}>
    <Helmet titleTemplate="%s - Ici Présent!" defaultTitle="Ici Présent!">
      <title>{props.title}</title>
    </Helmet>

    {props.menu && <nav className={styles.menu}>{props.menu}</nav>}

    <div className={styles.wrapper}>
      {props.loading && (
        <FadeContent className={styles.loader}>
          <Loader />
        </FadeContent>
      )}

      {!props.loading && (
        <FadeContent className={styles.content}>{props.children}</FadeContent>
      )}
    </div>
  </div>
)

LayoutFullscreen.displayName = 'LayoutFullscreen'

LayoutFullscreen.propTypes = {
  loading: PropTypes.bool,
  menu: PropTypes.element,
  title: PropTypes.string.isRequired,
}

LayoutFullscreen.defaultProps = {
  loading: false,
  menu: null,
}
