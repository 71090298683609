import React from 'react'
import PropTypes from 'prop-types'
import config from 'config.js'
import { Annotation, Button, ButtonLink, Card, Logo, Subheading } from 'ui'

import styles from './CustomerBoxCard.module.scss'

export const CustomerBoxCard = ({ box, shopifyId, onSend }) => {
  const downloadUrl = `${config.downloadUrl}/customers/ebox/download?id=${shopifyId}&rid=${box.redeemingId}&code=${box.redeemingCode}`

  return (
    <Card padding={false} light>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.picture}>
            {box.image && (
              <img
                className={styles.pictureSource}
                src={box.image}
                alt={box.name}
              />
            )}
            {!box.image && (
              <div className={styles.pictureDefault}>
                <Logo />
              </div>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.subheading}>
              <Subheading>{box.name}</Subheading>
            </div>
            <Annotation>
              {box.nbGifts} {box.nbGifts > 1 ? 'Coffrets' : 'Coffret'}
            </Annotation>
          </div>
        </div>
        <div className={styles.actions}>
          <Button onClick={onSend}>Envoyer par email</Button>
          <ButtonLink external primary to={downloadUrl}>
            Télécharger
          </ButtonLink>
        </div>
      </div>
    </Card>
  )
}

CustomerBoxCard.propTypes = {
  box: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nbGifts: PropTypes.number.isRequired,
    redeemingId: PropTypes.string.isRequired,
    redeemingCode: PropTypes.string.isRequired,
  }),
  shopifyId: PropTypes.number,
  onSend: PropTypes.func,
}
