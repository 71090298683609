import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

export const WidgetReviews = ({ supplierKey }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://widget.icipresent.com/reviews.js'
    script.async = true
    script.crossOrigin = 'anonymous'
    document.body.appendChild(script)

    return () => {
      try {
        document.body.removeChild(script)
      } catch {} // silent error
    }
  }, [])

  return (
    <div>
      <icipresent-widget id={supplierKey}></icipresent-widget>
    </div>
  )
}

WidgetReviews.propTypes = {
  supplierKey: PropTypes.string.isRequired,
}

WidgetReviews.defaultProps = {}
