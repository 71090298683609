import React from 'react'
import { IconHome, IconPause, IconTruck } from 'ui'

export const getMenuLinks = key => [
  {
    to: `/suppliers/${key}/config/company`,
    icon: <IconHome />,
    label: 'Entreprise',
  },
  {
    to: `/suppliers/${key}/config/expedition`,
    icon: <IconTruck />,
    label: 'Expéditions',
  },
  {
    to: `/suppliers/${key}/config/off`,
    icon: <IconPause />,
    label: 'Mise en pause',
  },
]
