import React from 'react'
import { IconCart, IconCompany } from 'ui'

export const MENU_LINKS = [
  {
    to: `/a/orders/b2c`,
    icon: <IconCart />,
    label: `E-Commerce`,
    primary: true,
  },
  { divider: true },
  {
    to: `/a/orders/b2b/all`,
    icon: <IconCompany />,
    label: `Entreprise`,
    primary: true,
  },
  {
    to: `/a/orders/b2b/eboxes/all`,
    label: 'E-Coffrets',
    primary: false,
  },
  {
    to: `/a/orders/b2b/eboxes/inactives`,
    label: 'Inactives',
    light: true,
  },
  {
    to: `/a/orders/b2b/eboxes/actives`,
    label: 'Actives',
    light: true,
  },
  {
    to: `/a/orders/b2b/eboxes/incoming`,
    label: 'À venir',
    light: true,
  },
  {
    to: `/a/orders/b2b/eboxes/closed`,
    label: 'Clôturées',
    light: true,
  },
  {
    to: `/a/orders/b2b/boxes/all`,
    label: `Coffrets Physiques`,
    primary: false,
  },
  {
    to: `/a/orders/b2b/boxes/inactives`,
    label: `Inactives`,
    light: true,
  },
  {
    to: `/a/orders/b2b/boxes/notsent`,
    label: `Non-expédiées`,
    light: true,
  },
  {
    to: `/a/orders/b2b/boxes/sending`,
    label: `En cours d'expédition`,
    light: true,
  },
  {
    to: `/a/orders/b2b/boxes/sent`,
    label: `Expédiées`,
    light: true,
  },
  {
    to: `/a/orders/b2b/boxes/closed`,
    label: `Clôturées`,
    light: true,
  },
]

export const DELIVERY_METHODS = [
  {
    label: 'E-Coffret',
    value: 0,
  },
  {
    label: 'Coffret Physique',
    value: 1,
  },
]

export const MESSAGE_TYPES = [
  {
    label: 'Aucun',
    value: 0,
  },
  {
    label: 'Standard',
    value: 1,
  },
  {
    label: 'Personnalisé',
    value: 2,
  },
]

export const ADDRESS_TYPES = [
  {
    label: 'Adresse unique',
    value: 1,
  },
  {
    label: 'Adresses individuelles',
    value: 2,
  },
]
