import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import ProductForm from './ProductForm'
import store from 'stores/Admin/NewProductStore'

export default class NewProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
    this._setState = this.setState.bind(this)
  }

  componentDidMount() {
    store.subscribe(this._setState)
  }

  componentWillUnmount() {
    store.unsubscribe(this._setState)
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          name_required: 'Merci de fournir un nom pour ce coffret.',
          image_url_required:
            "Merci de fournir une url d'image pour ce coffret.",
          catalog_url_required:
            'Merci de fournir une url du catalogue pour ce coffret.',
          name_taken: 'Ce coffret existe déjà.',
          ean13_not_num: 'Le code EAN13 doit être entièrement numérique.',
          ean13_not_13: 'Le code EAN13 doit contenir 13 caractères.',
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page admin-page" id="admin-product-new-page">
        <Helmet>
          <title>Coffrets-Cadeaux - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />
        <div className="group">
          <h1 className="h1">Nouveau Coffret-Cadeau</h1>

          <div id="new-product" className="feature">
            <ProductForm {...this.state} store={store} />
          </div>
        </div>
      </div>
    )
  }
}
