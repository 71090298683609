import React, { useEffect, useRef, useState } from 'react'
import {
  Annotation,
  Button,
  Card,
  Divider,
  LayoutFixed,
  MenuSecondary,
  TextField,
  Title,
} from 'ui'
import { waitForApi } from 'utils/api'
import { boxRenew } from 'services/boxes'
import { NotificationManager } from 'services/notifications'

import styles from './Prolongation.module.scss'
import { MENU_LINKS } from '../@commons'
import { AdminNavigation } from '../../components'

const BOX_RENEWED = 'Le coffret a été prolongé avec succès !'
const BOX_RENEWAL_ERROR = 'Une erreur est survenue lors de la prolongation'

export const FeatureAdminSupportProlongation = () => {
  const mounted = useRef(false)
  const prolongationForm = useRef(null)
  const [id, setId] = useState(null)
  const [code, setCode] = useState(null)
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    mounted.current = true
    if (!prolongationForm.current) return
    setValid(prolongationForm.current.checkValidity())

    return () => {
      mounted.current = false
    }
  }, [id, code])

  const onRenew = e => {
    e.preventDefault()
    e.stopPropagation()

    setLoading(true)

    return waitForApi(boxRenew({ id, code }))
      .then(onRenewSuccess)
      .catch(onError)
  }

  const onRenewSuccess = () => {
    NotificationManager.addSuccess(BOX_RENEWED)
    setLoading(false)
    setId('')
    setCode('')
  }

  const onError = () => {
    setLoading(false)
    NotificationManager.addError(BOX_RENEWAL_ERROR)
  }

  return (
    <LayoutFixed
      nav={<AdminNavigation title={'Support'} />}
      menu={<MenuSecondary links={MENU_LINKS} />}
      loading={loading}
      title="Prolongation de coffret"
    >
      <div id={'prolongation'}>
        <Title>Prolongation de coffret</Title>

        <Divider />

        <Card light>
          <Annotation>
            Veuillez saisir les codes d'accès du coffret pour l'activer pendant
            48h.
          </Annotation>

          <Divider small />

          <form ref={prolongationForm} name={'BoxForm'} className={styles.form}>
            <div className={styles.input}>
              <TextField
                id={'BoxID'}
                label={'Numéro de coffret (6 chiffres)'}
                type={'text'}
                required
                minLength={6}
                maxLength={6}
                initialValue={id}
                onChange={value => setId(value)}
              />
            </div>
            <div className={styles.input}>
              <TextField
                id={'BoxCode'}
                label={'Code pass (4 chiffres)'}
                type={'text'}
                required
                minLength={4}
                maxLength={4}
                initialValue={code}
                onChange={value => setCode(value)}
              />
            </div>
            <div className={styles.valid}>
              <Button primary disabled={!valid} onClick={onRenew}>
                Valider
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </LayoutFixed>
  )
}
