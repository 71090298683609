import React from 'react'
import PropTypes from 'prop-types'
import styles from './Content.module.scss'
import { ContentSummary, ContentInvoice } from '.'

export const Content = props => {
  const {
    invoices,
    summary,
    activeInvoiceType,
    hasAutoInvoicing,
    showEmptyMessage,
  } = props

  let modifiers = ' '
  if (activeInvoiceType) modifiers += styles[`_${activeInvoiceType.name}`]
  else modifiers += ` ${styles._summary}`

  return (
    <div className={`${styles.container}${modifiers}`}>
      <ContentSummary
        summary={summary}
        hasAutoInvoicing={hasAutoInvoicing}
        showEmptyMessage={showEmptyMessage}
      />

      {hasAutoInvoicing &&
        invoices?.map(invoice => (
          <ContentInvoice
            invoice={invoice}
            key={invoice.invoiceType.name}
            hasAutoInvoicing={hasAutoInvoicing}
          />
        ))}
    </div>
  )
}

Content.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  summary: PropTypes.object,
  activeInvoiceType: PropTypes.object,
  hasAutoInvoicing: PropTypes.bool,
  showEmptyMessage: PropTypes.bool,
}
