import React from 'react'
import PropTypes from 'prop-types'
import styles from './Error.module.scss'
import { Button } from 'ui'
import { withFade } from 'ui/hoc'

export const Error = withFade(props => {
  return (
    <div className={styles.error}>
      <div className={styles.title}>{props.title}</div>
      {props.message && <div className={styles.message}>{props.message}</div>}
      <Button primary onClick={() => window.location.reload()}>
        Recharger la page
      </Button>
    </div>
  )
})

Error.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

Error.defaultProps = {
  title: 'Oups ! Une erreur est survenue',
  message: null,
}
