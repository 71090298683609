import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Annotation, Button, Divider, Grid, LayoutFixed, Title } from 'ui'
import { NotificationManager } from 'services/notifications'
import {
  Cart,
  Header,
  ProductModal,
  ProductThumbnail,
  CurrentFilters,
  FiltersMenu,
  FiltersModal,
} from '../components'
import {
  getFirst,
  getNext,
  getPrevious,
  setCurrentProduct,
} from 'features/customers/redeem/slice'
import useDidMountEffect from 'hooks/useDidMountEffect'

import styles from './Selection.module.scss'

const INTERNAL_ERROR = 'Une erreur est survenue'
const REDEEM_SAVED = 'Votre commande a été enregistrée'

export const Selection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const box = useSelector(state => state.redeem.box.item)
  const products = useSelector(state => state.redeem.products)
  const currentFilters = useSelector(state => state.redeem.currentFilters)
  const save = useSelector(state => state.redeem.save)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const params = { box: box?.tag }

  useEffect(() => {
    if (!box) navigate('/redeem')
    else dispatch(getFirst(params))
  }, []) // eslint-disable-line

  useDidMountEffect(() => {
    if (!save.error) return
    NotificationManager.addError(INTERNAL_ERROR)
  }, [save.error])

  useDidMountEffect(() => {
    if (save.exceptions?.length === 0) return
    save.exceptions.map(ex => NotificationManager.addError(ex))
  }, [save.exceptions])

  useDidMountEffect(() => {
    products.error && NotificationManager.addError(INTERNAL_ERROR)
  }, [products.error])

  useDidMountEffect(() => {
    dispatch(getFirst(params))
  }, [currentFilters])

  useDidMountEffect(() => {
    if (!save.url) return

    NotificationManager.addSuccess(REDEEM_SAVED)
    navigate('/redeem/thanks')
  }, [save.url]) // eslint-disable-line

  const onProductClick = product => {
    if (!product) return
    dispatch(setCurrentProduct({ product }))
  }

  const getItems = () => {
    const list =
      products.loading || products.list.length === 0
        ? Array(products.limit).fill()
        : products.list

    return list.map(p => (
      <ProductThumbnail product={p} onClick={onProductClick} />
    ))
  }

  const isError = products.error
  const isEmpty =
    !products.loading && !products.error && products.list.length === 0

  const nav = !box?.redeemed ? <Header stepper gifts={box?.gifts} /> : <></>

  return (
    <LayoutFixed
      title={'Sélectionnez votre cadeau'}
      nav={nav}
      fullWidth={true}
      opposite={true}
      footer={<Cart />}
      offscreen={
        <div>
          <ProductModal />
          <FiltersModal
            opened={filtersOpen}
            onClose={() => setFiltersOpen(false)}
          />
        </div>
      }
      menu={<FiltersMenu onFilter={() => setFiltersOpen(true)} />}
    >
      {box && (
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.piccontainer}>
              <img className={styles.pic} src={box.image} alt={box.name} />
            </div>
            <div className={styles.headlines}>
              <div className={styles.subtitle}>
                <Title level={1}>Coffret {box.name}</Title>
              </div>
              <Divider small />
              {box.redeemed && (
                <Annotation className={styles.subtitle}>
                  Complétez votre sélection en sélectionnant{' '}
                  {box.remainingGifts} cadeau
                  {box.remainingGifts > 1 ? 'x' : ''} parmi notre sélection de
                  produits artisanaux.
                </Annotation>
              )}
              {!box.redeemed && (
                <Annotation className={styles.subtitle}>
                  Sélectionnez {box.remainingGifts} cadeau
                  {box.remainingGifts > 1 ? 'x' : ''} parmi notre sélection de
                  produits artisanaux.
                </Annotation>
              )}
            </div>
          </div>
          <div className={styles.filters}>
            <CurrentFilters />
          </div>
          <div className={styles.content}>
            <div className={styles.inner}>
              {isEmpty && (
                <div className={styles.empty}>
                  <div className={styles.emptyTitle}>
                    Oh oh... Aucun résultat trouvé pour cette recherche !
                  </div>
                </div>
              )}

              {isError && (
                <div className={styles.error}>
                  <div className={styles.errorTitle}>
                    Oh oh... Il semblerait qu'il y ai eu un problème !
                  </div>
                  <div className={styles.errorSubtitle}>
                    Veuillez contacter le support pour trouver une solution à
                    votre problème.
                  </div>
                </div>
              )}

              <div className={styles.list}>
                <Grid cols={2} s={4} xl={6} items={getItems()} />
              </div>
            </div>
            {(products.pagination?.hasPreviousPage ||
              products.pagination?.hasNextPage) && (
              <div className={styles.footer}>
                <div className={styles.cta}>
                  <Button
                    disabled={!products.pagination?.hasPreviousPage}
                    primary
                    onClick={() => dispatch(getPrevious(params))}
                  >
                    Page précédente
                  </Button>
                </div>
                <div className={styles.cta}>
                  <Button
                    disabled={!products.pagination?.hasNextPage}
                    primary
                    onClick={() => dispatch(getNext(params))}
                  >
                    Page suivante
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </LayoutFixed>
  )
}
