import React from 'react'
import styles from './OrderB2BCardInfos.module.scss'

export class OrderB2BCardInfos extends React.Component {
  render() {
    const { order, hideType } = this.props

    return (
      <div className={styles.infos}>
        {this.renderInfosData("Début d'activation", order.activatedAt || '/')}
        {this.renderInfosData("Fin d'activation", order.activatedTo || '/')}
        {this.renderInfosData('Coffret', order.product)}
        {this.renderInfosData('Nombre coffrets', order.giftBoxNumber)}
        {!hideType && this.renderInfosData('Type', order.deliveryMethodLabel)}
        {hideType && this.renderEmpty()}
        {this.renderInfosData('Nombre cadeaux', order.giftNumber)}
      </div>
    )
  }

  renderInfosData(label, value) {
    let modifiers = ''
    if (label === null && value === null) {
      modifiers += ` ${styles._empty}`
    }

    return (
      <div className={`${styles.item}${modifiers}`}>
        <div className={styles.itemLabel}>{label}</div>
        <div className={styles.itemValue}>{value}</div>
      </div>
    )
  }

  renderEmpty() {
    return this.renderInfosData(null, null)
  }
}
