import React from 'react'
import { FeatureAdminSuppliersList } from './List'

export const FeatureAdminSuppliersActive = props => (
  <FeatureAdminSuppliersList
    {...props}
    active={true}
    deleted={false}
    off={false}
  />
)
