import React from 'react'
import styles from './ReviewCardActions.module.scss'
import { Button, ButtonLink } from 'ui'

export const ReviewCardActions = props => (
  <div className={styles.ctas}>
    <div className={styles.cta}>
      <ButtonLink
        to={`/status/${props.review.shipping.boxId}/${props.review.shipping.redeemingId}/${props.review.shipping.redeemingCode}`}
      >
        Consulter
      </ButtonLink>
    </div>
    {props.review.active && (
      <div className={styles.cta}>
        <Button primary onClick={() => props.onDeactivate(props.review.id)}>
          Désactiver
        </Button>
      </div>
    )}
    {!props.review.active && (
      <div className={styles.cta}>
        <Button primary onClick={() => props.onActivate(props.review.id)}>
          Activer
        </Button>
      </div>
    )}
  </div>
)
