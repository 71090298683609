import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class NewProductStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      name: '',
      tag: '',
      imageUrl: '',
      catalogUrl: '',
      ean13codes: ['', '', '', '', '', ''],
      price: '',
      shopifyProducts: [[], []],
    }
  }

  nameChanged(name) {
    this.setState({ name })
  }

  tagChanged(tag) {
    this.setState({ tag })
  }

  imageUrlChanged(imageUrl) {
    this.setState({ imageUrl })
  }

  catalogUrlChanged(catalogUrl) {
    this.setState({ catalogUrl })
  }

  ean13codesChanged(plan, ean13) {
    this.setState(state => {
      state.ean13codes[plan] = ean13
      return state
    })
  }

  priceChanged(price) {
    this.setState(state => {
      state.price = price
      return state
    })
  }

  shopifyProductChanged(deliveryMethod, plan, value) {
    this.setState(state => {
      state.shopifyProducts[deliveryMethod][plan] = value
      return state
    })
  }

  async submit() {
    let ean13codes = []
    for (let i = 0; i < this.state.ean13codes.length; i++) {
      let ean13 = this.state.ean13codes[i].trim()
      if (ean13 && !ean13.match(/^\d+$/)) {
        this.setState({ errors: ['ean13_not_num'] })
        return
      }

      if (ean13 && ean13.length != 13) {
        this.setState({ errors: ['ean13_not_13'] })
        return
      }

      ean13codes.push(ean13)
    }

    let params = {
      name: this.state.name.trim(),
      tag: this.state.tag.trim(),
      imageUrl: this.state.imageUrl.trim(),
      catalogUrl: this.state.catalogUrl.trim(),
      ean13codes,
      price: this.state.price * 100,
      shopifyProducts: this.mapProducts(),
    }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/products/create`, {
      method: 'POST',
      body,
    })

    let data = await resp.json()

    if (data.success) {
      alert('Le produit a bien été créé.')
      window.location.href = '/a/products'
    } else {
      this.setState({ errors: data.errors })
    }
  }

  mapProducts() {
    let mapped = [[], []]

    for (let i = 0; i < 6; i++) {
      mapped[0].push(parseInt(this.state.shopifyProducts[0][i], 10))
      mapped[1].push(parseInt(this.state.shopifyProducts[1][i], 10))
    }

    return mapped
  }
}

const store = new NewProductStore()
export default store
