import React from 'react'
import { Grid, LayoutFixed, MenuSecondary, OrderB2CCard } from 'ui'
import { AdminActions, AdminNavigation } from 'features/admin/components'
import { MENU_LINKS } from 'features/admin/orders/@commons'
import { getB2COrders } from 'services/orders'
import { getRouteParam, setRouteParam } from 'utils/route'
import { withRouter } from 'hocs/withRouter'

class B2CAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = { result: { orders: [] } }
  }

  loadData = () => {
    const page = parseInt(getRouteParam(this.props.location, 'page')) || 1
    const query = this.query()
    return getB2COrders({ page, query })
  }

  onFilter = filter => {
    let location = setRouteParam(this.props.location, 'query', filter)
    location = setRouteParam(location, 'page', 1)
    this.props.navigate(location)
  }

  query() {
    return getRouteParam(this.props.location, 'query') || ''
  }

  render() {
    const {
      result: { orders },
      pagination,
    } = this.state

    return (
      <LayoutFixed
        title="Commandes E-Commerce"
        nav={<AdminNavigation title={'Commandes'} />}
        menu={<MenuSecondary links={MENU_LINKS} />}
        loadData={this.loadData}
        onDataLoaded={data => this.setState({ ...data })}
        actions={
          <AdminActions
            total={pagination?.nbItems}
            onFilter={this.onFilter}
            query={this.query()}
          />
        }
      >
        <Grid
          cols={1}
          items={orders?.map(o => (
            <OrderB2CCard key={o.id} order={o} />
          ))}
        />
      </LayoutFixed>
    )
  }
}

export const FeatureAdminOrdersB2CAll = withRouter(B2CAll)
