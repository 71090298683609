import React from 'react'

/*
 * Properties
 *
 * * id [Number]
 * * name [String]
 *
 */
export default class Theme extends React.Component {
  open = e => {
    window.location.href = `/a/themes/${this.props.id}/edit`
  }

  render() {
    return (
      <div className="theme list-item">
        <img src={this.props.imageUrl} alt={this.props.name} />
        <h3 className="h3">{this.props.name}</h3>
        <p>
          <button className="btn" onClick={this.open}>
            Modifier
          </button>
        </p>
      </div>
    )
  }
}
