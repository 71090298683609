import api from 'utils/api'
import config from 'config'

export const getB2COrders = body => api.post('api/admin/orders/b2c/list', body)

export const getB2BOrders = body => api.post('api/admin/orders/b2b/list', body)

export const getB2BOrder = id =>
  api.post('api/admin/orders/b2b/get', {
    id: id,
  })

export const deleteOrder = id =>
  api.post('api/admin/orders/b2b/delete', {
    id: id,
  })

export const exportCodes = id =>
  api.post('api/admin/orders/b2b/exportcodes', {
    id: id,
  })

export const exportReviews = id =>
  api.post('api/admin/orders/b2b/exportreviews', {
    id: id,
  })

export const createB2BOrder = body =>
  api.post(`api/admin/orders/b2b/create`, body)

export const updateB2BOrder = body =>
  api.post(`api/admin/orders/b2b/edit`, body)

export const sendB2BOrder = id =>
  api.post(`api/admin/orders/b2b/send`, {
    id: id,
  })

export const activateB2BOrder = body =>
  api.post(`api/admin/orders/b2b/activate`, body)

export const closeB2BOrder = id =>
  api.post(`api/admin/orders/b2b/close`, {
    id: id,
  })

export const exportBoxes = id =>
  api.post('api/admin/orders/b2b/generate', {
    id: id,
  })

export const getAddressTemplateUrl = () =>
  `${config.apiUrl}/api/admin/orders/b2b/downloadtemplate`

export const getCustomMessageUrl = id =>
  `${config.apiUrl}/api/admin/orders/b2b/downloadcustommessage?id=${id}`
