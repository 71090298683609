import React from 'react'
import PropTypes from 'prop-types'
import styles from './ListActions.module.scss'

export const ListActions = props => {
  return (
    <div className={styles.actions}>
      {props.left.length > 0 && (
        <div className={styles.left}>
          {props.left.map((action, index) => (
            <div className={styles.action} key={index}>
              {action}
            </div>
          ))}
        </div>
      )}
      {props.right.length > 0 && (
        <div className={styles.right}>
          {props.right.map((action, index) => (
            <div className={styles.action} key={index}>
              {action}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

ListActions.propTypes = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
}

ListActions.defaultProps = {
  left: [],
  right: [],
}
