import React from 'react'
import store from 'stores/Admin/UsersStore.js'

/*
 * Properties:
 *
 * * email          [String]
 * * isAdmin        [Boolean]
 * * supplierName   [String]
 *
 */
export default class User extends React.Component {
  revoke() {
    if (window.confirm(`Vraiment supprimer ${this.props.email}?`)) {
      store.revoke(this.props.email)
    }
  }

  admin() {
    if (this.props.isAdmin) return ', admin.'
  }

  supplier() {
    if (this.props.supplierName) return `, ${this.props.supplierName}.`
  }

  render() {
    return (
      <div className="User list-item">
        <button className="btn danger" onClick={e => this.revoke()}>
          Révoquer
        </button>
        {this.props.email}
        {this.admin()}
        {this.supplier()}
      </div>
    )
  }
}
