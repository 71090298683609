import BaseStore from 'stores/BaseStore'
import config from 'config.js'
import { fetch } from 'utils/api'

class AccountPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      email: '',
      passwordConfirm: '',
      newPassword: '',
      oldPassword: '',
    }
  }

  async load() {
    let resp = await fetch(`${config.apiUrl}/api/account/retrieve`)
    let data = await resp.json()

    if (data.success) {
      this.setState({ email: data.email })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  emailChanged(email) {
    this.setState({ email })
  }

  passwordConfirmChanged(passwordConfirm) {
    this.setState({ passwordConfirm })
  }

  newPasswordChanged(newPassword) {
    this.setState({ newPassword })
  }

  oldPasswordChanged(oldPassword) {
    this.setState({ oldPassword })
  }

  async updateEmail() {
    let params = {
      email: this.state.email,
      password: this.state.passwordConfirm,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/account/email/update`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      alert('Compte mis à jour')
      window.location.reload()
    } else {
      this.setState({ errors: data.errors })
    }
  }

  async updatePassword() {
    let params = {
      newPassword: this.state.newPassword,
      oldPassword: this.state.oldPassword,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/account/password/update`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      alert('Compte mis à jour')
      window.location.reload()
    } else {
      this.setState({ errors: data.errors })
    }
  }

  logout() {
    return fetch(`${config.apiUrl}/api/account/logout`)
  }
}

const store = new AccountPageStore()
export default store
