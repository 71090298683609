import React from 'react'
import PropTypes from 'prop-types'
import styles from './AdminActions.module.scss'
import { IconFilter, TextFieldButton } from 'ui'
import { withFade } from 'ui/hoc'

const Total = withFade(props => (
  <div>
    {props.value === 0 && <span>Aucun résultat</span>}
    {props.value === 1 && <span>1 résultat</span>}
    {props.value > 1 && <span>{props.value} résultats</span>}
  </div>
))

export const AdminActions = props => (
  <div className={styles.wrapper}>
    <div className={styles.results}>
      {props.total !== null && <Total value={props.total} />}
    </div>
    <div className={styles.actions}>
      <div className={`${styles.action} ${styles.filter}`}>
        <TextFieldButton
          id={'AdminActionFilter'}
          resetable
          light
          icon={<IconFilter />}
          label={'Recherche...'}
          onSubmit={props.onFilter}
          initialValue={props.query}
          title={'Filtrer'}
        />
      </div>
      {props.actions.map((a, i) => (
        <div key={i} className={styles.action}>
          {a}
        </div>
      ))}
    </div>
  </div>
)

AdminActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  query: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  total: PropTypes.number,
}

AdminActions.defaultProps = {
  actions: [],
  query: '',
  total: null,
}
