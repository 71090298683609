import React from 'react'
import PropTypes from 'prop-types'
import styles from './NavigationInfos.module.scss'
import { withFade } from 'ui/hoc'

export const NavigationInfos = withFade(props => (
  <div className={styles.infos}>
    <div className={styles.infosName}>{props.supplierName}</div>
    <div className={styles.infosDate}>{new Date().toLocaleDateString()}</div>
  </div>
))

NavigationInfos.propTypes = {
  supplierName: PropTypes.string.isRequired,
}
