import { Badge } from 'ui'

export const getOrderB2BBadgeProps = status => {
  if (status === 'active') return { primary: true, label: 'Active' }
  if (status === 'inactive') return { warning: true, label: 'Inactive' }
  if (status === 'incoming') return { info: true, label: 'À venir' }
  return { label: 'Clôturée' }
}

export const OrderB2BStatus = ({ status }) => (
  <Badge small {...getOrderB2BBadgeProps(status)} />
)
