import React from 'react'
import PropTypes from 'prop-types'
import styles from './RadioList.module.scss'
import { Radio } from 'ui'

export class RadioList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: null, validate: false }
  }

  componentDidMount() {
    this.setState({ value: this.props.value })
    if (this.props.validate) this.setState({ validate: true })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.onRadioChange(this.props.value)
    }

    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }
  }

  onRadioChange = value => {
    const { validate } = this.state
    const state = { value, validate }

    if (this.props.autoValidate && !validate) state.validate = true
    this.setState(state)
    if (this.props.onChange) this.props.onChange(value)
  }

  render() {
    const { disabled, id, options, inline, required } = this.props
    const { validate } = this.state
    let modifiers = ''
    if (inline) modifiers += ` ${styles.inline}`

    return (
      <div className={modifiers}>
        {options.map(o => (
          <div className={styles.radio} key={o.value}>
            <Radio
              id={id}
              checked={this.state.value === o.value}
              value={o.value}
              disabled={disabled}
              onCheck={this.onRadioChange}
              validate={validate}
              required={required}
            >
              {o.label}
            </Radio>
          </div>
        ))}
      </div>
    )
  }
}

RadioList.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  inline: PropTypes.bool,
  required: PropTypes.bool,
  autoValidate: PropTypes.bool,
  validate: PropTypes.bool,
}

RadioList.defaultProps = {
  disabled: false,
  onChange: null,
  options: [],
  value: null,
}
