import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BEboxesInactives = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes E-Coffrets Inactives"
    deliveryMethod={0}
    status={'inactive'}
  />
)
