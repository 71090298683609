import React from 'react'
import PropTypes from 'prop-types'
import styles from './Deactivation.module.scss'
import { REVIEWS_DEACTIVATION_REASONS } from 'services/reviews'
import { RadioList } from 'ui'

export const Deactivation = props => {
  const formOptions = REVIEWS_DEACTIVATION_REASONS.map(reason => ({
    ...reason,
    value: reason.value.toString(),
  }))

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Sélectionnez la raison de la désactivation
      </div>
      <div className={styles.subtitle}>
        Pour qu'un avis puisse être désactivé, il est obligatoire de renseigner
        un motif de désactivation.
      </div>

      <div className={styles.list}>
        <RadioList
          id={`FormReason`}
          options={formOptions}
          onChange={value => props.onChange(parseInt(value))}
          value={props.value.toString()}
        />
      </div>
    </div>
  )
}

Deactivation.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}
