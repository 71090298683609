import React from 'react'
import PropTypes from 'prop-types'
import { MenuPrimary } from 'ui'
import { NavigationInfos } from 'features/suppliers/components/NavigationInfos'

export const SupplierNavigation = props => {
  const { supplierKey, supplierName, title } = props
  const links = [
    { to: `/suppliers/${supplierKey}/shippings`, label: 'Commandes' },
    {
      to: `/suppliers/${supplierKey}/messages`,
      label: 'Messagerie',
    },
    {
      to: `/suppliers/${supplierKey}/reviews`,
      label: 'Avis clients',
    },
    {
      to: `/suppliers/${supplierKey}/invoices`,
      label: 'Facturation',
    },
    {
      to: `/suppliers/${supplierKey}/config`,
      label: 'Configuration',
    },
    {
      to: `/suppliers/${supplierKey}/services`,
      label: 'Services',
    },
    { to: `/account`, label: 'Mon compte' },
  ]

  return (
    <MenuPrimary
      links={links}
      logoUrl={`/suppliers/${supplierKey}`}
      title={title}
      infos={supplierName && <NavigationInfos supplierName={supplierName} />}
    />
  )
}

SupplierNavigation.propTypes = {
  supplierKey: PropTypes.string.isRequired,
  supplierName: PropTypes.string,
  title: PropTypes.string,
}

SupplierNavigation.defaultProps = {
  supplierName: null,
  title: null,
}
