import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, DateSelector, Divider, Grid } from 'ui'
import styles from './ActivateB2BModal.module.scss'

export const ActivateB2BModal = props => {
  const [start, setStart] = useState(getStartDateRange())
  const [end, setEnd] = useState(getEndDateRange(start.value))

  const onStartChange = value => {
    const newStart = { ...start, value }
    const newEnd = getEndDateRange(value)
    setStart(newStart)
    setEnd(newEnd)
    props.onChange({ start: newStart.value, end: newEnd.value })
  }

  const onEndChange = value => {
    const newEnd = { ...end, value }
    setEnd(newEnd)
    props.onChange({ start: start.value, end: newEnd.value })
  }

  const items = [
    <div className={styles.label}>Date de début</div>,
    <div className={styles.date}>
      <DateSelector
        value={start.value}
        onChange={onStartChange}
        min={start.min}
        max={start.max}
      />
    </div>,
    <div className={styles.label}>Date de fin</div>,
    <div className={styles.date}>
      <DateSelector
        value={end.value}
        onChange={onEndChange}
        min={end.min}
        max={end.max}
      />
    </div>,
  ]

  return (
    <div>
      <Card info light>
        Sélectionnez les dates de début et de fin d'activation.
        <br />
        Tous les coffrets de la commande seront activés automatiquement sur la
        période désirée.
      </Card>
      <Divider />
      <Grid cols={1} s={2} items={items} />
    </div>
  )
}

const getStartDateRange = () => {
  const d = new Date()

  const min = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  const max = new Date(Date.UTC(d.getFullYear() + 1, 11, 31))

  const value = new Date(min)
  return { value, min, max }
}

const getEndDateRange = startDate => {
  const min = new Date(startDate)
  min.setDate(startDate.getDate() + 1)

  const max = new Date(Date.UTC(startDate.getFullYear() + 1, 11, 31))

  const value = new Date(
    Date.UTC(
      startDate.getFullYear() + 1,
      startDate.getMonth(),
      startDate.getDate()
    )
  )

  return { value, min, max }
}

ActivateB2BModal.prototype = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
}
