import { Card, Heading } from 'ui'
import styles from './InvoicingChange.module.scss'

export const InvoicingChange = () => {
  return (
    <Card warning light>
      <Heading>Changements de facturation à partir de septembre 2023</Heading>
      <div className={styles.invoicing}>
        Nous facturions les commandes lorsqu'elles étaient envoyées, nous
        facturons dorénavant les commandes une fois reçues par vos
        consommateurs. Les commandes concernées sont toutes celles expédiées à
        partir du 1er septembre 2023.
        <br />
        <br />
        <b>Attention</b> : sur la facturation de septembre 2023, vous pouvez
        observer des différences avec l'historique de commandes : seules les
        commandes expédiées <b>et</b> reçues en septembre sont facturées (celles
        expédiées avant septembre ayant déjà été facturées sur la période
        précédente).
      </div>
    </Card>
  )
}
