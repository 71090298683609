import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Tooltip.module.scss'
import { IconInfo } from 'ui'

const BUTTON_SIZE = 16
const COLOR_ANNOTATION = '#72758e'
const COLOR_DEFAULT = '#262338'

export const Tooltip = ({ annotation, alt, bottom, children }) => {
  const [active, setActive] = useState(false)

  let modifiers = ` `
  if (active) modifiers += ` ${styles.active}`
  if (bottom) modifiers += ` ${styles.bottom}`
  if (alt) modifiers += ` ${styles.alt}`

  const stroke = annotation ? COLOR_ANNOTATION : COLOR_DEFAULT

  return (
    <div className={`${styles.tooltip}  ${modifiers}`}>
      <button className={styles.button} onClick={() => setActive(!active)}>
        <IconInfo width={BUTTON_SIZE} height={BUTTON_SIZE} stroke={stroke} />
      </button>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

Tooltip.propTypes = {
  bottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
  annotation: PropTypes.bool,
  alt: PropTypes.bool,
}

Tooltip.defaultProps = {
  bottom: false,
  annotation: false,
  alt: false,
}
