import React from 'react'
import Icon from './Icon'

export const IconCalendar = props => (
  <Icon {...props}>
    <rect x="2" y="3" width="20" height="19" rx="3" />
    <path d="M7 1V3" />
    <path d="M17 1V3" />
    <path d="M2 8H22" />
    <path d="M7.5 13H9.5" />
    <path d="M14.5 13H16.5" />
    <path d="M7.5 17H9.5" />
    <path d="M14.5 17H16.5" />
  </Icon>
)
