export const replaceOrAddUrlParam = (url, paramName, paramValue) => {
  if (paramValue == null) paramValue = ''
  const pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)')
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2')
  }
  url = url.replace(/[?#]$/, '')
  return (
    url + (url.indexOf('?') > -1 ? '&' : '?') + paramName + '=' + paramValue
  )
}

export const setRouteParam = (location, name, value) => {
  return {
    pathname: location.pathname,
    search: replaceOrAddUrlParam(
      location.search,
      name,
      encodeURIComponent(value)
    ),
  }
}

export const getRouteParam = (location, name) => {
  return new URLSearchParams(location.search).get(name)
}

export const goToAnchor = id => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
}
