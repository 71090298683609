import React from 'react'
import styles from './SupplierCardBody.module.scss'
import {
  IconActionClose,
  IconTrash,
  Divider,
  Badge,
  Link,
  IconClock,
  IconPause,
} from 'ui'
import { formatToDate } from 'utils/time'

export const SupplierCardBody = props => (
  <div className={styles.body}>
    <div className={styles.badges}>
      {props.supplier.active === false && !props.supplier.deleted && (
        <div className={styles.badge}>
          <Badge
            warning
            small
            label={'Inactif'}
            icon={<IconActionClose width={14} height={14} />}
          />
        </div>
      )}

      {props.supplier.deleted && (
        <div className={styles.badge}>
          <Badge
            error
            small
            label={'Supprimé'}
            icon={<IconTrash width={14} height={14} />}
            title={`Supprimé ${formatToDate(props.supplier.deletedAt)}`}
          />
        </div>
      )}

      {props.supplier.totalLate > 0 && (
        <div className={styles.badge}>
          <Badge
            info
            small
            label={`${props.supplier.totalLate} en retard`}
            icon={<IconClock width={14} height={14} />}
            title={`${
              props.supplier.totalLate
            } commandes en retard depuis ${formatToDate(
              props.supplier.oldestLate
            )}`}
          />
        </div>
      )}

      {props.supplier.off && (
        <div className={styles.badge}>
          <Badge
            info
            small
            label={`Jusqu'au ${formatToDate(props.supplier.offTo, false)}`}
            icon={<IconPause width={14} height={14} />}
            title={`En pause du ${formatToDate(
              props.supplier.offFrom,
              false
            )} au ${formatToDate(props.supplier.offTo, false)}`}
          />
        </div>
      )}
    </div>
    <ul className={styles.wrapper}>
      <li className={styles.line}>
        <div className={styles.label}> Administratif :</div>
        <div className={styles.value}>
          {props.supplier.adminEmail ? (
            <Link to={`mailto:${props.supplier.adminEmail}`} external>
              {props.supplier.adminEmail}
            </Link>
          ) : (
            'Non renseigné'
          )}
        </div>
      </li>
      <li className={styles.line}>
        <div className={styles.label}> Support : </div>
        <div className={styles.value}>
          {props.supplier.supportEmail ? (
            <Link to={`mailto:${props.supplier.supportEmail}`} external>
              {props.supplier.supportEmail}
            </Link>
          ) : (
            'Non renseigné'
          )}
        </div>
      </li>
      <li className={styles.line}>
        <div className={styles.label}>Téléphone : </div>
        <div className={styles.value}>
          {props.supplier.phone ? (
            <Link to={`tel:${props.supplier.phone}`} external>
              {props.supplier.phone}
            </Link>
          ) : (
            ' Non renseigné'
          )}
        </div>
      </li>
    </ul>

    <Divider border />

    <ul className={styles.wrapper}>
      <li className={styles.line}>
        <div className={styles.label}>Commission : </div>
        <div className={styles.stat}>{props.supplier.commission} % </div>
      </li>
      <li className={styles.line}>
        <div className={styles.label}> Commandes expédiées :</div>{' '}
        <div className={styles.stat}>{props.supplier.ordersSent}</div>
      </li>
      <li className={styles.line}>
        <div className={styles.label}>Note moyenne :</div>
        <div className={styles.stat}>
          {props.supplier.averageRating ? props.supplier.averageRating : '/'}
        </div>
      </li>
    </ul>
  </div>
)
