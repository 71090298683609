import React from 'react'
import { Badge, Divider } from 'ui'
import styles from './OrderB2BCardTracking.module.scss'

export class OrderB2BCardTracking extends React.Component {
  render() {
    const items = this.props.items

    return (
      <div className={styles.tracking}>
        <Divider border />
        <div className={styles.title}>Expéditions</div>

        {!items && (
          <div className={styles.empty}>
            Aucun numéro de suivi associé à cette commande
          </div>
        )}

        <div className={styles.list}>
          {items?.map(item => (
            <div className={styles.item} key={item.number}>
              <Badge
                key={item.number}
                small
                label={`${item.number} (${this.getNbBoxesLabel(item.nbBoxes)})`}
                to={item.url}
                external={true}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
  getNbBoxesLabel(nbBoxes) {
    return `${nbBoxes} coffret${nbBoxes > 1 ? 's' : ''}`
  }
}
