import React from 'react'
import { FeatureAdminReviewsList } from './List'

export const FeatureAdminReviewsNegative = props => (
  <FeatureAdminReviewsList
    {...props}
    ratings={[1, 2]}
    title="Avis clients négatifs"
  />
)
