import { IconClose } from 'ui'
import {
  removeFromSelection,
  setCurrentProduct,
} from 'features/customers/redeem/slice'

import styles from './CartItem.module.scss'
import { useDispatch } from 'react-redux'

const DEFAULT_PIC = '/redeeming/product-default.png'
const DEFAULT_TITLE = 'À choisir'

export const CartItem = ({ item, readonly = false }) => {
  const dispatch = useDispatch()

  let title = DEFAULT_TITLE
  if (item?.product) title = item.product.title
  if (item?.variant) title += ' ' + item.variant.title

  let picture = null
  if (item) picture = DEFAULT_PIC
  if (item?.product?.featuredImage) picture = item.product.featuredImage
  if (item?.variant?.image) picture = item.variant.image

  let modifiers = !!item ? ' ' + styles._selected : ''
  modifiers += readonly ? ' ' + styles._readonly : ''

  return (
    <div className={`${styles.item} ${modifiers}`} title={title}>
      <div
        className={styles.itemContent}
        onClick={() => {
          !readonly &&
            item &&
            dispatch(
              setCurrentProduct({
                product: item.product,
                variant: item.variant,
              })
            )
        }}
      >
        <div className={styles.left}>
          {picture && (
            <div className={styles.pic}>
              <img className={styles.img} src={picture} alt={title} />
            </div>
          )}
          {!picture && <div className={styles.nopic}>?</div>}
        </div>
        <div className={styles.itemTitle}>{title}</div>
      </div>
      {!readonly && item && (
        <button
          className={styles.itemClose}
          title="Supprimer de la sélection"
          onClick={() => dispatch(removeFromSelection(item.product.id))}
        >
          <span className={styles.itemCloseWhite}>
            <IconClose stroke="#fff" />
          </span>
          <span className={styles.itemCloseBlack}>
            <IconClose />
          </span>
        </button>
      )}
    </div>
  )
}
