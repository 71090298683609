import { useEffect, useState } from 'react'

export const ENV_PROD = 'prod'
export const ENV_STAGING = 'staging'
export const ENV_REPLICA = 'replica'
export const ENV_DEV = 'dev'

export const useEnv = () => {
  const [env, setEnv] = useState(ENV_PROD)
  useEffect(() => {
    if (window.location.hostname.match(/localhost/gi) !== null) {
      setEnv(ENV_DEV)
    } else if (window.location.hostname.match(/staging/gi) !== null) {
      setEnv(ENV_STAGING)
    } else if (window.location.hostname.match(/replica/gi) !== null) {
      setEnv(ENV_REPLICA)
    }
  }, [])
  return env
}
