import React from 'react'
import PropTypes from 'prop-types'
import styles from './OrderB2BPackagerCard.module.scss'
import { Card, Button } from 'ui'
import {
  OrderB2BCardHeader,
  OrderB2BCardInfos,
  OrderB2BCardTracking,
} from './components'

export class OrderB2BPackagerCard extends React.Component {
  constructor(props) {
    super(props)
    this.onAddTrackingNumberClick = this.onAddTrackingNumberClick.bind(this)
  }

  onAddTrackingNumberClick = () => {
    this.props.onAddTrackingNumber(this.props.order)
  }

  getCardActions = () => {
    if (this.isSent()) return []

    return [
      <Button
        onClick={this.onAddTrackingNumberClick}
        label={'Ajouter n° de suivi'}
        primary
      >
        Ajouter n° de suivi
      </Button>,
    ]
  }

  isSent = () => {
    const { order } = this.props
    return order.giftBoxNumber === order.nbBoxesSent
  }

  render() {
    const {
      order: { nbBoxesSent, trackingItems },
    } = this.props

    const displayTracking = nbBoxesSent > 0

    let modifiers = ''
    if (displayTracking) modifiers += ` ${styles._tracking}`

    return (
      <Card padding={false} light actions={this.getCardActions()}>
        <div className={`${styles.order} ${modifiers}`}>
          <div className={styles.container}>
            <OrderB2BCardHeader {...this.props} />

            <div className={styles.infos}>
              <OrderB2BCardInfos {...this.props} hideType={true} />
            </div>

            {displayTracking && <OrderB2BCardTracking items={trackingItems} />}
          </div>
        </div>
      </Card>
    )
  }
}

OrderB2BPackagerCard.propTypes = {
  onAddTrackingNumber: PropTypes.func,
  order: PropTypes.shape({
    createdAt: PropTypes.string,
    customerCompany: PropTypes.string,
    giftBoxNumber: PropTypes.number,
    giftNumber: PropTypes.number,
    id: PropTypes.number,
    isPhysical: PropTypes.bool,
    nbBoxesSent: PropTypes.number,
    product: PropTypes.string,
    status: PropTypes.string,
    trackingItems: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        url: PropTypes.string,
        nbBoxes: PropTypes.number,
      })
    ),
  }).isRequired,
}
