import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu.js'
import store from 'stores/Admin/EditThemeStore.js'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  componentDidMount() {
    store.reset()
    store.subscribe(state => this.setState(state))
    store.load(this.props.params.id)
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  submit = e => {
    e.preventDefault()
    store.submit()
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          unknown_theme: "Ce thème n'existe pas.",
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page admin-page" id="admin-new-theme-page">
        <Helmet>
          <title>Thèmes - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />

        <div className="group admin-list">
          <h1 className="h1">Modifier thème</h1>

          <form onSubmit={this.submit}>
            <div className="input-group">
              <label>Nom du thème</label>
              <input
                type="text"
                placeholder="Nom…"
                className="text-field"
                value={this.state.name}
                onChange={e => store.nameChanged(e.target.value)}
                required={true}
              />
            </div>

            <div className="input-group">
              <label>Url de l'image</label>
              <input
                type="text"
                placeholder="url…"
                className="text-field"
                value={this.state.imageUrl}
                onChange={e => store.imageUrlChanged(e.target.value)}
                required={true}
              />
            </div>

            <p>
              <button className="btn">Mettre à jour</button>
            </p>
          </form>
        </div>
      </div>
    )
  }
}

const EditThemePage = withRouter(Page)
export default EditThemePage
