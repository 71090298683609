import React from 'react'
import PropTypes from 'prop-types'
import styles from './Selectbox.module.scss'
import { IconChevronDown, IconClose } from 'ui'

export class Selectbox extends React.Component {
  constructor(props) {
    super(props)
    this.selectRef = React.createRef()
    this.state = { validate: false, focus: false }
  }

  componentDidMount() {
    this.setInputValue(this.props.initialValue)

    if (this.props.validate) this.setState({ validate: true })
    if (this.props.focused) this.selectRef.current.focus()
  }

  componentDidUpdate(prevProps) {
    const { value } = this.getInputState()

    if (
      prevProps.initialValue !== this.props.initialValue &&
      this.props.initialValue !== value
    ) {
      this.setInputValue(this.props.initialValue)
      this.onChange()
    }

    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }
  }

  onFocus = () => {
    this.setState({ focus: true })
  }

  onBlur = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    this.setState({ focus: false })
    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    if (this.props.onBlur) this.props.onBlur(value, valid)
  }

  onChange = () => {
    const { validate } = this.state
    const { value, valid } = this.getInputState()

    if (this.props.onChange) this.props.onChange(value, valid)
    if (this.props.autoValidate && !validate) this.setState({ validate: true })
    this.forceUpdate() // force update as the input state may have changed
  }

  setInputValue = value => {
    this.selectRef.current.value = value
  }

  getInputState = () => {
    if (!this.selectRef.current) return { value: null, valid: null }

    const value = this.selectRef.current.value
    const valid = this.selectRef.current.validity.valid

    return { value, valid }
  }

  reset = () => {
    this.setInputValue('')
    const { value, valid } = this.getInputState()
    this.props.onChange(value, valid)
    this.forceUpdate() // force update as the input state have changed
  }

  render() {
    const { value, valid } = this.getInputState()
    const { focus } = this.state
    const { disabled, id, values, width, initialValue } = this.props
    let svgColor
    let modifiers = ''

    if (this.props.disabled) modifiers += ` ${styles._disabled}`
    if (this.props.disabled && this.props.initialValue)
      modifiers += ` ${styles._disabledWithValue}`
    if (this.props.resetable) modifiers += ` ${styles._resetable}`
    if (this.props.width) modifiers += ` ${styles._width}`
    if (focus) modifiers += ` ${styles._focus}`
    if (this.state.validate) {
      svgColor = valid ? '#00cc67' : '#e40173'
      modifiers += valid ? ` ${styles._valid}` : ` ${styles._invalid}`
    }

    const isValueObjects = values[0] && typeof values[0] === 'object'

    const valueId = value !== null ? value : initialValue
    let selectValue = valueId
    if (isValueObjects) {
      selectValue = values?.find(v => v.id === selectValue)?.value
    }

    return (
      <div className={`${styles.control}${modifiers}`} style={{ width }}>
        <label className={styles.label}>
          <select
            className={styles.select}
            ref={this.selectRef}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder=" "
            name={id}
            disabled={disabled}
            required
          >
            {values.map(v => (
              <option key={isValueObjects ? v.id : v} value={isValueObjects ? v.id : v}>
                {isValueObjects ? v.value : v}
              </option>
            ))}
          </select>
          <div className={styles.placeholder}>{this.props.label}</div>
          <div className={styles.value}>{selectValue}</div>
          {this.props.resetable && selectValue && (
            <button className={styles.reset} onClick={this.reset}>
              <IconClose stroke={svgColor} />
            </button>
          )}
          <div className={styles.arrow}>
            <IconChevronDown stroke={svgColor} />
          </div>
        </label>
      </div>
    )
  }
}

Selectbox.propTypes = {
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  resetable: PropTypes.bool,
  validate: PropTypes.bool,
  autoValidate: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ])
  ),
}

Selectbox.defaultProps = {
  disabled: false,
  focused: false,
  initialValue: '',
  values: [],
}
