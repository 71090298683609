import React from 'react'
import './Pagination.scss'

/*
 * Properties:
 *
 * * pageCount    Number
 * * currentPage  Number
 * * onChange     Function onChange(page int) - callback called when page is changing
 *
 */
export default class Pagination extends React.Component {
  previousPage() {
    if (this.props.currentPage > 1) {
      return (
        <button onClick={e => this.props.onChange(this.props.currentPage - 1)}>
          «
        </button>
      )
    }
  }

  pageSelector() {
    let pages = []

    for (let i = 0; i < this.props.pageCount; i++) {
      pages.push(
        <option value={i + 1} key={i}>
          {i + 1}
        </option>
      )
    }

    return (
      <select
        value={this.props.currentPage}
        onChange={e => this.props.onChange(parseInt(e.target.value, 10))}
      >
        {pages}
      </select>
    )
  }

  nextPage() {
    if (this.props.currentPage < this.props.pageCount) {
      return (
        <button onClick={e => this.props.onChange(this.props.currentPage + 1)}>
          Next »
        </button>
      )
    }
  }

  render() {
    return (
      <div className="pagination">
        {this.previousPage()}
        {this.pageSelector()}
        {this.nextPage()}
      </div>
    )
  }
}
