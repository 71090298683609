import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Heading } from 'ui'
import { CartItem } from './CartItem'

import styles from './Cart.module.scss'
import { fillRedeem } from '../slice'

export const Cart = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const box = useSelector(state => state.redeem.box.item)
  const selection = useSelector(state => state.redeem.selection)
  const [isOpened, setIsOpened] = useState(false)
  const activeProducts = box?.activeProducts?.map(mapProductToCartitem) || []

  const gifts = box?.gifts || 0
  if (!box?.redeemed && gifts < 2) return <span></span>

  const remainingGifts = box?.remainingGifts || 0

  let modifiers = isOpened ? ' ' + styles._opened : ''
  if (activeProducts.length === 0 && selection.length === 0) {
    modifiers += ' ' + styles._empty
  }

  return (
    <div className={`${styles.wrapper} ${modifiers}`}>
      <div className={styles.backdrop} onClick={() => setIsOpened(false)}></div>
      <div className={styles.modal}>
        <div className={styles.inner}>
          <div className={styles.title}>
            <Heading>Votre sélection :</Heading>
          </div>
          <div className={styles.empty}>
            <div className={styles.emptyBlock}>
              Vous n'avez pas encore sélectionné de produit !
            </div>
            <div className={styles.emptyBlock}>
              Parcourez les offres de nos artisans, trouvez votre bonheur et
              ajoutez les à votre sélection.
            </div>
            <div className={styles.emptyBlock}>
              PSSST : vous pouvez choisir {gifts} cadeaux parmi toutes les
              offres !
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.products}>
              <div className={styles.choosen}>
                {activeProducts.map((p, k) => (
                  <CartItem key={k} item={p} readonly />
                ))}
              </div>
              <div className={styles.list}>
                {Array(remainingGifts)
                  .fill()
                  .map((_, k) => (
                    <CartItem key={k} item={selection[k] || null} />
                  ))}
              </div>
            </div>
            <div className={styles.valid}>
              <Button
                primary
                disabled={selection.length !== remainingGifts}
                onClick={() => {
                  if (box.redeemed) {
                    dispatch(fillRedeem())
                    return
                  }
                  navigate('/redeem/information')
                }}
              >
                Valider votre sélection
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.mobile}>
          {isOpened && (
            <div className={styles.cta}>
              <Button light onClick={() => setIsOpened(false)}>
                Fermer
              </Button>
            </div>
          )}
          {isOpened && selection.length === gifts && (
            <div className={styles.cta}>
              <Button primary onClick={() => navigate('/redeem/information')}>
                Valider
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.opener}>
        <div className={styles.cta}>
          <Button primary onClick={() => setIsOpened(true)}>
            Votre sélection
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapProductToCartitem = product => ({
  product: {
    title: product.title,
    featuredImage: product.image,
  },
})
