import React from 'react'
import PropTypes from 'prop-types'
import styles from './Cell.module.scss'

export const Cell = ({
  align,
  children,
  condensed,
  italic,
  header,
  highlight,
  width,
}) => {
  const TagName = header ? 'th' : 'td'

  let modifiers = ` ${styles[align]}`
  if (condensed) modifiers += ` ${styles.condensed}`
  if (italic) modifiers += ` ${styles.italic}`
  if (highlight) modifiers += ` ${styles.highlight}`
  if (header) modifiers += ` ${styles.header}`

  return (
    <TagName
      className={`${styles.cell} ${modifiers}`}
      width={width ? `${width}` : 'auto'}
    >
      {children || '\u00A0'}
    </TagName>
  )
}

Cell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  condensed: PropTypes.bool,
  italic: PropTypes.bool,
  header: PropTypes.bool,
  highlight: PropTypes.bool,
  width: PropTypes.number,
}

Cell.defaultProps = {
  align: 'left',
  condensed: false,
  italic: false,
  header: false,
  highlight: false,
}
