import React from 'react'
import PropTypes from 'prop-types'
import { IconClose, IconSuccess } from 'ui'
import styles from './Stepper.module.scss'

export const Stepper = ({ current, error, steps }) => {
  const iconError = <IconClose stroke={'#fff'} height={16} width={16} />
  const iconSuccess = <IconSuccess stroke={'#fff'} height={16} width={16} />

  return (
    <ul className={`${styles.wrapper} ${error ? styles.error : ''}`}>
      {steps.map((s, i) => (
        <li
          key={i}
          className={`${styles.item}
          ${isActive(current, i) ? styles.active : ''}
          ${isSuccess(steps, error, i) ? styles.success : ''}
          ${isError(current, error, i) ? styles.error : ''}
          `}
        >
          <div className={styles.title}>{s.title}</div>
          <div className={styles.infos}>{s.infos}</div>
          <div className={styles.point}>
            {isSuccess(steps, error, i) ? iconSuccess : ''}
            {isError(current, error, i) ? iconError : ''}
          </div>
        </li>
      ))}
    </ul>
  )
}

const isActive = (current, i) => current > i
const isError = (current, error, i) => error && current === i + 1
const isSuccess = (steps, error, i) => !error && steps.length === i + 1

Stepper.propTypes = {
  current: PropTypes.number,
  error: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      infos: PropTypes.string,
    })
  ),
}

Stepper.defaultProps = {
  error: false,
}
