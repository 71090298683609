import React from 'react'
import {
  IconCalendar,
  IconClock,
  IconHome,
  IconSuccess,
  IconActionClose,
  IconStats,
  IconThumbDown,
} from 'ui'

export const MENU_LINKS = [
  {
    to: `/a/reviews/today`,
    icon: <IconCalendar />,
    label: `Aujourd'hui`,
    primary: true,
  },
  {
    to: `/a/reviews/quarantine`,
    icon: <IconClock />,
    label: 'Quarantaine',
    primary: true,
  },
  {
    to: `/a/reviews/negative`,
    icon: <IconThumbDown />,
    label: 'Avis négatifs',
    primary: true,
  },
  { divider: true },
  {
    to: `/a/reviews/all`,
    icon: <IconHome />,
    label: 'Tous les avis',
  },
  {
    to: `/a/reviews/active`,
    icon: <IconSuccess />,
    label: 'Avis actifs',
  },
  {
    to: `/a/reviews/inactive`,
    icon: <IconActionClose />,
    label: 'Avis inactifs',
  },
  { divider: true },
  {
    to: `/a/reviews/stats`,
    icon: <IconStats />,
    label: 'Statistiques',
  },
]
