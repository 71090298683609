import React from 'react'
import Lottie from 'react-lottie'
import styles from './Empty.module.scss'

import animationData from './Empty.json'

export const Empty = () => (
  <div className={styles.empty}>
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={300}
      width={300}
    />
    <div className={styles.title}>Il n'y a rien par ici !</div>
  </div>
)
