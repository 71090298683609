import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './MenuLink.module.scss'
import { withFade } from 'ui/hoc'
import { Badge } from 'ui'
import { goToAnchor } from 'utils/route'

const Value = withFade(props => (
  <span className={styles.value}>{props.value}</span>
))

export const MenuLink = props => {
  const location = useLocation()

  const { icon, label, light, isNew, primary, to, value } = props
  const isAnchor = to.startsWith('#')

  let modifiers = ``
  if (light) modifiers += ` ${styles._light}`
  if (primary) modifiers += ` ${styles._primary}`

  const classNames = ({ isActive }) => {
    const active = (isAnchor && location.hash === to) || (!isAnchor && isActive)
    return `${styles.link} ${modifiers} ${active ? styles._active : ''}`
  }

  const onClick = () => {
    if (isAnchor) goToAnchor(to.substring(1))
  }

  return (
    <NavLink className={classNames} to={to} onClick={onClick}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>
        {label}
        {isNew && (
          <span className={styles.new}>
            <Badge small error label={'New!'} />
          </span>
        )}
      </span>
      {value !== null && <Value value={value} />}
    </NavLink>
  )
}

MenuLink.propTypes = {
  icon: PropTypes.element,
  isNew: PropTypes.bool,
  label: PropTypes.string.isRequired,
  light: PropTypes.bool,
  primary: PropTypes.bool,
  to: PropTypes.string.isRequired,
  value: PropTypes.number,
}

MenuLink.defaultProps = {
  icon: null,
  isNew: false,
  light: false,
  primary: false,
  value: null,
}
