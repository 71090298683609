var config = {
  maintenance: false,
}

if (process.env.NODE_ENV === 'production') {
  if (window.location.href.match(/^https:\/\/staging/)) {
    config.apiUrl = 'https://staging-app.icipresent.com'
    config.downloadUrl = 'https://staging-app.icipresent.com'
  } else if (window.location.href.match(/^https:\/\/replica/)) {
    config.apiUrl = 'https://replica-app.icipresent.com'
    config.downloadUrl = 'https://replica-download.icipresent.com'
  } else {
    config.apiUrl = 'https://app.icipresent.com'
    config.downloadUrl = 'https://download.icipresent.com'
  }
} else {
  config.apiUrl = window.location.href
    .replace(/^(https?:\/\/.*?)\/.*/, '$1')
    .replace(/:3/, ':5')
  config.downloadUrl = window.location.href
    .replace(/^(https?:\/\/.*?)\/.*/, '$1')
    .replace(/:3/, ':5')
}

export default config
