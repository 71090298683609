import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Divider,
  Grid,
  LayoutFixed,
  MenuSecondary,
  ThreadAdminCard,
  Title,
} from 'ui'
import { MENU_LINKS } from '../@commons'
import { AdminNavigation } from '../../components'

export const FeatureAdminSupportThreads = ({ title, callback }) => {
  const [searchParams] = useSearchParams()
  const [threads, setThreads] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState(null)

  useEffect(() => {
    setPage(parseInt(searchParams.get('page')) || 1)
  }, [searchParams])

  return (
    <LayoutFixed
      nav={<AdminNavigation title={'Support'} />}
      menu={<MenuSecondary links={MENU_LINKS} />}
      loadData={useCallback(async () => callback({ page }), [callback, page])}
      onDataLoaded={data => {
        setThreads(data.result.threads)
        setPagination(data.pagination)
      }}
      title={title}
    >
      <div>
        <Title>
          {title} ({pagination?.nbItems})
        </Title>

        <Divider />

        <Grid
          cols={1}
          items={threads.map(t => (
            <ThreadAdminCard key={t.uniqueKey} thread={t} />
          ))}
        />
      </div>
    </LayoutFixed>
  )
}
