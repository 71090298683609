import React from 'react'
import PropTypes from 'prop-types'
import styles from './withStatus.module.scss'

export const withStatus = WrappedComponent => {
  return props => {
    let defaultStatus = false
    let modifiers = ''

    if (props.light) modifiers += ` ${styles._light}`

    if (props.primary) modifiers += ` ${styles._primary}`
    else if (props.info) modifiers += ` ${styles._info}`
    else if (props.success) modifiers += ` ${styles._success}`
    else if (props.warning) modifiers += ` ${styles._warning}`
    else if (props.error) modifiers += ` ${styles._error}`
    else {
      modifiers += ` ${styles._default}`
      defaultStatus = true
    }

    return (
      <WrappedComponent
        {...props}
        className={modifiers}
        defaultStatus={defaultStatus}
      />
    )
  }
}

withStatus.propTypes = {
  light: PropTypes.bool,
  primary: PropTypes.bool,
  info: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
}

withStatus.defaultProps = {
  light: false,
  primary: false,
  info: false,
  success: false,
  warning: false,
  error: false,
}
