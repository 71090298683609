import React from 'react'
import PropTypes from 'prop-types'
import styles from './OrderB2CCard.module.scss'
import { Card } from 'ui'
import {
  OrderB2CCardHeader,
  OrderB2CCardInfos,
  OrderB2CCardBoxes,
} from './components'

export const OrderB2CCard = props => {
  return (
    <Card padding={false} light>
      <div className={styles.card}>
        <div className={styles.container}>
          <OrderB2CCardHeader {...props} />

          <div className={styles.infos}>
            <OrderB2CCardInfos {...props} />
          </div>

          <OrderB2CCardBoxes {...props} />
        </div>
      </div>
    </Card>
  )
}

OrderB2CCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.string,
    shopifyId: PropTypes.number,
    shopifyInternalId: PropTypes.number,
    price: PropTypes.string,
    customer: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    boxes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        category: PropTypes.string,
        label: PropTypes.string,
        link: PropTypes.string,
      })
    ),
  }).isRequired,
}
