import React from 'react'
import './Errors.scss'

/*
 * Properties:
 *
 * * messages [String] - human friendly messages
 */
export default class Errors extends React.Component {
  constructor(props) {
    super(props)
    this.state = { messages: props.messages, opened: !!props.messages.length }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.messages) !== JSON.stringify(this.props.messages)
    ) {
      this.setState({
        messages: this.props.messages,
        opened: !!this.props.messages.length,
      })
    }
  }

  close = () => {
    this.setState({ opened: false })
  }

  className() {
    return this.state.opened ? 'filled' : 'empty'
  }

  messages() {
    return this.state.messages.map(message => <li key={message}>{message}</li>)
  }

  render() {
    return (
      <div id="errors" className={this.className()}>
        <ul>{this.messages()}</ul>
        <button className="btn" onClick={this.close}>
          close
        </button>
      </div>
    )
  }
}
