import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const withRouter = Component => {
  return props => (
    <Component
      {...props}
      location={useLocation()}
      navigate={useNavigate()}
      params={useParams()}
    />
  )
}
