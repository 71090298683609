import api from 'utils/api'

export const REVIEWS_DEACTIVATION_REASONS = [
  {
    label:
      "L'avis est identifié comme visant à biaiser la moyenne de la société notée ou comporte des éléments concrets de conflits d'intérêt",
    value: 1,
  },
  {
    label: "L'avis est sans rapport avec le sujet noté",
    value: 2,
  },
  {
    label:
      "L'avis comporte des informations à caractère personnel ou toute autre information susceptible d'identifier le rédacteur, de le contacter ou d'aboutir à un vol d'identité",
    value: 3,
  },
  {
    label:
      "L'avis mentionne le nom d'un concurrent et/ou incite à l'achat chez la concurrence",
    value: 4,
  },
  {
    label:
      "Le rédacteur précise dans son avis qu'il n'est pas encore en mesure d'évaluer la société notée, ou qu'il n'a pas eu recours à ses services",
    value: 5,
  },
  {
    label:
      "L'avis comporte des éléments promotionnels, est considéré comme spam, et/ou mentionne des sites web",
    value: 6,
  },
  {
    label:
      "La société notée est intervenue sur le traitement du litige, et le rédacteur de l'avis souhaite le mettre à jour",
    value: 7,
  },
  {
    label:
      "Le rédacteur de l'avis a contacté Ici Présent! ou la société notée afin de modifier ou de supprimer son avis",
    value: 8,
  },
  {
    label: 'Ici Présent! a identifié cet avis comme frauduleux',
    value: 9,
  },

  {
    label:
      'Ici Présent! estime que sa responsabilité civile ou pénale peut être engagée',
    value: 10,
  },
]

export const activateReview = id =>
  api.post(`api/admin/reviews/activate`, { id })

export const deactivateReview = (id, reason) =>
  api.post(`api/admin/reviews/deactivate`, { id, reason })

export const getReviews = body => api.post(`api/admin/reviews/list`, body)

export const getReviewsStats = () => api.post(`api/admin/reviews/stats`)
