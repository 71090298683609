import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Modal.module.scss'
import { IconClose, Heading, Loader } from 'ui'
import { withFade } from 'ui/hoc'

const KEYBOARD_ESC_KEY = 27
const FadeContent = withFade(props => props.children)

export class Modal extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress)
  }

  onKeyPress = event => {
    const { opened, onClose } = this.props
    if (event.keyCode === KEYBOARD_ESC_KEY && opened && onClose) {
      onClose(event)
    }
  }

  render() {
    const {
      actions,
      leftActions,
      children,
      closable,
      closeToLeft,
      onClose,
      opened,
      title,
      loading,
      small,
      nopadding,
    } = this.props

    const showContent = !loading
    const showActions =
      (actions.length > 0 || leftActions.length > 0) && !loading

    let modifiers = ''
    if (loading) modifiers += `${styles._loading}`
    if (opened) modifiers += ` ${styles._opened}`
    if (closeToLeft) modifiers += ` ${styles._leftClose}`
    if (small) modifiers += ` ${styles._small}`
    if (nopadding) modifiers += ` ${styles._nopadding}`

    return (
      <div className={`${styles.outer} ${modifiers}`}>
        <div className={styles.bg} onClick={onClose}></div>

        <div className={styles.modal}>
          <div className={styles.inner}>
            {(title || closable) && (
              <div className={styles.top}>
                <div>{title && <Heading>{title}</Heading>}</div>
                <div>
                  {closable && (
                    <button className={styles.close} onClick={onClose}>
                      <IconClose
                        id={'ModalClose'}
                        label={'Fermer la fenêtre'}
                      />
                    </button>
                  )}
                </div>
              </div>
            )}

            {loading && (
              <FadeContent className={styles.loader}>
                <Loader />
              </FadeContent>
            )}
            {showContent && (
              <div className={styles.content}>
                <div className={styles.contentInner}>{children}</div>
              </div>
            )}
            {showActions && (
              <div className={styles.actions}>
                <div className={styles.left}>
                  {leftActions.map((action, index) => (
                    <div className={styles.action} key={index}>
                      {action}
                    </div>
                  ))}
                </div>
                <div className={styles.right}>
                  {actions.map((action, index) => (
                    <div className={styles.action} key={index}>
                      {action}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  leftActions: PropTypes.arrayOf(PropTypes.element),
  closable: PropTypes.bool,
  closeToLeft: PropTypes.bool,
  small: PropTypes.bool,
  nopadding: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loading: PropTypes.bool,
}

Modal.defaultProps = {
  actions: [],
  leftActions: [],
  closable: false,
  closeToLeft: false,
  small: false,
  nopadding: false,
  opened: false,
  onClose: null,
  title: null,
  loading: false,
}
