import React from 'react'
import { Helmet } from 'react-helmet'
import Thread from './SupplierMessagesPage/Thread'
import Pagination from 'components/Pagination'
import store from 'stores/SupplierMessagesPageStore'
import './SupplierMessagesPage.scss'
import { SupplierNavigation } from 'features/suppliers/components'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  componentDidMount() {
    store.reset()
    store.subscribe(this.setState.bind(this))
    store.setState({ uniqueKey: this.props.params.uniqueKey })
    store.retrieveThreads()
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  threads() {
    if (!this.state.threads.length) {
      return <p className="center">Pas de messages pour l'instant!</p>
    } else {
      return this.state.threads.map(t => (
        <Thread key={t.id} {...t} supplierHash={this.state.uniqueKey} />
      ))
    }
  }

  pagination() {
    if (this.state.pageCount > 1) {
      return (
        <p>
          <Pagination
            pageCount={this.state.pageCount}
            currentPage={this.state.currentPage}
            onChange={page => store.changeCurrentPage(page)}
          />
        </p>
      )
    }
  }

  render() {
    return (
      <div className="page" id="supplier-messages-page">
        <Helmet>
          <title>Messagerie - Ici Présent!</title>
        </Helmet>
        <div class="menu">
          <SupplierNavigation
            supplierKey={this.state.uniqueKey}
            title={'Messages'}
          />
        </div>

        <div className="group">
          <div className="feature" id="explanation">
            <h1 className="h1">Votre messagerie</h1>
            {this.pagination()}
            {this.threads()}
            {this.pagination()}
          </div>
        </div>
      </div>
    )
  }
}

const SupplierMessagesPage = withRouter(Page)
export default SupplierMessagesPage
