export const formatPrice = (value, decimals = 2) =>
  `${formatNumber(value, decimals)} €`

export const formatCents = (value, decimals) =>
  formatPrice(value / 100, decimals)

export const formatPercents = (value, decimals) =>
  `${formatNumber(value, decimals)} %`

export const formatNumber = (value, decimals = 2) =>
  value.toLocaleString('fr-FR', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

export const toPascalCase = word => {
  return word !== null && word !== ''
    ? word[0].toUpperCase() + word.slice(1, word.length)
    : word
}
