import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './store'
import './styles/global.scss'

// make sure we're not caching frontent on download domain, in case
// of mistyped urls, because it then makes it display empty
// frontend each time we try to download a file (trololo).
if (window.location.host.match(/download/)) {
  if (window.location.host.match(/staging/)) {
    window.location.href = window.location.href.replace(
      /staging-download/,
      'staging-app'
    )
  } else {
    window.location.href = window.location.href.replace(
      /download.icipresent/,
      'app.icipresent'
    )
  }
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
}
