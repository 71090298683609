import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class NewSupplierStore extends BaseStore {
  setDefaults() {
    this.state = {
      success: false,
      name: '',
      emails: '',
      supportEmails: '',
      vat: 0,
      trackingCodeRequired: false,
      useColissimo: false,
      colissimo_address: '',
      colissimo_city: '',
      colissimo_zipcode: '',
      active: true,
      inactiveReason: '',
      internalInfos: '',
      errors: [],
    }
  }

  nameChanged(name) {
    this.setState({ name })
  }

  emailsChanged(emails) {
    this.setState({ emails })
  }

  supportEmailsChanged(supportEmails) {
    this.setState({ supportEmails })
  }

  activeChanged(active) {
    this.setState({ active })
  }

  inactiveReasonChanged(inactiveReason) {
    this.setState({ inactiveReason })
  }

  internalInfosChanged(internalInfos) {
    this.setState({ internalInfos })
  }

  vatChanged(vat) {
    this.setState({ vat })
  }

  commissionChanged(commission) {
    this.setState({ commission })
  }

  trackingCodeRequiredChanged(trackingCodeRequired) {
    this.setState({ trackingCodeRequired })
  }

  useColissimoChanged(useColissimo) {
    this.setState({ useColissimo })
  }

  colissimoAddressChanged(colissimo_address) {
    this.setState({ colissimo_address })
  }

  colissimoZipcodeChanged(colissimo_zipcode) {
    this.setState({ colissimo_zipcode })
  }

  colissimoCityChanged(colissimo_city) {
    this.setState({ colissimo_city })
  }

  async submit() {
    let params = {
      name: this.state.name.trim(),
      emails: this.state.emails.split(/\s+/).filter(e => e),
      supportEmails: this.state.supportEmails.split(/\s+/).filter(e => e),
      vat: parseFloat(this.state.vat),
      commission: parseFloat(this.state.commission),
      trackingCodeRequired: this.state.trackingCodeRequired,
      useColissimo: this.state.useColissimo,
      active: this.state.active,
      inactiveReason: this.state.inactiveReason.trim(),
      internalInfos: this.state.internalInfos.trim(),
    }

    if (this.state.useColissimo) {
      params.colissimo_address = this.state.colissimo_address.trim()
      params.colissimo_zipcode = this.state.colissimo_zipcode.trim()
      params.colissimo_city = this.state.colissimo_city.trim()
    }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/suppliers/create`, {
      method: 'POST',
      body,
    })

    let data = await resp.json()

    if (data.success) {
      alert('Le fournisseur a bien été créé.')
      window.location.href = '/a/suppliers'
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new NewSupplierStore()
export default store
