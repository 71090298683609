import moment from 'moment';

function formatDate(datetime) {
  return moment(datetime).format('DD/MM/YYYY');
}

function formatTime(datetime) {
  return moment(datetime).format('HH:mm:ss');
}

function formatDateTime(datetime) {
  return moment(datetime).format('DD/MM/YYYY, à HH:mm:ss');
}

export {formatDate, formatTime, formatDateTime};
