import React from 'react'
import PropTypes from 'prop-types'
import styles from './History.module.scss'
import { Subtitle, Title } from 'ui'
import HistoryItem from './HistoryItem'
import { InvoicingChange } from '../../components'

export const History = props => {
  const { history, onExport } = props

  const historyPerYear = []
  history.forEach(item => {
    const year = historyPerYear.find(i => i.label === item.year)
    if (year) year.list.push(item)
    else historyPerYear.push({ label: item.year, list: [item] })
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Title hLevel={2}>Historique des commandes</Title>
      </div>

      <InvoicingChange />

      <div className={styles.content}>
        {historyPerYear.map(year => (
          <div className={styles.year} key={year.label}>
            <div className={styles.subtitle}>
              <Subtitle hLevel={3}>{year.label}</Subtitle>
            </div>
            <div className={styles.list}>
              {year.list.map(item => (
                <HistoryItem
                  historyItem={item}
                  onExport={onExport}
                  key={year.label + item.month}
                />
              ))}
            </div>
          </div>
        ))}

        {historyPerYear.length === 0 && (
          <div className={styles.empty}>
            Aucun historique pour le moment.
            <br />
            Vous pourrez revenir le mois prochain pour avoir un compte-rendu des
            commandes du mois.
          </div>
        )}
      </div>
    </div>
  )
}

History.propTypes = {
  history: PropTypes.arrayOf(PropTypes.object),
  onExport: PropTypes.func,
}

History.defaultProps = {
  invoices: [],
  onExport: null,
}
