import React from 'react'
import Icon from './Icon'

export const IconStats = props => (
  <Icon {...props}>
    <path d="M3 14V22" />
    <path d="M9 12V22" />
    <path d="M15 8V22" />
    <path d="M21 2V22" />
  </Icon>
)
