import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Subheading } from 'ui'
import styles from './Message.module.scss'

export const Message = ({
  category,
  content,
  createdAt,
  name,
  type,
  profile,
  full,
}) => {
  let modifiers = ` ${styles[type]}`
  if (full) modifiers += ` ${styles._full}`
  if (profile === 2) modifiers += ` ${styles.admin}`

  return (
    <div className={`${styles.message} ${modifiers}`}>
      <div className={styles.content}>
        {category?.length > 0 && (
          <div>
            <Subheading>Sujet : {category}</Subheading>
            <Divider small />
          </div>
        )}

        <div>{content}</div>
      </div>
      <div className={styles.infos}>
        <span>{name}</span>
        <span>, le {createdAt}</span>
      </div>
    </div>
  )
}

Message.propTypes = {
  category: PropTypes.string,
  content: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['user', 'supplier', 'admin']),
  profile: PropTypes.number,
  full: PropTypes.bool,
}

Message.defaultProps = {
  type: 'user',
  full: false,
}
