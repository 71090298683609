import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { LayoutFixed, MenuSecondary } from 'ui'
import { SupplierNavigation } from 'features/suppliers/components'
import { getMenuLinks } from 'features/suppliers/configuration/@commons'
import { OffForm } from 'features/suppliers/configuration/components'
import { getOffConfiguration, saveOffConfiguration } from 'services/suppliers'
import { waitForApi } from 'utils/api'
import { NotificationManager } from 'services/notifications'

const ERROR_INTERNAL =
  'Une erreur interne est survenue, veuillez recharger la page pour obtenir des données à jour'

const SUCCESS_SAVE = 'Les informations sont sauvegardées'

export const FeatureSuppliersConfigurationOff = () => {
  const { uniqueKey } = useParams()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState(null)

  const loadData = useCallback(async () => {
    return waitForApi(getOffConfiguration(uniqueKey))
      .then(({ result }) => {
        setConfig(result.config)
        setLoading(false)
        return Promise.resolve()
      })
      .catch(() => {
        setLoading(false)
        setError(ERROR_INTERNAL)
        return Promise.resolve()
      })
  }, [uniqueKey])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onSubmit = async config => {
    setLoading(true)

    await waitForApi(saveOffConfiguration(uniqueKey, config))
      .then(loadData)
      .then(() => NotificationManager.addSuccess(SUCCESS_SAVE))
  }

  return (
    <LayoutFixed
      nav={
        <SupplierNavigation supplierKey={uniqueKey} title={'Configuration'} />
      }
      menu={<MenuSecondary links={getMenuLinks(uniqueKey)} />}
      title="Mise en pause"
      loading={loading}
      error={error}
    >
      <OffForm config={config} onSubmit={onSubmit} />
    </LayoutFixed>
  )
}
