import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class SupplierThreadPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      uniqueKey: null,
      threadKey: null,
      errors: [],
      shipping: {},
      messages: [],
      closed: false,
      replyContent: '',
      replyInternal: false,
    }
  }

  async retrieveThread() {
    let body = JSON.stringify({ uniqueKey: this.state.threadKey })

    let resp = await fetch(`${config.apiUrl}/api/support/thread`, {
      method: 'POST',
      body: body,
    })

    let data = await resp.json()

    if (data.success) {
      this.setState({
        messages: data.messages,
        shipping: data.shipping,
        closed: data.closed,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  replyContentChanged(replyContent) {
    this.setState({ replyContent })
  }

  replyInternalChanged(replyInternal) {
    this.setState({ replyInternal })
  }

  async sendReply() {
    let body = JSON.stringify({
      uniqueKey: this.state.threadKey,
      content: this.state.replyContent,
      internal: this.state.replyInternal,
    })

    let resp = await fetch(`${config.apiUrl}/api/suppliers/messages/reply`, {
      method: 'POST',
      body: body,
    })

    let data = await resp.json()

    if (data.success) {
      window.location.reload()
    } else {
      this.setState({ errors: data.errors })
    }
  }

  async closeThread() {
    let body = JSON.stringify({
      supplierKey: this.state.uniqueKey,
      threadKey: this.state.threadKey,
    })

    let resp = await fetch(`${config.apiUrl}/api/suppliers/messages/close`, {
      method: 'POST',
      body: body,
    })

    let data = await resp.json()

    if (data.success) {
      window.location.reload()
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new SupplierThreadPageStore()
export default store
