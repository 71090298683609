import React from 'react'
import { useParams } from 'react-router-dom'
import { ButtonLink, Card, LayoutFixed, Title } from 'ui'
import styles from './SupportContainer.module.scss'
import { SupplierNavigation } from './components'
import {
  SUPPLIERS_DOCUMENTS_URL,
  SUPPLIERS_PARTNERS_EMAIL,
} from 'services/suppliers'

export const FeatureSupplierSupport = () => {
  const { uniqueKey } = useParams()
  return (
    <LayoutFixed
      nav={<SupplierNavigation supplierKey={uniqueKey} title={'Services'} />}
      title="Services"
    >
      <div className={styles.wrapper}>
        <div className={styles.intro}>
          <div className={styles.title}>
            <Title>Services</Title>
          </div>
          <div className={styles.paragraph}>
            Retrouvez ci-dessous tous les services proposés par notre équipe
            Partenariat :
          </div>
        </div>
        <div className={styles.infos}>
          <div className={styles.documents}>
            <Card large light>
              <Title>Vos services</Title>
              <div className={styles.text}>
                Modifier ou proposer une offre, accéder aux documents du
                partenariat... Vous trouverez tous nos services sur le lien
                ci-dessous :
              </div>
              <ButtonLink to={SUPPLIERS_DOCUMENTS_URL} primary external>
                Accéder aux services
              </ButtonLink>
            </Card>
          </div>
          <div className={styles.contact}>
            <Card large light>
              <Title>Vos contacts</Title>
              <div className={styles.text}>
                Pour toute question ou problème, n'hésitez pas à nous contacter
                :
              </div>
              <div className={styles.btn}>
                <ButtonLink
                  to={`mailto:${SUPPLIERS_PARTNERS_EMAIL}`}
                  primary
                  external
                >
                  {SUPPLIERS_PARTNERS_EMAIL}
                </ButtonLink>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </LayoutFixed>
  )
}
