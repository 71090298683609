import api from 'utils/api'

const STOREFRONT_URL =
  'https://les-petites-boites.myshopify.com/api/2022-04/graphql.json'
const STOREFRONT_TOKEN = 'e9105e18a4c80642b9536faa6cfe4a7a'
const DESCRIPTION_SEPARATOR = '============'

let END_CURSOR = null
let START_CURSOR = null
const DEFAULT_SHOPIFY_VARIANT = 'Default Title'

const FILTER_THEME_PREFIX = 'Catégorie '
const FILTER_REGION_PREFIX = 'Région '

/**
 * EXTERNALS
 */

export const validCodes = params =>
  api.post('api/customers/redeem/valid', params)

export const getSupplier = params =>
  api.post('api/customers/redeem/getsupplier', params)

export const fillRedeem = params =>
  api.post('api/customers/redeem/fill', params)

export const saveRedeem = params =>
  api.post('api/customers/redeem/save', params)

export const getFirstPage = async params => {
  return getPage(getProductsQuery(true, params), {
    limit: params.limit,
  })
}

export const getNextPage = async params => {
  return getPage(getProductsQuery(true, params), {
    cursor: END_CURSOR,
    limit: params.limit,
  })
}

export const getPreviousPage = async params => {
  return getPage(getProductsQuery(false, params), {
    cursor: START_CURSOR,
    limit: params.limit,
  })
}

export const getFilters = async () => {
  const body = JSON.stringify({ query: getFiltersQuery() })
  const shopifyFilters = fetch(STOREFRONT_URL, getStorefrontApiParams(body))
    .then(result => result.json())
    .then(result => {
      if (result.errors?.length > 0) return Promise.reject(result.errors)

      return Promise.resolve(mapFiltersResult(result))
    })

  const appFilters = api.get('api/customers/redeem/getsuppliers')

  return Promise.all([shopifyFilters, appFilters]).then(result => {
    return { ...result[0], suppliers: result[1].result.suppliers }
  })
}

/**
 * INTERNALS
 */

const getStorefrontApiParams = body => {
  return {
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': STOREFRONT_TOKEN,
      'Content-Type': 'application/json',
    },
    body,
  }
}

const getPage = (query, variables) => {
  const body = JSON.stringify({ query, variables })
  return fetch(STOREFRONT_URL, getStorefrontApiParams(body))
    .then(result => result.json())
    .then(result => {
      if (result.errors?.length > 0) return Promise.reject(result.errors)

      END_CURSOR = result.data.products.pageInfo.endCursor
      START_CURSOR = result.data.products.pageInfo.startCursor

      return Promise.resolve(mapProductsResult(result))
    })
}
const getFiltersQuery = () => {
  return `{
    productTags(first: 250) {
      edges {
        node
      }
    }
  }`
}

const getProductsQuery = (next, { box, filters }) => {
  const position = next
    ? 'first: $limit, after: $cursor'
    : 'last: $limit, before: $cursor'

  const { themes, regions, suppliers } = filters
  let tags = `AND tag:'${box}'`
  if (themes.length > 0) {
    tags += ' AND ('
    themes.map(sanitizeFilter).forEach((f, i) => {
      tags += `tag:'${FILTER_THEME_PREFIX}${f}'`
      if (i < themes.length - 1) tags += ' OR '
    })
    tags += ')'
  }

  if (regions.length > 0) {
    tags += ' AND ('
    regions.map(sanitizeFilter).forEach((f, i) => {
      tags += `tag:'${FILTER_REGION_PREFIX}${f}'`
      if (i < regions.length - 1) tags += ' OR '
    })
    tags += ')'
  }

  let vendors = ''
  if (suppliers.length > 0) {
    vendors += ' AND ('
    suppliers.forEach((f, i) => {
      vendors += `vendor:'${f.id}'`
      if (i < suppliers.length - 1) vendors += ' OR '
    })
    vendors += ')'
  }

  return `query ($limit: Int!, $cursor: String){
    products(${position}, query:"product_type:'Coffret artisanal' ${tags} ${vendors}" sortKey:BEST_SELLING) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage 
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          vendor
          descriptionHtml
          featuredImage { url }
          variants(first: 60) {
            edges {
              node {
                id
                title
                image { url }
                selectedOptions { name, value }
              }
            }
          }
        }
      }
    }
  }`
}

const mapProductsResult = ({ data: { products } }) => {
  return {
    pagination: {
      hasPreviousPage: products.pageInfo.hasPreviousPage,
      hasNextPage: products.pageInfo.hasNextPage,
    },
    products: products.edges.map(({ node }) => ({
      ...node,
      descriptionHtml: node.descriptionHtml.split(DESCRIPTION_SEPARATOR)[0],
      moreinfoHtml:
        node.descriptionHtml.split(DESCRIPTION_SEPARATOR)[1] || null,
      featuredImage: node.featuredImage?.url || undefined,
      variants: node.variants.edges
        .map(({ node }) => ({
          ...node,
          id: node.id,
          image: node.image?.url,
        }))
        .filter(v => v.title !== DEFAULT_SHOPIFY_VARIANT),
    })),
  }
}

const mapFiltersResult = ({ data: { productTags } }) => {
  const themes = productTags.edges
    .map(e => e.node)
    .filter(n => n.startsWith(FILTER_THEME_PREFIX))
    .map(n => n.replace(FILTER_THEME_PREFIX, ''))

  const regions = productTags.edges
    .map(e => e.node)
    .filter(n => n.startsWith(FILTER_REGION_PREFIX))
    .map(n => n.replace(FILTER_REGION_PREFIX, ''))

  return { themes, regions }
}

const sanitizeFilter = value => value.replace("'", "\\\\'")
