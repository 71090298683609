import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CheckboxList, Loader, Subheading, TextField } from 'ui'
import {
  getFilters,
  setRegionFilters,
  setSupplierFilters,
  setThemeFilters,
} from 'features/customers/redeem/slice'

import styles from './Filters.module.scss'

const INTERNAL_ERROR = 'Une erreur est survenue'
const SUPPLIERS_LIMIT = 10

export const Filters = () => {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.redeem.filters)
  const currentFilters = useSelector(state => state.redeem.currentFilters)
  const [supplierFilter, setSupplierFilter] = useState('')
  const [themeFilter, setThemeFilter] = useState('')
  const [supplierLimit, setSupplierLimit] = useState(SUPPLIERS_LIMIT)

  // derived states
  const themes = filters.themes
    .filter(f => f.toLowerCase().includes(themeFilter))
    .map(f => ({ label: f, value: f }))

  const regions = filters.regions.map(f => ({ label: f, value: f }))

  const filteredSuppliers = filters.suppliers
    .filter(f => f.name.toLowerCase().includes(supplierFilter))
    .map(f => ({
      label: f.name,
      value: f.id.toString(),
    }))
  const suppliers = filteredSuppliers.slice(0, supplierLimit)

  const currentSuppliersIds = currentFilters.suppliers.map(s => s.id.toString())

  const hasMoreSuppliers =
    suppliers.length > 0 && suppliers.length < filteredSuppliers.length

  useEffect(() => {
    dispatch(getFilters())
  }, []) // eslint-disable-line

  const onSupplierFilterChange = ids => {
    const selectedSuppliers = ids.map(id =>
      filters.suppliers.find(s => s.id == id)
    )
    dispatch(setSupplierFilters(selectedSuppliers))
  }

  const modifiers = filters.loading ? styles._loading : ''

  return (
    <div className={`${styles.wrapper} ${modifiers}`}>
      <div className={styles.filter}>
        <div className={styles.title}>
          <Subheading>Nos thématiques</Subheading>
        </div>

        {filters.error && <div className={styles.error}>{INTERNAL_ERROR}</div>}

        <div className={styles.themeFilter}>
          <TextField
            id="ThemesFilter"
            label="Chercher une thématique"
            resetable
            onChange={v => setThemeFilter(v.toLowerCase())}
          />
        </div>

        <div className={styles.list}>
          <div className={styles.loader}>
            <Loader />
          </div>

          <CheckboxList
            id="Themes"
            options={themes}
            values={currentFilters.themes}
            onChange={v => dispatch(setThemeFilters(v))}
          />
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.title}>
          <Subheading>Nos régions</Subheading>
        </div>

        <div className={styles.list}>
          <div className={styles.loader}>
            <Loader />
          </div>

          {filters.error && (
            <div className={styles.error}>{INTERNAL_ERROR}</div>
          )}

          <CheckboxList
            id="Regions"
            options={regions}
            values={currentFilters.regions}
            onChange={v => dispatch(setRegionFilters(v))}
          />
        </div>
      </div>
      <div className={styles.filter}>
        <div className={styles.title}>
          <Subheading>Nos artisans</Subheading>
        </div>

        {filters.error && <div className={styles.error}>{INTERNAL_ERROR}</div>}

        <div className={styles.supplierFilter}>
          <TextField
            id="SuppliersFilter"
            label="Chercher un artisan"
            resetable
            onChange={v => setSupplierFilter(v.toLowerCase())}
          />
        </div>

        <div className={styles.list}>
          <div className={styles.loader}>
            <Loader />
          </div>

          <CheckboxList
            id="Suppliers"
            options={suppliers}
            values={currentSuppliersIds}
            onChange={onSupplierFilterChange}
          />
        </div>

        {hasMoreSuppliers && (
          <div className={styles.supplierMore}>
            <Button
              onClick={() => setSupplierLimit(supplierLimit + SUPPLIERS_LIMIT)}
            >
              Voir plus d'artisans
            </Button>
          </div>
        )}

        {suppliers.length === 0 && (
          <div className={styles.supplierEmpty}>Aucun artisan trouvé</div>
        )}
      </div>
    </div>
  )
}
