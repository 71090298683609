import dayjs from 'dayjs'

export const LOADER_MINIMUM_TIME = 350

export const getMonthName = month => {
  return new Date(month + '/1/1').toLocaleDateString('fr-fr', {
    month: 'long',
  })
}

export const debounce = (callback, wait) => {
  let timeout = null
  return (...args) => {
    const next = () => callback(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

export const formatDate = date => {
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, month, day].join('-')
}

export const formatToDate = (date, prefix = true) => {
  const res = dayjs(date)
  return prefix ? res.format('Le DD/MM/YYYY') : res.format('DD/MM/YYYY')
}

export const formatToDateAndHour = date => {
  return dayjs(date).format('Le DD/MM/YYYY à HH:mm')
}
