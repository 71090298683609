import React from 'react'
import PropTypes from 'prop-types'
import styles from './FileUpload.module.scss'
import { Button } from 'ui/components/Navigation/Button'

export class FileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      content: '',
      valid: false,
      validate: false,
    }

    this.inputFileRef = React.createRef()
    this.onButtonClick = this.onButtonClick.bind(this)
  }

  componentDidMount() {
    const { validate, initialValue } = this.props
    if (validate) this.setState({ validate: true })
    if (initialValue) this.setState({ name: initialValue })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.validate !== this.props.validate) {
      this.setState({ validate: this.props.validate })
    }

    if (prevProps !== this.props) {
      this.setState({
        valid: this.inputFileRef.current.validity.valid,
        value: this.inputFileRef.current.value,
      })
    }
  }

  getFileContent(file) {
    const promise = new Promise(resolve => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
    })

    return promise
  }

  onChange(files) {
    this.getFileContent(files[0]).then(c => {
      this.setState({
        name: files[0].name,
        content: c,
        valid: this.inputFileRef.current.validity.valid,
      })

      if (this.props.onChange) {
        this.props.onChange({
          name: this.state.name,
          content: this.state.content,
        })
      }
    })
  }

  onButtonClick(e) {
    e.preventDefault()
    this.inputFileRef.current.click()
  }

  render() {
    let modifiers = ''
    if (this.state.validate) {
      modifiers += this.state.valid
        ? ` ${styles._valid}`
        : ` ${styles._invalid}`
    }

    return (
      <div className={`${styles.upload}${modifiers}`}>
        <input
          type="file"
          ref={this.inputFileRef}
          className={styles.selector}
          accept={this.props.accept.join(',')}
          onChange={e => this.onChange(e.target.files)}
          required={this.props.required}
          disabled={this.props.disabled}
        />
        <Button {...this.props} onClick={this.onButtonClick} />
        {this.props.showName && (
          <span className={styles.name}>{this.state.name}</span>
        )}
      </div>
    )
  }
}

FileUpload.propTypes = {
  onChange: PropTypes.func,
  accept: PropTypes.arrayOf(PropTypes.string),
  showName: PropTypes.bool,
  validate: PropTypes.bool,
  initialValue: PropTypes.string,
  ...Button.propTypes,
}

FileUpload.defaultProps = {
  onChange: null,
  accept: [],
  showName: false,
  validate: false,
  initialValue: null,
  ...Button.defaultProps,
}
