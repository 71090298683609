import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactLink } from 'react-router-dom'
import styles from './Link.module.scss'

export const Link = props => {
  const args = { ...props }
  delete args.external
  delete args.to

  return props.external ? (
    <a href={props.to} {...args} className={styles.link}>
      {props.children}
    </a>
  ) : (
    <ReactLink to={props.to} {...args} className={styles.link}>
      {props.children}
    </ReactLink>
  )
}

Link.propTypes = {
  external: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

Link.defaultProps = {
  external: false,
}
