import React from 'react'
import { Logo } from 'ui'
import Errors from './MainMenu/Errors.js'
import { Link } from 'react-router-dom'
import './MainMenu.scss'

/*
 * Properties:
 *
 * * errors       String[]
 * * adminMenu    Boolean
 * * supplierMenu String   - the unique key of the supplier
 *
 */
export default class MainMenu extends React.Component {
  adminLinks() {
    if (this.props.adminMenu) {
      return [
        <Link key="orders" to="/a/orders">
          Commandes
        </Link>,
        <Link key="boxes" to="/a/boxes">
          Box
        </Link>,
        <Link key="suppliers" to="/a/suppliers">
          Artisans
        </Link>,
        <Link key="products" to="/a/products">
          Coffrets-Cadeaux
        </Link>,
        <Link key="themes" to="/a/themes">
          Thèmes
        </Link>,
        <Link key="support" to="/a/support">
          Support
        </Link>,
        <Link key="stats" to="/a/monitoring">
          Suivi
        </Link>,
        <Link key="reviews" to="/a/reviews">
          Avis
        </Link>,
        <Link key="users" to="/a/users">
          Utilisateurs
        </Link>,
        <Link key="account" to="/account">
          Mon Compte
        </Link>,
      ]
    }
  }

  supplierLinks() {
    if (this.props.supplierMenu) {
      return [
        <Link key="orders" to={`/suppliers/${this.props.supplierMenu}`}>
          Commandes
        </Link>,
        <Link
          key="messages"
          to={`/suppliers/${this.props.supplierMenu}/messages`}
        >
          Messagerie
        </Link>,
        <Link key="config" to={`/suppliers/${this.props.supplierMenu}/config`}>
          Configuration
        </Link>,
        <Link
          key="reviews"
          to={`/suppliers/${this.props.supplierMenu}/reviews`}
        >
          Avis clients
        </Link>,
        <Link key="account" to="/account">
          Mon Compte
        </Link>,
      ]
    }
  }

  render() {
    return (
      <div id="main-menu">
        <nav>
          <a id="logo" href="https://www.icipresent.com/">
            <Logo />
          </a>
          {this.adminLinks()}
          {this.supplierLinks()}
        </nav>
        <Errors messages={this.props.errors || []} />
      </div>
    )
  }
}
