import React from 'react'
import { ShippingIcon, IconClock, IconStats } from 'ui'
import { ShippingStates } from 'services/shippings'

export const getMenuLinks = (key, stats) => [
  {
    to: `/suppliers/${key}/shippings/received`,
    icon: <ShippingIcon status={ShippingStates.received.value} />,
    label: ShippingStates.received.plural,
    value: stats?.received,
    primary: true,
  },
  {
    to: `/suppliers/${key}/shippings/accepted`,
    icon: <ShippingIcon status={ShippingStates.accepted.value} />,
    label: ShippingStates.accepted.plural,
    value: stats?.accepted,
    primary: true,
  },
  { divider: true },
  {
    to: `/suppliers/${key}/shippings/sent`,
    icon: <ShippingIcon status={ShippingStates.sent.value} />,
    label: ShippingStates.sent.plural,
    value: stats?.sent,
  },
  {
    to: `/suppliers/${key}/shippings/delivered`,
    icon: <ShippingIcon status={ShippingStates.delivered.value} />,
    label: ShippingStates.delivered.plural,
  },
  {
    to: `/suppliers/${key}/shippings/canceled`,
    icon: <ShippingIcon status={ShippingStates.canceled.value} />,
    label: ShippingStates.canceled.plural,
  },
  {
    to: `/suppliers/${key}/shippings/forthcoming`,
    icon: <ShippingIcon status={ShippingStates.forthcoming.value} />,
    label: ShippingStates.forthcoming.plural,
    value: stats?.forthcoming || null,
  },
  { divider: true },
  {
    to: `/suppliers/${key}/shippings/history`,
    icon: <IconClock />,
    label: 'Historique',
  },
  {
    to: `/suppliers/${key}/shippings/stats`,
    icon: <IconStats />,
    label: 'Statistiques',
  },
]
