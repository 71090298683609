import React from 'react'
import styles from './Actions.module.scss'
import PropTypes from 'prop-types'

export const Actions = props => {
  return (
    <div className={styles.buttons}>
      <div className={styles.wrapper}>
        {props.left?.map((item, index) => (
          <div key={index} className={styles.item}>
            {item}
          </div>
        ))}
      </div>
      <div className={styles.wrapper}>
        {props.right?.map((item, index) => (
          <div key={index} className={styles.item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

Actions.prototype = {
  left: PropTypes.arrayOf(PropTypes.element),
  right: PropTypes.arrayOf(PropTypes.element),
}
