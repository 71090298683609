import React, { useState } from 'react'
import { SepaForm } from 'features/admin/sepa/components/SepaForm'
import { LayoutFixed, MenuPrimary } from 'ui'
import { waitForApi } from 'utils/api'
import { generateSepa } from 'services/invoices'
import { sendData } from 'download'
import { NotificationManager } from 'services/notifications'

const ERROR_INTERNAL =
  'Une erreur interne est survenue, veuillez recharger la page pour recommencer'

const SEPA_GENERATED = 'Le fichier sepa a été généré'

export function FeatureAdminGenerateSepa() {
  const [error, setError] = useState(null)
  const [loading, isLoading] = useState(false)

  const onDataSuccess = () => {
    setError(null)
    isLoading(false)
  }

  const onError = () => {
    setError(ERROR_INTERNAL)
    isLoading(false)
  }

  const onExportSepa = file => {
    isLoading(true)

    return waitForApi(generateSepa(file.content))
      .then(onExportSepaException)
      .then(onExportSepaSuccess)
      .then(onDataSuccess)
      .catch(onError)
  }

  const onExportSepaSuccess = data => {
    isLoading(false)
    sendData(data.result.file, 'text/xml', `${data.result.name}.xml`)
    NotificationManager.addSuccess(SEPA_GENERATED)
  }

  const onExportSepaException = data => {
    if (data.exceptions?.length > 0) {
      data.exceptions.map(e => NotificationManager.addError(e))
      return Promise.reject()
    }
    return Promise.resolve(data)
  }

  return (
    <LayoutFixed
      nav={<MenuPrimary />}
      loading={loading}
      error={error}
      title="Conversion sepa"
    >
      <SepaForm onExport={onExportSepa} />
    </LayoutFixed>
  )
}
