import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  IconChevronLeft,
  IconChevronRight,
  IconClose,
  Modal,
  Rating,
  Selectbox,
  WidgetReviews,
  Loader,
} from 'ui'
import {
  addToSelection,
  setCurrentProduct,
  getNext,
  getPrevious,
  getCurrentVariantsByName,
  setCurrentVariant,
  removeCurrentProduct,
  setSelection,
  setModalOpened,
} from 'features/customers/redeem/slice'
import { wait } from 'utils/promise'
import { LOADER_MINIMUM_TIME } from 'utils/time'
import { goToAnchor } from 'utils/route'

import styles from './Modal.module.scss'

const ANIMATION_DURATION = 321

export const ProductModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const modalOpened = useSelector(state => state.redeem.modalOpened)
  const box = useSelector(state => state.redeem.box.item)
  const selection = useSelector(state => state.redeem.selection)
  const products = useSelector(state => state.redeem.products.list)
  const pagination = useSelector(state => state.redeem.products.pagination)
  const variantsByName = useSelector(getCurrentVariantsByName)
  const product = useSelector(state => state.redeem.currentProduct)
  const variant = useSelector(state => state.redeem.currentVariant)
  const [loading, setLoading] = useState(false)
  const [pictureLoading, setPictureLoading] = useState(false)
  const [activeReviews, setActiveReviews] = useState(false)
  const [prevClicked, setPrevClicked] = useState(false)

  const index = products.findIndex(p => p.id === product?.id)
  const isInList = index !== -1
  const hasPrev = index !== 0 || pagination?.hasPreviousPage
  const hasNext = index !== products.length - 1 || pagination?.hasNextPage
  const title = `${product?.title || ''} ${variant?.title || ''}`
  const picture =
    variant && variant.image ? variant.image : product?.featuredImage
  const hasShippingInfo =
    product?.vendorInfos.info.length > 0 ||
    product?.vendorInfos.shippingTime > 0

  useEffect(() => {
    if (product && products.length > 0) {
      if (prevClicked) {
        dispatch(setCurrentProduct({ product: products[products.length - 1] }))
      } else dispatch(setCurrentProduct({ product: products[0] }))
    }
  }, [products]) //eslint-disable-line

  useEffect(() => {
    setLoading(false)
    setActiveReviews(false)
    setTimeout(() => {
      const modal = document.getElementById('Modal')
      if (!modal) return
      modal.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }, [product])

  const onPrevClick = () => {
    setLoading(true)
    setPrevClicked(true)
    if (index > 0) dispatch(setCurrentProduct({ product: products[index - 1] }))
    else dispatch(getPrevious({ box: box?.tag }))
  }

  const onNextClick = () => {
    setLoading(true)
    setPrevClicked(false)
    if (index < products.length - 1)
      dispatch(setCurrentProduct({ product: products[index + 1] }))
    else dispatch(getNext({ box: box?.tag }))
  }

  const onVariantChange = async (name, value) => {
    setPictureLoading(true)

    // replace the changed option
    const newOptions = [...variant?.selectedOptions].filter(
      o => o.name !== name
    )
    newOptions.push({ name, value })

    // set the new current variant
    const v = findVariantByOptions(product?.variants, newOptions)

    await wait(ANIMATION_DURATION) // waiting for end of fading animation
    dispatch(setCurrentVariant(v))
  }

  const onSeeReviewsClick = e => {
    e.preventDefault()

    setActiveReviews(true)
    setTimeout(() => {
      goToAnchor('Reviews')
    }, 0) // start scrolling once the reviews are actived
  }

  const onPictureLoaded = async () => {
    await wait(LOADER_MINIMUM_TIME)
    setPictureLoading(false)
  }

  const onCloseModal = async () => {
    dispatch(setModalOpened(false))
    await wait(ANIMATION_DURATION)
    dispatch(removeCurrentProduct())
  }

  const getLeftActions = () => {
    const actions = []

    isInList &&
      actions.push(
        <Button
          icon={<IconChevronLeft />}
          hideLabelOnMobile={true}
          disabled={!hasPrev}
          onClick={onPrevClick}
        >
          Précédent
        </Button>
      )

    isInList &&
      actions.push(
        <Button
          icon={<IconChevronRight />}
          hideLabelOnMobile={true}
          disabled={!hasNext}
          onClick={onNextClick}
        >
          Suivant
        </Button>
      )

    return actions
  }

  const getRightActions = () => {
    const actions = []

    if (box?.remainingGifts > 1) {
      actions.push(
        <Button
          primary
          disabled={selection.length === box?.remainingGifts}
          onClick={async () => {
            dispatch(addToSelection({ product, variant }))
            dispatch(setModalOpened(false))
            await wait(ANIMATION_DURATION)
            dispatch(removeCurrentProduct())
          }}
        >
          Sélectionner
        </Button>
      )
    } else {
      actions.push(
        <Button
          primary
          onClick={async () => {
            dispatch(setSelection([{ product, variant }]))
            dispatch(setModalOpened(false))
            await wait(ANIMATION_DURATION)
            dispatch(setCurrentProduct(null))
            !box.redeemed && navigate('/redeem/information')
          }}
        >
          Choisir ce produit
        </Button>
      )
    }

    return actions
  }

  let modifiers = loading ? styles._loading : ''
  if (pictureLoading) modifiers += ' ' + styles._pictureLoading

  return (
    <Modal
      opened={modalOpened}
      nopadding
      onClose={onCloseModal}
      leftActions={getLeftActions()}
      actions={getRightActions()}
    >
      <div id="Modal" className={`${styles.wrapper} ${modifiers}`}>
        <div className={styles.loader}>
          <Loader />
        </div>
        <div className={styles.product}>
          <button className={styles.close} onClick={onCloseModal}>
            <IconClose
              id={'ModalClose'}
              label={'Fermer la fenêtre'}
              stroke={'#fff'}
            />
          </button>
          {picture && (
            <div className={styles.header}>
              <div className={styles.pictureLoader}>
                <Loader opposite={true} />
              </div>
              <img className={styles.imgBg} src={picture} alt={title} />

              <img
                className={styles.img}
                src={picture}
                alt={title}
                onLoad={onPictureLoaded}
              />
            </div>
          )}
          {product && (
            <div className={styles.content}>
              <div className={styles.inner}>
                <div className={styles.infos}>
                  <div className={styles.title}>{title}</div>
                  <div>par {product.vendorInfos?.name}</div>
                  {product.vendorInfos?.rating && (
                    <div>
                      <Rating
                        value={product.vendorInfos?.rating}
                        count={product.vendorInfos?.count}
                        color="#58483e"
                      />
                      <a
                        href="#Reviews"
                        className={styles.reviewsLink}
                        onClick={onSeeReviewsClick}
                      >
                        Voir les avis
                      </a>
                    </div>
                  )}
                </div>
                {variantsByName.length > 0 && (
                  <div className={styles.variants}>
                    {variantsByName.map(v => (
                      <div key={v.name} className={styles.variant}>
                        <Selectbox
                          id={v.name}
                          label={v.name}
                          initialValue={getCurrentOptionValue(variant, v.name)}
                          values={v.options}
                          onChange={value => onVariantChange(v.name, value)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: product.descriptionHtml,
                }}
              />

              {hasShippingInfo && (
                <div className={styles.shipping}>
                  <h2 className={styles.subtitle}>Informations de livraison</h2>
                  <div>
                    Expédié sous {product.vendorInfos.shippingTime}{' '}
                    {product.vendorInfos.shippingTime > 1
                      ? 'jours ouvrés'
                      : 'jour ouvré'}
                  </div>
                  {product.vendorInfos.info.length > 0 && (
                    <div>{product.vendorInfos.info}</div>
                  )}
                </div>
              )}

              {product.moreinfoHtml && (
                <div className={styles.moreinfo}>
                  <h2 className={styles.subtitle}>Plus d'informations</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product.moreinfoHtml,
                    }}
                  />
                </div>
              )}
              <div
                id="Reviews"
                className={`${styles.reviews} ${
                  activeReviews ? styles._active : ''
                }`}
              >
                {activeReviews && product.vendorInfos?.uniqueKey && (
                  <div>
                    <h2 className={styles.reviewsTitle}>
                      Les avis de {product.vendorInfos?.name}
                    </h2>
                    <WidgetReviews
                      supplierKey={product.vendorInfos?.uniqueKey}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

function getCurrentOptionValue(variant, name) {
  return variant?.selectedOptions.find(v => v.name === name)?.value || null
}

function findVariantByOptions(list, options) {
  return list.find(item => {
    return options.reduce((acc, option) => {
      const selectedOption = item.selectedOptions.find(
        so => so.name === option.name
      )
      return acc && selectedOption?.value === option.value
    }, true)
  })
}
