import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class EditThemeStore extends BaseStore {
  setDefaults() {
    this.state = {
      id: 0,
      errors: [],
      name: '',
      imageUrl: '',
    }
  }

  async load(id) {
    id = parseInt(id, 10)
    let params = { id }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/themes/info`, {
      method: 'POST',
      body,
    })

    let data = await resp.json()

    if (data.success) {
      let { name, imageUrl } = data
      this.setState({
        id,
        name,
        imageUrl,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  nameChanged(name) {
    this.setState({ name })
  }

  imageUrlChanged(imageUrl) {
    this.setState({ imageUrl })
  }

  async submit() {
    let params = {
      id: this.state.id,
      name: this.state.name,
      imageUrl: this.state.imageUrl,
    }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/themes/update`, {
      method: 'POST',
      body,
    })

    let data = await resp.json()

    if (data.success) {
      alert('Le thème a bien été mis à jour.')
      window.location.href = '/a/themes'
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new EditThemeStore()
export default store
