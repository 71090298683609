import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import store from 'stores/Admin/UsersStore.js'
import User from './UsersPage/User.js'
import Pagination from 'components/Pagination'
import UrlParser from 'urlParser'
import './UsersPage.scss'

export default class UsersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
    let url = new UrlParser()
    let params = url.getParams()
    let page = parseInt(params.page || 1)
    let search = params.search
    store.load(page, search)
  }

  componentWillUnmount() {
    store.unsubscribe(this.updated)
  }

  pageChanged(page) {
    let url = new UrlParser()
    let params = url.getParams()
    params.page = page
    url.setParams(params)
    window.location.href = url.toString()
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  users() {
    return this.state.users.map(u => <User key={u.email} {...u} />)
  }

  pagination() {
    if (this.state.pageCount > 1) {
      return (
        <Pagination
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          onChange={page => this.pageChanged(page)}
        />
      )
    }
  }

  render() {
    return (
      <div className="page admin-page" id="UsersPage">
        <Helmet>
          <title>Utilisateurs - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />
        <div className="group admin-list">
          <h1 className="h1">Liste des utilisateurs</h1>

          <div id="users" className="feature">
            <form>
              <input
                type="text"
                name="search"
                className="text-field"
                placeholder="Recherche…"
                value={this.state.search}
                onChange={e => store.searchChanged(e.target.value)}
              />
              <button className="btn">Chercher</button>
            </form>

            <div>
              <a href="/a/users/new" id="new-user">
                Nouvel utilisateur
              </a>
              {this.pagination()}
            </div>

            {this.users()}
            {this.pagination()}
          </div>
        </div>
      </div>
    )
  }
}
