import React from 'react'
import { IconCalendar, IconComment } from 'ui'

export const MENU_LINKS = [
  {
    to: `/a/support/messages`,
    icon: <IconComment />,
    label: `Messagerie`,
    primary: true,
  },
  {
    to: `/a/support/messages/unanswered`,
    label: `Sans réponse`,
  },
  {
    to: `/a/support/messages/unresolved`,
    label: `Non résolus`,
  },
  {
    to: `/a/support/prolongation`,
    icon: <IconCalendar />,
    label: `Prolongation`,
    primary: true,
  },
  { divider: true },
]
