import React from 'react'
import { FeatureAdminReviewsList } from './List'

export const FeatureAdminReviewsQuarantine = props => (
  <FeatureAdminReviewsList
    {...props}
    quarantine={true}
    title="Avis clients en quarantaine"
  />
)
