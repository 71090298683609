import { useDispatch, useSelector } from 'react-redux'
import { Badge, IconClose } from 'ui'
import {
  removeRegionFilter,
  removeSupplierFilter,
  removeThemeFilter,
} from 'features/customers/redeem/slice'

import styles from './CurrentFilters.module.scss'

export const CurrentFilters = () => {
  const dispatch = useDispatch()
  const currentFilters = useSelector(state => state.redeem.currentFilters)

  return (
    <div className={styles.wrapper}>
      {currentFilters.themes.map(f => (
        <div
          key={f}
          className={styles.filter}
          onClick={() => dispatch(removeThemeFilter(f))}
        >
          <Badge small label={f} icon={<IconClose />} />
        </div>
      ))}

      {currentFilters.regions.map(f => (
        <div
          key={f}
          className={styles.filter}
          onClick={() => dispatch(removeRegionFilter(f))}
        >
          <Badge small label={f} icon={<IconClose />} />
        </div>
      ))}

      {currentFilters.suppliers.map(f => (
        <div
          key={f.id}
          className={styles.filter}
          onClick={() => dispatch(removeSupplierFilter(f.id))}
        >
          <Badge small label={f.name} icon={<IconClose />} />
        </div>
      ))}
    </div>
  )
}
