import { Button, Modal } from 'ui'
import styles from './FormExportModal.module.scss'
import { waitForApi } from 'utils/api'
import {
  exportShipping,
  exportChronopostShipping,
  ShippingStates,
} from 'services/shippings'
import { sendData } from 'download'
import { NotificationManager } from 'services/notifications'
import { MIME_CSV, MIME_XLS } from 'utils/file'

const EXPORT_SUCCESS = 'Les données ont été exportées'
const EXPORT_ERROR = 'Une erreur est survenue'

export const FormExportModal = ({
  opened,
  supplierKey,
  status,
  onClose,
  search,
  hasChronopost,
}) => {
  const launchExport = (callback, prefix, type, ext) => {
    return waitForApi(callback(supplierKey, status, search))
      .then(({ result }) => {
        const filename = getFilename(prefix, status, search, ext)
        sendData(result.file, type, filename)
        onClose()
        NotificationManager.addSuccess(EXPORT_SUCCESS)
      })
      .catch(() => NotificationManager.addError(EXPORT_ERROR))
  }
  const actions = []

  if (hasChronopost) {
    actions.push(
      <Button
        onClick={() =>
          launchExport(exportChronopostShipping, 'Chronopost', MIME_CSV, 'csv')
        }
      >
        Format Chronopost
      </Button>
    )
  }

  actions.push(
    <Button
      primary
      onClick={() =>
        launchExport(exportShipping, 'IciPresent', MIME_XLS, 'xlsx')
      }
    >
      Format Ici Présent!
    </Button>
  )

  return (
    <Modal
      opened={opened}
      closable={true}
      title={'Exporter les commandes'}
      onClose={onClose}
      actions={actions}
    >
      <div className={styles.modal}>
        <div className={styles.subtitle}>
          Sélectionnez le format d'export que vous souhaitez.
        </div>
      </div>
    </Modal>
  )
}

const getFilename = (prefix, status, { query, dateMin, dateMax }, ext) => {
  let infos = ''
  if (query.length > 0) infos += '-filtrées'
  if (dateMin) infos += `-min-${dateMin.replace(/\//g, '-')}`
  if (dateMax) infos += `-max-${dateMax.replace(/\//g, '-')}`
  return `${prefix}-${ShippingStates[status].plural}${infos}.${ext}`
}
