import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { initialState } from 'features/customers/redeem/state'
import {
  getFirstPage,
  getNextPage,
  getPreviousPage,
  validCodes as srvValidCodes,
  fillRedeem as srvFillRedeem,
  saveRedeem as srvSaveRedeem,
  getFilters as srvGetFilters,
  getSupplier,
} from 'services/redeem'
import { waitForApi } from 'utils/api'

// Async thunks

export const validCodes = createAsyncThunk(
  'redeem/validCodes',
  async (params, { rejectWithValue }) => {
    try {
      const res = await waitForApi(srvValidCodes(params))
      if (res.exceptions?.length > 0) return rejectWithValue(res.exceptions)
      return res.result
    } catch (err) {
      return rejectWithValue()
    }
  }
)

export const getFilters = createAsyncThunk('redeem/getFilters', async () =>
  waitForApi(srvGetFilters())
)

export const getFirst = createAsyncThunk(
  'redeem/getFirst',
  async (params, { getState }) =>
    waitForApi(
      getFirstPage({
        ...params,
        limit: getState().redeem.products.limit,
        filters: getState().redeem.currentFilters,
      })
    )
)

export const getNext = createAsyncThunk(
  'redeem/getNext',
  async (params, { getState }) =>
    waitForApi(
      getNextPage({
        ...params,
        limit: getState().redeem.products.limit,
        filters: getState().redeem.currentFilters,
      })
    )
)

export const getPrevious = createAsyncThunk(
  'redeem/getPrevious',
  async (params, { getState }) =>
    waitForApi(
      getPreviousPage({
        ...params,
        limit: getState().redeem.products.limit,
        filters: getState().redeem.currentFilters,
      })
    )
)

export const saveRedeem = createAsyncThunk(
  'redeem/save',
  async (_, { getState, rejectWithValue }) => {
    try {
      const params = mapStateToSaveParams(getState().redeem)
      const res = await waitForApi(srvSaveRedeem(params))
      if (res.exceptions?.length > 0) return rejectWithValue(res.exceptions)
      return res.result.redirectUrl
    } catch (err) {
      return rejectWithValue()
    }
  }
)

export const fillRedeem = createAsyncThunk(
  'redeem/fill',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { box, selection } = mapStateToSaveParams(getState().redeem)
      const res = await waitForApi(srvFillRedeem({ box, selection }))
      if (res.exceptions?.length > 0) return rejectWithValue(res.exceptions)
      return res.result.redirectUrl
    } catch (err) {
      return rejectWithValue()
    }
  }
)

export const setCurrentProduct = createAsyncThunk(
  'redeem/setCurrentProduct',
  async (params, { getState, rejectWithValue }) => {
    try {
      if (params === null) return Promise.resolve()

      const vendorId = parseInt(params.product?.vendor)
      const vendors = getState().redeem.vendors
      const vendorInfos = vendors.find(v => v.id === vendorId)
      if (vendorInfos)
        return Promise.resolve({
          product: { ...params.product, vendorInfos },
          variant: params.variant,
        })

      const res = await waitForApi(getSupplier({ id: vendorId }))
      if (res.errors) return rejectWithValue(res.errors)
      return {
        product: { ...params.product, vendorInfos: res.result },
        variant: params.variant,
      }
    } catch (err) {
      return rejectWithValue()
    }
  }
)

// Extra reducers

const validCodesReducer = (state, { payload }) => {
  state.box.item = payload.box || null
  state.box.redirectUrl = payload.redirectUrl || null
  state.box.loading = false
  state.box.error = false
}

const validCodesErrorReducer = (state, { payload }) => {
  state.box.error = payload?.length > 0 ? false : true
  state.box.exceptions = payload?.length > 0 ? payload : []
  state.box.loading = false
}

const validCodesPendingReducer = state => {
  state.box.loading = true
  state.box.error = false
}

const getPageReducer = (state, action) => {
  state.products.pagination = action.payload.pagination
  state.products.list = action.payload.products
  state.products.loading = false
  state.products.error = false
}

const getPageErrorReducer = state => {
  state.products.error = true
  state.products.loading = false
}

const getPagePendingReducer = state => {
  state.products.error = false
  state.products.loading = true
  state.products.error = false
}

const saveReducer = (state, action) => {
  // Reset states
  state.box = { ...initialState.box }
  state.selection = []
  state.information = { ...initialState.information }
  state.save = { ...initialState.save }

  state.save.url = action.payload
}

const saveErrorReducer = (state, { payload }) => {
  state.save.error = payload?.length > 0 ? false : true
  state.save.exceptions = payload?.length > 0 ? payload : []
  state.save.loading = false
}

const savePendingReducer = state => {
  state.save = { ...initialState.save }
  state.save.loading = true
}

const setCurrentProductReducer = (state, { payload }) => {
  if (!payload) {
    state.currentProduct = null
    state.currentVariant = null
    return
  }

  state.currentProduct = payload.product
  state.currentVariant = payload.variant || payload.product.variants[0] || null
  state.modalOpened = true

  const index = state.vendors.findIndex(
    v => v.id === payload.product.vendorInfos.id
  )
  if (index === -1) state.vendors.push(payload.product.vendorInfos)
}

const getFiltersReducer = (state, { payload }) => {
  state.filters = { ...initialState.filters }
  state.filters.themes = payload.themes
  state.filters.regions = payload.regions
  state.filters.suppliers = payload.suppliers
}

const getFiltersErrorReducer = state => {
  state.filters = { ...initialState.filters }
  state.filters.error = true
}

const getFiltersPendingReducer = state => {
  state.filters = { ...initialState.filters }
  state.filters.loading = true
}

// Extra selectors

export const getCurrentVariantsByName = createSelector(
  state => state.redeem.currentProduct,
  product => {
    return (
      product?.variants?.reduce((acc, { selectedOptions }) => {
        selectedOptions.forEach(({ name, value }) => {
          const index = acc.findIndex(a => a.name === name)
          if (index === -1) {
            acc.push({ name, options: [value] })
          } else if (!acc[index].options.includes(value)) {
            acc[index].options.push(value)
          }
        })
        return acc
      }, []) || []
    )
  }
)

// Slice

export const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    addToSelection: (state, { payload }) => {
      if (
        state.box.item?.gifts &&
        state.selection.length < state.box.item.gifts
      ) {
        state.selection.push(payload)
      }
    },
    removeFromSelection: ({ selection }, { payload }) => {
      const index = selection.findIndex(s => s.product.id === payload)
      if (index > -1) {
        selection = selection.splice(index, 1)
      }
    },
    setSelection: (state, { payload }) => {
      if (state.box.item?.gifts && payload.length <= state.box.item.gifts) {
        state.selection = [...payload]
      }
    },
    setModalOpened: (state, { payload }) => {
      state.modalOpened = payload
    },
    setInformation: (state, { payload }) => {
      state.information = payload
    },
    setCurrentVariant: (state, { payload }) => {
      state.currentVariant = payload
    },
    removeCurrentProduct: state => {
      state.currentProduct = null
      state.currentVariant = null
    },
    setThemeFilters: (state, { payload }) => {
      state.currentFilters.themes = payload
    },
    removeThemeFilter: (state, { payload }) => {
      state.currentFilters.themes = state.currentFilters.themes.filter(
        f => f !== payload
      )
    },
    setRegionFilters: (state, { payload }) => {
      state.currentFilters.regions = payload
    },
    removeRegionFilter: (state, { payload }) => {
      state.currentFilters.regions = state.currentFilters.regions.filter(
        f => f !== payload
      )
    },
    setSupplierFilters: (state, { payload }) => {
      state.currentFilters.suppliers = payload
    },
    removeSupplierFilter: (state, { payload }) => {
      state.currentFilters.suppliers = state.currentFilters.suppliers.filter(
        f => f.id !== payload
      )
    },
  },
  extraReducers: builder => {
    builder.addCase(validCodes.fulfilled, validCodesReducer)
    builder.addCase(validCodes.rejected, validCodesErrorReducer)
    builder.addCase(validCodes.pending, validCodesPendingReducer)

    builder.addCase(getFirst.fulfilled, getPageReducer)
    builder.addCase(getFirst.pending, getPagePendingReducer)
    builder.addCase(getFirst.rejected, getPageErrorReducer)

    builder.addCase(getNext.fulfilled, getPageReducer)
    builder.addCase(getNext.pending, getPagePendingReducer)
    builder.addCase(getNext.rejected, getPageErrorReducer)

    builder.addCase(getPrevious.fulfilled, getPageReducer)
    builder.addCase(getPrevious.pending, getPagePendingReducer)
    builder.addCase(getPrevious.rejected, getPageErrorReducer)

    builder.addCase(fillRedeem.fulfilled, saveReducer)
    builder.addCase(fillRedeem.rejected, saveErrorReducer)
    builder.addCase(fillRedeem.pending, savePendingReducer)

    builder.addCase(saveRedeem.fulfilled, saveReducer)
    builder.addCase(saveRedeem.rejected, saveErrorReducer)
    builder.addCase(saveRedeem.pending, savePendingReducer)

    builder.addCase(getFilters.fulfilled, getFiltersReducer)
    builder.addCase(getFilters.rejected, getFiltersErrorReducer)
    builder.addCase(getFilters.pending, getFiltersPendingReducer)

    builder.addCase(setCurrentProduct.fulfilled, setCurrentProductReducer)
  },
})

const mapStateToSaveParams = state => {
  const box = { id: state.box.item?.id, code: state.box.item?.code }
  const selection = state.selection.map(s => {
    const title = `${s.product.title}${s.variant ? ` ${s.variant.title}` : ''}`
    const image =
      s.variant && s.variant.image ? s.variant.image : s.product.featuredImage

    return {
      title,
      image,
      supplierID: parseInt(s.product.vendor),
    }
  })
  const information = state.information

  return { box, selection, information }
}

export const {
  addToSelection,
  removeFromSelection,
  setSelection,
  setModalOpened,
  setInformation,
  setCurrentVariant,
  removeCurrentProduct,
  setThemeFilters,
  setRegionFilters,
  removeThemeFilter,
  removeRegionFilter,
  setSupplierFilters,
  removeSupplierFilter,
} = redeemSlice.actions

export default redeemSlice.reducer
