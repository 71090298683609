import React from 'react'
import UrlParser from 'urlParser'

/*
 * Properties:
 *
 * * products     Object[] (list of existing products)
 * * redeemed       Number
 * * deliveryMethod Number
 * * plan           Number
 * * product       Number
 *
 */
export default class Filters extends React.Component {
  redeemedChanged(val) {
    let url = new UrlParser()
    let params = url.getParams()
    params.redeemed = val
    params.page = 1
    url.setParams(params)
    window.location.href = url.toString()
  }

  deliveryMethodChanged(val) {
    let url = new UrlParser()
    let params = url.getParams()
    params.deliveryMethod = val
    params.page = 1
    url.setParams(params)
    window.location.href = url.toString()
  }

  planChanged(val) {
    let url = new UrlParser()
    let params = url.getParams()
    params.plan = val
    params.page = 1
    url.setParams(params)
    window.location.href = url.toString()
  }

  productChanged(val) {
    let url = new UrlParser()
    let params = url.getParams()
    params.product = val
    params.page = 1
    url.setParams(params)
    window.location.href = url.toString()
  }

  products() {
    return this.props.products.map(product => {
      return (
        <option key={product.id} value={product.id}>
          {product.name}
        </option>
      )
    })
  }

  render() {
    return (
      <div id="admin-boxes-filters">
        <select
          className="text-field"
          value={this.props.redeemed}
          onChange={e => this.redeemedChanged(e.target.value)}
        >
          <option value={99}>-- Redeemed --</option>
          <option value={0}>Non</option>
          <option value={1}>Oui</option>
        </select>

        <select
          className="text-field"
          value={this.props.deliveryMethod}
          onChange={e => this.deliveryMethodChanged(e.target.value)}
        >
          <option value={99}>-- Type livraison --</option>
          <option value={0}>E-Coffret</option>
          <option value={1}>Coffret Physique</option>
        </select>

        <select
          className="text-field"
          value={this.props.plan}
          onChange={e => this.planChanged(e.target.value)}
        >
          <option value={99}>-- Plan --</option>
          <option value={0}>1 box</option>
          <option value={1}>2 box</option>
          <option value={2}>3 box</option>
          <option value={3}>4 box</option>
          <option value={4}>5 box</option>
          <option value={5}>6 box</option>
        </select>

        <select
          className="text-field"
          value={this.props.product}
          onChange={e => this.productChanged(e.target.value)}
        >
          <option value={99}>-- Produit --</option>
          {this.products()}
        </select>
      </div>
    )
  }
}
