import React from 'react'
import config from 'config'
import Loading from 'components/Loading'
import { fetch } from 'utils/api'

export default class ShopifyImporter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      products: [],
      selectedProduct: null,
      loading: false,
    }
  }

  async load(e) {
    e.preventDefault()
    this.setState({ loading: true })
    let resp = await fetch(`${config.apiUrl}/api/admin/shopify/importvariants`)
    let products = (await resp.json()).products
    this.setState({
      products,
      selectedProduct: products[0],
      show: true,
      loading: false,
    })
  }

  productChanged(title) {
    let selectedProduct = this.state.products.find(p => p.title === title)
    this.setState({ selectedProduct })
  }

  import(e) {
    e.preventDefault()
    let shopifyProducts = [
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
    ]

    this.state.selectedProduct.variants.forEach(variant => {
      let deliveryMethod = variant.requires_shipping ? 1 : 0
      let planMatch = variant.title.match(/\d+/)
      if (planMatch) {
        let plan = parseInt(planMatch[0], 10)
        shopifyProducts[deliveryMethod][plan - 1] = variant.id
      }
    })

    this.props.store.setState({ shopifyProducts })
  }

  productOptions() {
    return this.state.products.map(p => (
      <option key={p.title}>{p.title}</option>
    ))
  }

  form() {
    return (
      <p>
        <select
          onChange={e => this.productChanged(e.target.value)}
          value={this.state.selectedProduct.title}
        >
          {this.productOptions()}
        </select>
        &nbsp;
        <button className="btn secondary" onClick={e => this.import(e)}>
          Importer
        </button>
      </p>
    )
  }

  render() {
    if (this.state.show) {
      return this.form()
    } else {
      if (this.state.loading) {
        return (
          <p>
            <Loading />
          </p>
        )
      } else {
        return (
          <p>
            <button onClick={e => this.load(e)} className="secondary btn">
              Import from shopify
            </button>
          </p>
        )
      }
    }
  }
}
