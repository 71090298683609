import React from 'react'
import PropTypes from 'prop-types'
import { MenuPrimary } from 'ui'

export const AdminNavigation = props => {
  const links = [
    { to: `/a/orders`, label: 'Commandes' },
    { to: `/a/boxes`, label: 'Box' },
    { to: `/a/suppliers`, label: 'Artisans' },
    { to: `/a/products`, label: 'Coffrets-Cadeaux' },
    { to: `/a/themes`, label: 'Thèmes' },
    { to: `/a/support`, label: 'Support' },
    { to: `/a/monitoring`, label: 'Suivi' },
    { to: `/a/reviews`, label: 'Avis' },
    { to: `/a/users`, label: 'Utilisateurs' },
    { to: `/account`, label: 'Mon Compte' },
  ]

  return <MenuPrimary logoUrl={'/a'} links={links} title={props.title} />
}

AdminNavigation.propTypes = {
  title: PropTypes.string,
}

AdminNavigation.defaultProps = {
  title: null,
}
