import React from 'react'
import Icon from './Icon'

export const IconCart = props => (
  <Icon {...props}>
    <path d="M1 2H3.39566C4.33305 2 5.14468 2.65106 5.34803 3.56614L6 6.5M6 6.5L7.10424 12.5733C7.62296 15.4263 10.1077 17.5 13.0075 17.5H15.7106C18.512 17.5 20.9406 15.5615 21.5614 12.8297L22.5835 8.33243C22.7969 7.39379 22.0834 6.5 21.1208 6.5H6Z" />
    <circle cx="8.5" cy="21" r="1" />
    <circle cx="20.5" cy="21" r="1" />
  </Icon>
)
