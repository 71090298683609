import api from 'utils/api'

export const getPurchasesInfos = year =>
  api.post('api/admin/monitoring/purchases', { year })

export const getSalesInfos = year =>
  api.post('api/admin/monitoring/sales', { year })

export const exportPurchasesInfos = body =>
  api.post(`api/admin/monitoring/purchasesexport`, body)

export const exportSalesInfos = body =>
  api.post(`api/admin/monitoring/salesexport`, body)
