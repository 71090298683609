import BaseStore from 'stores/BaseStore'
import config from 'config.js'
import { fetch } from 'utils/api'

class PasswordResetPageStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      key: '',
      password: '',
    }
  }

  load(key) {
    this.setState({ key })
  }

  passwordChanged(password) {
    this.setState({ password })
  }

  async save() {
    let params = {
      key: this.state.key,
      password: this.state.password,
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/account/password/reset`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      alert(
        "Mot de passe mis à jour, vous pouvez maintenant l'utiliser pour vous connecter."
      )
      window.location.href = '/login'
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new PasswordResetPageStore()
export default store
