import React from 'react'
import PropTypes from 'prop-types'
import styles from './Checkbox.module.scss'

export class Checkbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = { checked: false }
  }

  componentDidMount() {
    this.setState({ checked: this.props.checked })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  onChange = () => {
    const checked = !this.state.checked
    this.setState({ checked })
    this.props.onChange(this.props.value, checked)
  }

  render() {
    const { children, disabled, id, value, required, validate } = this.props
    const { checked } = this.state

    let modifiers = ''
    if (disabled) modifiers += ` ${styles._disabled}`

    if (required && validate) {
      modifiers += checked ? ` ${styles._valid}` : ` ${styles._invalid}`
    }

    return (
      <label className={`${styles.label}${modifiers}`}>
        <span className={styles.control}>
          <input
            name={id}
            className={styles.input}
            type="checkbox"
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={this.onChange}
            required={required}
          />
          <span className={styles.chip}></span>
        </span>
        <span className={styles.text}>{children}</span>
      </label>
    )
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.bool,
}

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  required: false,
  validate: false,
}
