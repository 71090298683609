import styles from './Thumbnail.module.scss'

const LOADING_PIC = '/redeeming/product-default.png'
const LOADING_TEXT = '\u00A0' // Space in order to keep title height

export const ProductThumbnail = ({
  product,
  variant,
  onClick,
  readonly,
  showVendor,
}) => {
  let title = LOADING_TEXT
  let vendor = LOADING_TEXT
  if (product) {
    title = product.title
    vendor = product.vendorInfos?.name
  }
  if (variant) title += ' ' + variant.title

  let picture = LOADING_PIC
  if (product && product.featuredImage) picture = product.featuredImage
  if (variant && variant.image) picture = variant.image

  const getContent = (title, picture, vendor) => {
    return (
      <div>
        <div className={styles.piccontainer} title={title}>
          <img className={styles.pic} src={picture} alt={title} />
        </div>
        {showVendor && <div className={styles.vendor}>{vendor}</div>}
        <div className={styles.title}>{title}</div>
      </div>
    )
  }

  let modifiers = ''
  if (!product) modifiers += ' ' + styles._loading
  if (readonly) modifiers += ' ' + styles._readonly

  return (
    <div
      className={`${styles.product} ${modifiers}`}
      onClick={() => (!readonly ? onClick(product) : {})}
    >
      <div className={styles.skeleton}>
        {getContent(LOADING_TEXT, LOADING_PIC, LOADING_TEXT)}
      </div>

      <div className={styles.content}>{getContent(title, picture, vendor)}</div>
    </div>
  )
}
