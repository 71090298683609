import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BEboxesClosed = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes E-Coffrets clôturées"
    deliveryMethod={0}
    status={'closed'}
  />
)
