import api from 'utils/api'

export const getBoxStatus = params =>
  api.post('api/customers/status/get', params)

export const contactBoxSupplier = params =>
  api.post('api/customers/contact', params)

export const receiveBoxSupplier = params =>
  api.post('api/customers/received', params)

export const reviewBoxSupplier = params =>
  api.post('api/customers/review', params)

export const updateBoxDetails = params =>
  api.post('api/customers/status/updatedetails', params)

export const getOrderStatus = body => api.post('api/customers/order/get', body)

export const updateOrderDetails = params =>
  api.post('api/customers/order/updatedetails', params)

export const sendBox = params => api.post('api/customers/send', params)
