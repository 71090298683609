import React from 'react'
import PropTypes from 'prop-types'
import styles from './Rating.module.scss'
import { IconStar } from 'ui/components/Icon'
import { ICON_FILL_COLOR } from 'ui/components/Icon/Icon'

export const Rating = ({ color, count, max, value }) => {
  const ratings = Array.from(Array(max).keys())
  const width = (value / max) * 100 + '%'

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.stars}
        title={`${value}/${max} pour ${count} avis`}
      >
        <div className={styles.all}>
          {ratings.map(v => (
            <div key={v} className={styles.star}>
              <IconStar stroke={color} />
            </div>
          ))}
        </div>
        <div className={styles.filled} style={{ width }}>
          {ratings.map(v => (
            <div key={v} className={styles.star}>
              <IconStar fill={color} stroke={color} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.literal}>
        <span className={styles.info}>
          {value}/{max}
        </span>
        {count && (
          <>
            <span> pour </span>
            <span className={styles.info}>{count}</span>
            <span> avis</span>
          </>
        )}
      </div>
    </div>
  )
}

Rating.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
}

Rating.defaultProps = {
  color: ICON_FILL_COLOR,
  count: null,
  max: 5,
}
