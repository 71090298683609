import { Buffer } from 'buffer'

export const createUUID = () => {
  const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return pattern.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const downloadPDFContent = (filename, data) => {
  const binary = Buffer.from(data, 'base64')

  // Set type to octet instead pdf to prevent browser open file inside current tab
  const blob = new Blob([binary], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = `${filename}.pdf`
  a.click()
  document.body.removeChild(a)
}
