import api from 'utils/api'

export const SUPPLIERS_PARTNERS_EMAIL = 'partenariat@icipresent.com'
export const SUPPLIERS_INVOICING_EMAIL = 'facturation@icipresent.com'

export const SUPPLIERS_DOCUMENTS_URL = 'https://linktr.ee/icipresent.plateforme'

export const SUPPLIERS_COMPANY_ERRORS = {
  siret_invalid: 'Le SIRET précisé est invalide',
  companyname_invalid: 'Le nom de la société est invalide',
  vatnumber_invalid: 'Le numéro de TVA intracommunautaire est invalide',
  address1_invalid: "L'adresse est invalide",
  zipcode_invalid: 'Le code postal est invalide',
  city_invalid: 'La ville est invalide',
  lastname_invalid: 'Le nom du contact administratif est invalide',
  firstname_invalid: 'Le prénom du contact administratif est invalide',
  phone_invalid: 'Le numéro de téléphone du contact administratif est invalide',
  email_invalid: "L'email du contact administratif est invalide",
  cgp_invalid:
    "Les Conditions Générales de Partenariat n'ont pas été acceptées",
}

export const SUPPLIERS_VAT = [
  {
    id: '20',
    value: '20 % : taux normal',
  },
  {
    id: '10',
    value: '10 %',
  },
  {
    id: '5.5',
    value: '5,5 %',
  },
  {
    id: '0',
    value: '0 % : je suis exonéré de TVA',
  },
  // multi vat is deactivated on frontend
]

export const acceptIntro = uniqueKey =>
  api.post('api/suppliers/acceptIntro', { uniqueKey })

export const getExpeditionConfiguration = uniqueKey =>
  api.post('api/suppliers/config/expedition/get', { uniqueKey })

export const updateExpeditionConfiguration = (
  uniqueKey,
  config,
  activeShippers
) =>
  api.post('api/suppliers/config/expedition/update', {
    uniqueKey,
    ...config,
    activeShippers,
  })

export const getCompanyConfiguration = uniqueKey =>
  api.post('api/suppliers/config/company/get', { uniqueKey })

export const updateCompanyConfiguration = (uniqueKey, config, cgpAccepted) =>
  api.post('api/suppliers/config/company/update', {
    uniqueKey,
    config,
    cgpAccepted,
  })

export const getSiretInformation = siret =>
  api.post('api/suppliers/config/company/siret', { siret })

export const getInvoice = body => api.post('api/suppliers/invoices/get', body)

export const getAdminSuppliers = body =>
  api.post(`api/admin/suppliers/list`, body)

export const exportSuppliers = body =>
  api.post(`api/admin/suppliers/export`, body)

export const getOffConfiguration = uniqueKey => {
  return api
    .post('api/suppliers/config/off/get', { uniqueKey })
    .then(result => {
      // string to date mapping
      let from = result.result.config?.from
      let to = result.result.config?.to

      from = from?.length > 0 ? new Date(Date.parse(from)) : null
      to = to?.length > 0 ? new Date(Date.parse(to)) : null

      result.result.config = { ...result.result.config, from, to }
      return Promise.resolve(result)
    })
}

export const saveOffConfiguration = (uniqueKey, config) => {
  return api.post('api/suppliers/config/off/save', { uniqueKey, config })
}
