import React from 'react'
import { Helmet } from 'react-helmet'
import jsbarcode from 'jsbarcode'
import './DownloadCODE128Page.scss'
import config from 'config'
import Url from 'urlParser'
import { fetch } from 'utils/api'

export default class DownloadEAN128Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = { codes: [] }
  }

  componentDidMount() {
    let params = new Url().getParams()
    let from = params.from
    let to = params.to
    this.auchan = params.auchan
    if (from && to) this.load(parseInt(from), parseInt(to))
  }

  async load(from, to) {
    let params = { from, to }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/packagers/code128/list`, {
      method: 'POST',
      body,
    })

    let json = await resp.json()
    let codes = json.codes

    this.setState({ codes })

    setTimeout(() => {
      for (let i = 0; i < codes.length; i++) {
        jsbarcode(
          `#code-${i}`,
          this.auchan ? this.adaptForWTF(codes[i]) : codes[i],
          { format: 'CODE128' }
        )
      }
    }, 2000)
  }

  // Auchan has weird (probably bogus) constraints, where they want
  // id to be strictly on 12 characters, but it mustn't start with leading 0.
  // If it starts with a 9 followed by 0, it's fine by them.
  adaptForWTF(id) {
    return '9' + id.padStart(11, '0')
  }

  codes() {
    let codes = []

    for (let i = 0; i < this.state.codes.length; i++) {
      codes.push(<svg key={i} className="code" id={`code-${i}`}></svg>)
    }

    return codes
  }

  render() {
    return (
      <div id="DownloadCODE128Page">
        <Helmet>
          <title>Téléchargement - Ici Présent!</title>
        </Helmet>
        {this.codes()}
      </div>
    )
  }
}
