import React from 'react'
import Icon from './Icon'

export const IconTruck = props => (
  <Icon {...props}>
    <rect x="1" y="3" width="14" height="13" />
    <path d="M15 8H19L23 12V16H15V8Z" />
    <circle cx="5.5" cy="18.5" r="2.5" />
    <circle cx="17.5" cy="18.5" r="2.5" />
  </Icon>
)
