import React from 'react'
import PropTypes from 'prop-types'
import styles from './ContentInvoice.module.scss'
import { Card, Logo, Title } from 'ui'
import { toPascalCase } from 'utils/string'

export const ContentInvoice = props => {
  const { invoice } = props

  const showEmitterVAT = invoice.totalTax > 0 || invoice.emitter.isOwner
  const showRecipientVAT = invoice.totalTax > 0 || invoice.recipient.isOwner

  return (
    <div className={styles.invoice} id={invoice.invoiceType.name}>
      <Card light large>
        <header>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.emitter}>
            <span>
              <b>Emetteur : </b>
            </span>
            <span>{invoice.emitter.company}</span>
            <span>{invoice.emitter.address1}</span>
            <span>{invoice.emitter.address2}</span>
            <span>
              {invoice.emitter.zipCode}{' '}
              {invoice.emitter && invoice.emitter.city}
            </span>
            <span>N° SIRET : {invoice.emitter.siret}</span>
            {showEmitterVAT && (
              <span>N° TVA : {invoice.emitter.vatNumber}</span>
            )}
            <br />
            <span>Facture n° {invoice.invoiceNumber}</span>
            <span>Date : {invoice.invoiceDate}</span>
          </div>
          <div className={styles.recipient}>
            <span>
              <b>Destinataire : </b>
            </span>
            <span>{invoice.recipient.company}</span>
            <span>{invoice.recipient.address1}</span>
            <span>{invoice.recipient.address2}</span>
            <span>
              {invoice.recipient.zipCode} {invoice.recipient.city}
            </span>
            <span>N° SIRET : {invoice.recipient.siret}</span>
            {showRecipientVAT && (
              <span>N° TVA : {invoice.recipient.vatNumber}</span>
            )}
          </div>
          <div className={styles.title}>
            <Title>
              {toPascalCase(invoice.invoiceType.label)}
              {invoice.isProforma && <span> (proforma)</span>}
            </Title>
          </div>
        </header>
        <section className={styles.items}>
          <table>
            <thead>
              <tr>
                <th className={styles.itemsDescr}>Description</th>
                <th className={styles.itemsVAT}>TVA</th>
                <th className={styles.itemsQte}>Qté</th>
                <th className={styles.itemsHT}>Total HT</th>
                <th className={styles.itemsTTC}>Total TTC</th>
              </tr>
            </thead>
            <tbody>
              {invoice.items &&
                invoice.items.map(i => (
                  <tr key={i.name}>
                    <td className={styles.itemsDescr}>{i.name}</td>
                    <td className={styles.itemsVAT}>{i.taxPercentage} %</td>
                    <td className={styles.itemsQte}>{i.quantity}</td>
                    <td className={styles.itemsHT}>
                      {i.totalHT} {invoice.currency}
                    </td>
                    <td className={styles.itemsTTC}>
                      {i.totalTTC} {invoice.currency}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        <footer className={styles.total}>
          <table>
            <tbody>
              <tr>
                <td className={styles.totalEmpty}>&nbsp;</td>
                <td className={styles.totalLabel}>TOTAL HT</td>
                <td className={styles.totalMoney}>
                  {invoice.totalHT} {invoice.currency}
                </td>
              </tr>
              <tr>
                <td className={styles.totalEmpty}>&nbsp;</td>
                <td className={styles.totalLabel}>TVA</td>
                <td className={styles.totalMoney}>
                  {invoice.totalTax} {invoice.currency}
                </td>
              </tr>
              <tr>
                <td className={styles.totalEmpty}>&nbsp;</td>
                <td className={styles.totalLabel}>TOTAL TTC</td>
                <td className={styles.totalMoney}>
                  {invoice.total} {invoice.currency}
                </td>
              </tr>
              {invoice.totalTax === 0 && (
                <tr className={styles.novat}>
                  <td colSpan={3}>TVA non applicable, art. 293 B du CGI</td>
                </tr>
              )}
            </tbody>
          </table>
        </footer>
      </Card>
    </div>
  )
}

ContentInvoice.propTypes = {
  invoice: PropTypes.object,
}
