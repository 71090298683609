import React from 'react'
import { Helmet } from 'react-helmet'
import Review from './SupplierReviewsPage/Review.js'
import Pagination from 'components/Pagination.js'
import UrlParser from 'urlParser.js'
import store from 'stores/SupplierReviewsPageStore.js'
import './SupplierReviewsPage.scss'
import { SupplierNavigation } from 'features/suppliers/components'
import { withRouter } from 'hocs/withRouter'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
    let url = new UrlParser()
    let params = url.getParams()
    let currentPage = parseInt(params.page, 10) || 1
    store.load(this.props.params.uniqueKey, currentPage)
  }

  componentWillUnmount() {
    store.unsubscribe(this.updated)
  }

  reviews() {
    if (this.state.reviews.length) {
      return this.state.reviews.map(r => <Review key={r.shipping.id} {...r} />)
    } else {
      return <p>Pas encore de reviews.</p>
    }
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          unknown_key: 'Compte fournisseur inconnu.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  pageChanged(page) {
    let url = new UrlParser()
    let params = url.getParams()
    params.page = page
    url.setParams(params)
    window.location.href = url.toString()
  }

  pagination() {
    if (this.state.pageCount > 1) {
      return (
        <Pagination
          pageCount={this.state.pageCount}
          currentPage={this.state.page}
          onChange={page => this.pageChanged(page)}
        />
      )
    }
  }

  render() {
    return (
      <div className="page" id="SupplierReviewsPage">
        <Helmet>
          <title>Avis clients - Ici Présent!</title>
        </Helmet>
        <div class="menu">
          <SupplierNavigation
            supplierKey={this.state.uniqueKey}
            title={'Avis clients'}
          />
        </div>

        <div className="group">
          <h1 className="h1">Vos avis clients</h1>
          {this.pagination()}
          {this.reviews()}
          {this.pagination()}
        </div>
      </div>
    )
  }
}

const SupplierReviewsPage = withRouter(Page)
export default SupplierReviewsPage
