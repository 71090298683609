import React from 'react'
import PropTypes from 'prop-types'
import styles from './Notification.module.scss'
import { IconClose } from 'ui'
import { withStatus } from 'ui/hoc'

class InternalNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
    }
  }
  componentDidMount = () => {
    const { id, timeout } = this.props

    setTimeout(() => this.setState({ mounted: true }), 10)

    if (timeout !== 0)
      this.timer = setTimeout(() => this.props.onRequestClose(id), timeout)
  }

  componentWillUnmount = () => {
    if (this.timer) clearTimeout(this.timer)
  }

  render() {
    const { children, id, onRequestClose } = this.props
    const modifiers = this.state.mounted ? styles._mounted : ''

    return (
      <div className={`${styles.notif} ${modifiers} ${this.props.className}`}>
        <div className={styles.content}>{children}</div>
        <div className={styles.actions}>
          <button
            className={styles.close}
            onClick={() => onRequestClose(id)}
            title={'Supprimer la notification'}
          >
            <IconClose />
          </button>
        </div>
      </div>
    )
  }
}

export const Notification = withStatus(InternalNotification)

Notification.displayName = 'Notification'

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  ...withStatus.propTypes,
}

Notification.defaultProps = {
  timeout: 0,
  ...withStatus.defaultProps,
}
