import React from 'react'
import PropTypes from 'prop-types'
import { ShippingStates } from 'services/shippings'
import {
  IconActionClose,
  IconBell,
  IconBox,
  IconCalendar,
  IconFlag,
  IconTruck,
} from 'ui'

const SIZE_SMALL = 16
const SIZE_DEFAULT = 20

export const ShippingIcon = ({ small, status }) => {
  const size = small
    ? { width: SIZE_SMALL, height: SIZE_SMALL }
    : { width: SIZE_DEFAULT, height: SIZE_DEFAULT }

  if (status === ShippingStates.accepted.value)
    return <IconBox fill={'#ff9c42'} {...size} />
  if (status === ShippingStates.canceled.value)
    return <IconActionClose fill={'#e94545'} {...size} />
  if (status === ShippingStates.delivered.value)
    return <IconFlag fill={'#4e4b66'} {...size} />
  if (status === ShippingStates.forthcoming.value)
    return <IconCalendar fill={'#a0a3bd'} {...size} />
  if (status === ShippingStates.received.value)
    return <IconBell fill={'#548eff'} {...size} />
  if (status === ShippingStates.sent.value)
    return <IconTruck fill={'#87e278'} {...size} />
  return <></>
}

ShippingIcon.propTypes = {
  small: PropTypes.bool,
  status: PropTypes.oneOf([
    'accepted',
    'canceled',
    'delivered',
    'forthcoming',
    'received',
    'sent',
  ]).isRequired,
}

ShippingIcon.defaultProps = {
  small: false,
}
