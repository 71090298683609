import React from 'react'
import styles from './Maintenance.module.scss'
import { Annotation, Logo, Title } from 'ui'

export const Maintenance = () => (
  <div className={styles.wrapper}>
    <div className={styles.logo}>
      <Logo />
    </div>
    <Title>Nous sommes en maintenance</Title>
    <br />
    <Annotation>
      Désolé pour la gêne occasionnée, merci de revenir plus tard.
    </Annotation>
  </div>
)
