import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'
import { withStatus } from 'ui/hoc'

export const Button = withStatus(props => {
  const { children, disabled, icon, onClick, title, width } = props

  let modifiers = ''
  if (props.small) modifiers += ` ${styles._small}`
  if (props.light) modifiers += ` ${styles._light}`
  if (icon && !children) modifiers += ` ${styles._icon}`
  if (props.hideLabelOnMobile) modifiers += ` ${styles._hideLabel}`
  if (!props.radiusLeft) modifiers += ` ${styles._noRadiusLeft}`
  if (!props.radiusRight) modifiers += ` ${styles._noRadiusRight}`

  // Status
  if (props.primary) modifiers += ` ${styles._primary}`
  else if (props.info) modifiers += ` ${styles._info}`
  else if (props.success) modifiers += ` ${styles._success}`
  else if (props.warning) modifiers += ` ${styles._warning}`
  else if (props.error) modifiers += ` ${styles._error}`
  else modifiers += ` ${styles._default}`

  return (
    <button
      className={`${styles.button}${modifiers} ${props.className}`}
      onClick={e => !disabled && onClick && onClick(e)}
      disabled={disabled}
      style={{ width }}
      title={title}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {children && <span className={styles.inner}>{children}</span>}
    </button>
  )
})

Button.displayName = 'Button'

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  hideLabelOnMobile: PropTypes.bool,
  onClick: PropTypes.func,
  radiusLeft: PropTypes.bool,
  radiusRight: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
  ...withStatus.propTypes,
}

Button.defaultProps = {
  disabled: false,
  icon: null,
  hideLabelOnMobile: false,
  onClick: null,
  radiusLeft: true,
  radiusRight: true,
  small: false,
  title: null,
  width: null,
  ...withStatus.defaultProps,
}
