import React from 'react'
import PropTypes from 'prop-types'
import styles from './OrderB2BAdminCard.module.scss'
import { Card, Button, IconExport, IconCopy } from 'ui'
import {
  OrderB2BCardHeader,
  OrderB2BCardInfos,
  OrderB2BCardTracking,
} from './components'

export class OrderB2BAdminCard extends React.Component {
  constructor(props) {
    super(props)
    this.onDuplicateOrderClick = this.onDuplicateOrderClick.bind(this)
    this.onExportClick = this.onExportClick.bind(this)
  }

  onDuplicateOrderClick = () => {
    this.props.onDuplicateOrder(this.props.order.id)
  }

  onExportClick = () => {
    this.props.onExport(this.props.order.id)
  }

  getActions = () => {
    let actions = []
    actions.push(
      <Button onClick={this.onExportClick} icon={<IconExport />}>
        Exporter
      </Button>
    )
    actions.push(
      <Button onClick={this.onDuplicateOrderClick} icon={<IconCopy />}>
        Dupliquer
      </Button>
    )
    return actions
  }

  render() {
    const {
      order: { isPhysical, nbBoxesSent, trackingItems },
    } = this.props

    const displayTracking = isPhysical && nbBoxesSent > 0

    let modifiers = ''
    if (displayTracking) modifiers += ` ${styles._tracking}`

    return (
      <Card
        padding={false}
        light
        actions={this.getActions()}
        to={`/a/orders/b2b/${this.props.order.id}`}
      >
        <div className={`${styles.order} ${modifiers}`}>
          <div className={styles.container}>
            <OrderB2BCardHeader {...this.props} />

            <div className={styles.infos}>
              <OrderB2BCardInfos {...this.props} />
            </div>

            {displayTracking && <OrderB2BCardTracking items={trackingItems} />}
          </div>
        </div>
      </Card>
    )
  }
}

OrderB2BAdminCard.propTypes = {
  onEditOrder: PropTypes.func,
  onDuplicateOrder: PropTypes.func,
  order: PropTypes.object.isRequired,
}
