import React from 'react'
import PropTypes from 'prop-types'
import styles from './Badge.module.scss'
import { withStatus } from 'ui/hoc'
import { Link } from 'react-router-dom'

export const Badge = withStatus(props => {
  let modifiers = ''
  if (props.small) modifiers += ` ${styles._small}`
  if (props.to) modifiers += ` ${styles._link}`

  const params = {
    title: props.title,
    className: `${styles.wrapper}${modifiers} ${props.className}`,
  }

  let TagName = 'div'
  if (props.to && props.external) {
    TagName = 'a'
    params.href = props.to
    params.target = '_blank'
  }

  if (props.to && !props.external) {
    TagName = Link
    params.to = props.to
  }

  return (
    <TagName {...params}>
      {props.icon && <div className={styles.icon}>{props.icon}</div>}
      <span>{props.label}</span>
    </TagName>
  )
})

Badge.displayName = 'Badge'

Badge.propTypes = {
  external: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  small: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string,
  ...withStatus.propTypes,
}

Badge.defaultProps = {
  external: false,
  icon: null,
  small: false,
  title: '',
  to: null,
  ...withStatus.defaultProps,
}
