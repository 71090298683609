import React from 'react'
import store from 'stores/Admin/BoxesStore'
import UrlParser from 'urlParser'

/*
 * Properties:
 *
 * * field    String
 * * query    String
 *
 */
export default class Search extends React.Component {
  search() {
    let url = new UrlParser()
    let params = url.getParams()
    params.field = this.props.field
    params.query = this.props.query
    params.page = 1
    url.setParams(params)
    window.location.href = url.toString()
  }

  render() {
    return (
      <div id="admin-boxes-search" className="input-group">
        <select
          className="text-field"
          value={this.props.field}
          onChange={e => store.searchFieldChanged(e.target.value)}
        >
          <option value="all">Tout</option>
          <option value="name">Nom</option>
          <option value="address">Adresse</option>
          <option value="shopifyId">Shopify ID</option>
          <option value="supplier">Fournisseur</option>
          <option value="redeemingValues">Codes</option>
          <option value="email">Email</option>
        </select>

        <input
          type="text"
          placeholder="Recherche..."
          value={this.props.query}
          className="text-field"
          onChange={e => store.searchQueryChanged(e.target.value)}
        />

        <span>
          <button className="btn" onClick={e => this.search()}>
            Chercher
          </button>
        </span>
      </div>
    )
  }
}
