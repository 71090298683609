export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export const allSettled = promises => {
  let wrappedPromises = promises.map(p =>
    Promise.resolve(p).then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err })
    )
  )
  return Promise.all(wrappedPromises)
}

export const resolvesAfter = (promise, delay) => {
  return allSettled([promise, wait(delay)]).then(test => {
    if (test[0].status === 'rejected') return Promise.reject(test[0].reason)
    return Promise.resolve(test[0].value)
  })
}
