import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLink, Card } from 'ui'
import styles from './SupplierCard.module.scss'
import { SupplierCardBody } from './components'

export const SupplierCard = props => (
  <Card
    padding={false}
    light
    to={`/a/suppliers/${props.supplier.id}/edit`}
    actions={[
      <ButtonLink to={`/a/suppliers/${props.supplier.id}/edit`}>
        Editer
      </ButtonLink>,
      <ButtonLink to={`/suppliers/${props.supplier.uniqueKey}`}>
        Interface
      </ButtonLink>,
    ]}
  >
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.name}>{props.supplier.name}</div>
        <div className={styles.id}>#{props.supplier.id}</div>
      </div>
      <SupplierCardBody {...props} />
    </div>
  </Card>
)

SupplierCard.propTypes = {
  supplier: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    adminEmail: PropTypes.string,
    averageRating: PropTypes.number.isRequired,
    commission: PropTypes.number.isRequired,
    deleted: PropTypes.bool.isRequired,
    deletedAt: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    off: PropTypes.bool,
    offFrom: PropTypes.string,
    offTo: PropTypes.string,
    ordersSent: PropTypes.number.isRequired,
    oldestLate: PropTypes.string,
    phone: PropTypes.string,
    supportEmail: PropTypes.string,
    totalLate: PropTypes.number,
    uniqueKey: PropTypes.string.isRequired,
  }).isRequired,
}
