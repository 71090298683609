import React from 'react'
import PropTypes from 'prop-types'
import styles from './Stats.module.scss'
import { Title, Figure, Grid } from 'ui'
import { withFade } from 'ui/hoc'

export const Stats = withFade(props => {
  const { shippingStats } = props

  const orderStats = [
    {
      label: 'Commandes expédiées ce mois-ci',
      value: shippingStats.ordersSentThisMonth.toString(),
    },
    {
      label: 'Commandes expédiées le mois dernier',
      value: shippingStats.ordersSentPastMonth.toString(),
    },
    {
      label: 'Commandes expédiées depuis le ' + shippingStats.firstSale,
      value: shippingStats.ordersSent.toString(),
    },
  ]

  const ratingStats = [
    { label: 'Note moyenne', value: shippingStats.averageRating.toString() },
    {
      label: 'Note moyenne sur les 30 derniers jours',
      value: shippingStats.averageRating30d.toString(),
    },
    { label: 'Nombre de notes', value: shippingStats.ratingCount.toString() },
  ]

  return (
    <div className={styles.container}>
      {shippingStats.firstSale.length === 0 && (
        <div className={styles.empty}>
          <Title level={1}>Aucune commande pour le moment...</Title>
          <div className={styles.emptySubtitle}>
            Revenez dès que vous aurez expédié votre première commande !
          </div>
        </div>
      )}
      {shippingStats.firstSale.length > 0 && (
        <Grid
          cols={1}
          s={2}
          items={[
            <div>
              <div className={styles.title}>
                <Title hLevel={2}>Commandes</Title>
              </div>
              <div className={styles.list}>
                {orderStats.map((stat, index) => (
                  <div className={styles.item} key={index}>
                    <Figure label={stat.label} value={stat.value} />
                  </div>
                ))}
              </div>
            </div>,
            <div>
              <div className={styles.title}>
                <Title hLevel={2}>Notes</Title>
              </div>
              <div className={styles.list}>
                {ratingStats.map((stat, index) => (
                  <div className={styles.item} key={index}>
                    <Figure label={stat.label} value={stat.value} />
                  </div>
                ))}
              </div>
            </div>,
          ]}
        />
      )}
    </div>
  )
})

Stats.propTypes = {
  shippingStats: PropTypes.shape({
    firstSale: PropTypes.string,
    ordersSentThisMonth: PropTypes.number,
    ordersSentPastMonth: PropTypes.number,
    ordersSent: PropTypes.number,
    averageRating: PropTypes.number,
    averageRating30d: PropTypes.number,
    ratingCount: PropTypes.number,
  }),
}

Stats.defaultProps = {
  shippingStats: {},
}
