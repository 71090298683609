import React from 'react'
import './SupplierForm.scss'
/*
 * Properties
 *
 * * store                [Object] - the related store for the form
 * * name                 [String]
 * * emails               [String]
 * * supportEmails        [String]
 * * trackingCodeRequired [Boolean]
 * * useColissimo         [Boolean]
 * * active               [Boolean]
 * * inactiveReason       [String]
 * * colissimo_address    [String]
 * * colissimo_city       [String]
 * * colissimo_zipcode    [String]
 *
 */
export default class SupplierForm extends React.Component {
  submit = e => {
    e.preventDefault()
    this.props.store.submit()
  }

  inactiveReason() {
    if (!this.props.active) {
      return (
        <div className="input-group">
          <label>
            Raison de désactivation (public)
            <input
              type="text"
              className="text-field"
              value={this.props.inactiveReason}
              onChange={e =>
                this.props.store.inactiveReasonChanged(e.target.value)
              }
            />
          </label>
          <p className="text-help">
            Si vous fournissez une raison de désactivation, le fournisseur sera
            toujours présent dans la liste des artisans mais ne pourra être
            sélectionné, et la raison indiquée ici sera présentée à
            l'utilisateur.
          </p>
        </div>
      )
    }
  }

  addressFields() {
    if (this.props.useColissimo) {
      return (
        <div>
          <div className="input-group">
            <label>
              Adresse
              <input
                type="text"
                required={true}
                className="text-field"
                value={this.props.colissimo_address}
                onChange={e =>
                  this.props.store.colissimoAddressChanged(e.target.value)
                }
              />
            </label>
          </div>

          <div className="input-group">
            <label>
              Code postal
              <input
                type="text"
                required={true}
                className="text-field"
                value={this.props.colissimo_zipcode}
                onChange={e =>
                  this.props.store.colissimoZipcodeChanged(e.target.value)
                }
              />
            </label>
          </div>

          <div className="input-group">
            <label>
              Ville
              <input
                type="text"
                required={true}
                className="text-field"
                value={this.props.colissimo_city}
                onChange={e =>
                  this.props.store.colissimoCityChanged(e.target.value)
                }
              />
            </label>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <form onSubmit={this.submit} id="admin-supplier-form">
        <div className="input-group">
          <label>
            Nom
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.name}
              onChange={e => this.props.store.nameChanged(e.target.value)}
            />
          </label>
        </div>

        <div className="input-group">
          <label>
            Emails
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.emails}
              onChange={e => this.props.store.emailsChanged(e.target.value)}
            />
          </label>
          <p className="text-help">Liste de mails séparés par un espace</p>
        </div>

        <div className="input-group">
          <label>
            Emails pour le support
            <input
              type="text"
              className="text-field"
              value={this.props.supportEmails}
              onChange={e =>
                this.props.store.supportEmailsChanged(e.target.value)
              }
            />
          </label>
          <p className="text-help">
            Liste de mails pour les messages de support, séparés par un espace.
            Si ce champs est vide, il sera rempli avec les emails principaux du
            fournisseur.
          </p>
        </div>

        <div className="input-group">
          <label>
            Commission
            <input
              type="number"
              step="0.01"
              className="text-field"
              required
              value={this.props.commission}
              onChange={e => this.props.store.commissionChanged(e.target.value)}
              onWheel={e => e.target.blur()}
            />
          </label>
          <p className="text-help">
            Commission Ici Présent! appliquée aux coffret-cadeaux et à la vente
            directe (en pourcents).
          </p>
        </div>

        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={this.props.trackingCodeRequired}
              onChange={e =>
                this.props.store.trackingCodeRequiredChanged(e.target.checked)
              }
            />
            Numéro de suivi obligatoire
          </label>
          <p className="text-help">
            Si cette option est activée, le fournisseur sera obligé de fournir
            un numéro de suivi pour valider une commande. Mutuellement exclusif
            avec "Colissimo".
          </p>
        </div>

        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={this.props.useColissimo}
              onChange={e =>
                this.props.store.useColissimoChanged(e.target.checked)
              }
            />
            Colissimo
          </label>
          <p className="text-help">
            Si cette option est activée, le fournisseur pourra générer une
            étiquette Colissimo via Ici Présent!. Le numéro de suivi sera
            automatiquement assigné en imprimant l'étiquette Colissimo.
            Mutuellement exclusif avec "Numéro de suivi obligatoire".
          </p>
        </div>

        {this.addressFields()}

        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={this.props.active}
              onChange={e => this.props.store.activeChanged(e.target.checked)}
            />{' '}
            Actif
          </label>
        </div>

        {this.inactiveReason()}
        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={this.props.removed}
              onChange={e => this.props.store.removeChanged(e.target.checked)}
            />{' '}
            Supprimé
          </label>
        </div>
        <div>
          <label>
            Informations internes
            <textarea
              className="no-transition text-field"
              value={this.props.internalInfos}
              onChange={e =>
                this.props.store.internalInfosChanged(e.target.value)
              }
            ></textarea>
          </label>
          <p className="text-help">
            Informations internes à destination des administrateurs.
          </p>
        </div>

        <div class="link-container">
          <button className="btn">Sauvegarder</button>

          {this.props.publicProfileUrl && (
            <p class="link">
              <a
                href={this.props.publicProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Voir la page publique
              </a>
            </p>
          )}

          {this.props.editProfileUrl && (
            <p class="link">
              <a
                href={this.props.editProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Editer la page publique
              </a>
            </p>
          )}

          {this.props.editProductsUrl && (
            <p class="link">
              <a
                href={this.props.editProductsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Editer ses produits
              </a>
            </p>
          )}
        </div>
      </form>
    )
  }
}
