import { Button } from 'ui'
import { Filters } from '.'

import styles from './FiltersMenu.module.scss'

export const FiltersMenu = props => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mobile}>
        <Button onClick={props.onFilter}>Filtrer les cadeaux</Button>
      </div>
      <div className={styles.inner}>
        <Filters />
      </div>
    </div>
  )
}
