import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Heading,
  LayoutFullscreen,
  Subheading,
  Subtitle,
  TextField,
  Button,
  Loader,
} from 'ui'
import { Header } from '../components'
import { NotificationManager } from 'services/notifications'
import { validCodes } from 'features/customers/redeem/slice'
import useDidMountEffect from 'hooks/useDidMountEffect'

import styles from './CodeValidation.module.scss'

const INTERNAL_ERROR = 'Une erreur est survenue'

export const CodeValidation = () => {
  const mounted = useRef(false)
  const refForm = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const box = useSelector(state => state.redeem.box)

  const [id, setId] = useState(null)
  const [code, setCode] = useState(null)
  const [valid, setValid] = useState(false)

  useEffect(() => {
    mounted.current = true
    if (!refForm.current) return
    setValid(refForm.current.checkValidity())

    return () => {
      mounted.current = false
    }
  }, [id, code])

  useDidMountEffect(() => {
    box.redirectUrl && navigate(box.redirectUrl)
  }, [box.redirectUrl]) // eslint-disable-line

  useDidMountEffect(() => {
    box.item && navigate('/redeem/selection')
  }, [box.item]) // eslint-disable-line

  useDidMountEffect(() => {
    box.error && NotificationManager.addError(INTERNAL_ERROR)
  }, [box.error])

  useDidMountEffect(() => {
    if (box.exceptions?.length === 0) return
    box.exceptions.map(ex => NotificationManager.addError(ex))
  }, [box.exceptions])

  const submit = e => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(validCodes({ id, code }))
  }

  const modifiers = box.loading ? styles._loading : ''

  return (
    <LayoutFullscreen title={'Choisissez votre cadeau'} menu={<Header />}>
      <div className={`${styles.wrapper} ${modifiers}`}>
        <div className={styles.banner}>
          <div className={styles.overlay}>
            <div className={styles.title}>
              <Subtitle level={1}>Choisissez votre cadeau</Subtitle>
            </div>
            <div className={styles.formWrapper}>
              <div className={styles.loader}>
                <Loader />
              </div>
              <form ref={refForm} name={'BoxForm'} className={styles.form}>
                <div className={styles.input}>
                  <TextField
                    id={'BoxID'}
                    label={'Numéro de coffret (6 chiffres)'}
                    type={'text'}
                    required
                    minLength={6}
                    maxLength={6}
                    autoValidate
                    onChange={value => setId(value)}
                  />
                </div>
                <div className={styles.input}>
                  <TextField
                    id={'BoxCode'}
                    label={'Code pass (4 chiffres)'}
                    type={'text'}
                    required
                    minLength={4}
                    maxLength={4}
                    autoValidate
                    onChange={value => setCode(value)}
                  />
                </div>
                <div className={styles.input}>
                  <Button primary disabled={!valid} onClick={submit}>
                    Valider
                  </Button>
                </div>
              </form>
            </div>
            <div className={styles.infos}>
              <Heading level={2}>Où trouver ces informations ?</Heading>
              <div className={styles.kinds}>
                <div className={styles.kind}>
                  <div className={styles.kindTitle}>
                    <Subheading level={3}>Coffret Imprimé</Subheading>
                  </div>
                  <div className={styles.kindContent}>
                    Vous trouverez ces chiffres au dos de votre chèque-cadeau,
                    dans le coffret.
                  </div>
                </div>
                <div className={styles.kind}>
                  <div className={styles.kindTitle}>
                    <Subheading level={3}>E-Coffret</Subheading>
                  </div>
                  <div className={styles.kindContent}>
                    Vous les trouverez en bas de votre chèque-cadeau au format
                    PDF.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.stepper}>
            <div className={styles.step}>
              <img
                className={styles.stepPic}
                src={'/redeeming/laptop.png'}
                alt={'Je consulte le site Ici Présent!'}
              />
              <div className={styles.stepTitle}>
                <Subtitle level={2}>Je consulte</Subtitle>
              </div>
              <div className={styles.stepContent}>
                les nombreuses offres de nos artisans partenaires disponibles
              </div>
            </div>
            <div className={styles.step}>
              <img
                className={styles.stepPic}
                src={'/redeeming/website.png'}
                alt={'Je renseigne mes coordonnées'}
              />
              <div className={styles.stepTitle}>
                <Subtitle level={2}>Je renseigne</Subtitle>
              </div>
              <div className={styles.stepContent}>
                mes coordonnées afin de recevoir les cadeaux artisanaux à mon
                adresse
              </div>
            </div>
            <div className={styles.step}>
              <img
                className={styles.stepPic}
                src={'/redeeming/gift.png'}
                alt={'Je reçoit mes cadeaux Ici Présent!'}
              />
              <div className={styles.stepTitle}>
                <Subtitle level={2}>Je reçois</Subtitle>
              </div>
              <div className={styles.stepContent}>
                les cadeaux que j'ai choisis à mon domicile ou en point-relais
              </div>
            </div>
          </div>
          <div className={styles.note}>
            <Card warning>
              <div className={styles.noteTitle}>
                <Subheading>Astuce pour les curieux !</Subheading>
              </div>
              <div className={styles.noteContent}>
                Entrez vos codes, consultez nos offres et revenez plus tard pour
                valider votre sélection ! Rien n'est validé tant que vous ne
                l'avez pas confirmé 🥳
              </div>
            </Card>
          </div>
        </div>
      </div>
    </LayoutFullscreen>
  )
}
