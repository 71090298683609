import api from 'utils/api'

export const importPrepaFacile = file =>
  api.post('api/packagers/tracking/prepafacile/importfile', {
    file,
  })

export const getOrders = body => api.post('api/packagers/tracking/colissimo/listorders', body)

export const addTracking = body =>
  api.post(`api/packagers/tracking/colissimo/addTracking`, body)