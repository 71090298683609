import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BEboxesIncoming = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes E-Coffrets à venir"
    deliveryMethod={0}
    status={'incoming'}
  />
)
