import React, { Component } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import config from 'config'

import { Notifier } from 'ui/index.js'

import HomePage from 'components/HomePage'
import AccountPage from 'components/AccountPage.js'
import LoginPage from 'components/LoginPage.js'
import PasswordResetPage from 'components/PasswordResetPage.js'
import SupplierMessagesPage from 'components/SupplierMessagesPage'
import SupplierThreadPage from 'components/SupplierThreadPage'
import SupplierReviews from 'components/SupplierReviewsPage'
import DownloadEAN13Page from 'components/DownloadEAN13Page'
import DownloadCODE128Page from 'components/DownloadCODE128Page'
import AdminNewSupplierPage from 'components/Admin/NewSupplierPage'
import AdminEditSupplierPage from 'components/Admin/EditSupplierPage'
import AdminBoxesPage from 'components/Admin/BoxesPage'
import AdminEditBoxPage from './components/Admin/EditBoxPage.js'
import AdminProductsPage from 'components/Admin/ProductsPage'
import AdminNewProductPage from 'components/Admin/NewProductPage'
import AdminEditProductPage from 'components/Admin/EditProductPage'
import AdminNewThemePage from 'components/Admin/NewThemePage'
import AdminEditThemePage from 'components/Admin/EditThemePage'
import AdminThemesPage from 'components/Admin/ThemesPage'
import AdminUsersPage from 'components/Admin/UsersPage.js'
import AdminNewUserPage from 'components/Admin/NewUserPage.js'

import { Maintenance } from 'features/components'
import { FeatureSuppliersInvoicesList } from 'features/suppliers/invoices'

import {
  FeatureAdminReviewsAll,
  FeatureAdminReviewsActive,
  FeatureAdminReviewsInactive,
  FeatureAdminReviewsStats,
  FeatureAdminReviewsToday,
  FeatureAdminReviewsQuarantine,
  FeatureAdminReviewsNegative,
} from 'features/admin/reviews/containers'

import { FeatureCustomersBoxStatus } from 'features/customers/box/containers'

import { FeatureCustomersOrderStatus } from 'features/customers/order/containers'

import {
  FeatureSupplierIntro,
  FeatureSupplierSupport,
} from 'features/suppliers'

import {
  FeatureSuppliersConfigurationCompany,
  FeatureSuppliersConfigurationExpedition,
  FeatureSuppliersConfigurationOff,
} from 'features/suppliers/configuration/containers'

import {
  FeatureSuppliersShippingsHistory,
  FeatureSuppliersShippingsList,
  FeatureSuppliersShippingsStats,
} from 'features/suppliers/shippings'

import {
  FeaturePackagersTrackingPrepaFacile,
  FeaturePackagersTrackingColissimo,
} from 'features/packagers/tracking/containers'

import {
  FeatureAdminOrdersB2BAll,
  FeatureAdminOrdersB2BBoxes,
  FeatureAdminOrdersB2BBoxesInactives,
  FeatureAdminOrdersB2BBoxesNotSent,
  FeatureAdminOrdersB2BBoxesSending,
  FeatureAdminOrdersB2BBoxesSent,
  FeatureAdminOrdersB2BEboxes,
  FeatureAdminOrdersB2BEboxesActives,
  FeatureAdminOrdersB2BEboxesInactives,
  FeatureAdminOrdersB2BEboxesIncoming,
  FeatureAdminOrdersB2BEboxesClosed,
  FeatureAdminOrdersB2BBoxesClosed,
  FeatureAdminOrdersB2BItem,
  FeatureAdminOrdersB2BItemNew,
  FeatureAdminOrdersB2CAll,
} from 'features/admin/orders/containers'

import {
  FeatureAdminSuppliersActive,
  FeatureAdminSuppliersAll,
  FeatureAdminSuppliersDeleted,
  FeatureAdminSuppliersInactive,
  FeatureAdminSuppliersLate,
  FeatureAdminSuppliersOff,
} from 'features/admin/suppliers/containers'

import {
  FeatureAdminSupportThreadsUnanswered,
  FeatureAdminSupportThreadsUnresolved,
  FeatureAdminSupportProlongation,
} from 'features/admin/support/containers'

import { FeatureAdminGenerateSepa } from 'features/admin/sepa/containers'

import {
  CodeValidation,
  Confirmation,
  Selection,
  Information,
  Thanks,
} from 'features/customers/redeem/containers'

import {
  FeatureAdminMonitoringSales,
  FeatureAdminMonitoringPurchases,
} from 'features/admin/monitoring/containers'

class App extends Component {
  render() {
    return (
      <div id="app">
        <Routes>
          {!!config.maintenance && <Route path="*" element={<Maintenance />} />}
          {!config.maintenance && (
            <>
              <Route path="" element={<HomePage />} />

              <Route path="redeem">
                <Route
                  path=""
                  element={<Navigate to={`validation`} replace />}
                />
                <Route path="validation" element={<CodeValidation />} />
                <Route path="selection" element={<Selection />} />
                <Route path="information" element={<Information />} />
                <Route path="confirmation" element={<Confirmation />} />
                <Route path="thanks" element={<Thanks />} />
              </Route>

              <Route path="login" element={<LoginPage />} />

              <Route path="account">
                <Route path="" element={<AccountPage />} />
                <Route path="password-reset" element={<PasswordResetPage />} />
              </Route>

              <Route path="orders">
                <Route
                  path=":shopifyId"
                  element={<FeatureCustomersOrderStatus />}
                />
              </Route>

              <Route path="status">
                <Route
                  path=":id/:redeemingId/:redeemingCode"
                  element={<FeatureCustomersBoxStatus />}
                />
              </Route>

              <Route path="packagers">
                <Route path="ean/13/:code" element={<DownloadEAN13Page />} />
                <Route path="code128" element={<DownloadCODE128Page />} />
                <Route path="tracking">
                  <Route
                    path=""
                    element={<Navigate to="prepafacile" replace />}
                  />
                  <Route
                    path="prepafacile"
                    element={<FeaturePackagersTrackingPrepaFacile />}
                  />
                  <Route
                    path="colissimo"
                    element={<FeaturePackagersTrackingColissimo />}
                  />
                </Route>
              </Route>

              <Route path="suppliers">
                <Route path=":uniqueKey">
                  <Route
                    path=""
                    element={<Navigate to={`shippings`} replace />}
                  />
                  <Route path="intro" element={<FeatureSupplierIntro />} />
                  <Route path="services" element={<FeatureSupplierSupport />} />
                  <Route path="reviews" element={<SupplierReviews />} />

                  <Route path="messages">
                    <Route path="" element={<SupplierMessagesPage />} />
                    <Route path=":threadKey" element={<SupplierThreadPage />} />
                  </Route>
                  <Route path="config">
                    <Route
                      path=""
                      element={<Navigate to={`company`} replace />}
                    />
                    <Route
                      path="expedition"
                      element={<FeatureSuppliersConfigurationExpedition />}
                    />
                    <Route
                      path="company"
                      element={<FeatureSuppliersConfigurationCompany />}
                    />
                    <Route
                      path="off"
                      element={<FeatureSuppliersConfigurationOff />}
                    />
                  </Route>
                  <Route path="shippings">
                    <Route
                      path=""
                      element={<Navigate to={`received`} replace />}
                    />
                    <Route
                      path=":status"
                      element={<FeatureSuppliersShippingsList />}
                    />
                    <Route
                      path="stats"
                      element={<FeatureSuppliersShippingsStats />}
                    />
                    <Route
                      path="history"
                      element={<FeatureSuppliersShippingsHistory />}
                    />
                  </Route>
                  <Route path="invoices">
                    <Route
                      path=""
                      element={<Navigate to={`latest/resume`} replace />}
                    />
                    <Route
                      path=":latest/resume"
                      element={<FeatureSuppliersInvoicesList />}
                    />
                    <Route
                      path=":year/:month"
                      element={<FeatureSuppliersInvoicesList />}
                    />
                    <Route
                      path=":year/:month/:type"
                      element={<FeatureSuppliersInvoicesList />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="a">
                <Route path="" element={<Navigate replace to="orders" />} />

                <Route path="monitoring">
                  <Route path="" element={<Navigate replace to="sales" />} />
                  <Route
                    path="sales"
                    element={<FeatureAdminMonitoringSales />}
                  />
                  <Route
                    path="purchases"
                    element={<FeatureAdminMonitoringPurchases />}
                  />
                </Route>

                <Route
                  path="generatesepa"
                  element={<FeatureAdminGenerateSepa />}
                />

                <Route path="support">
                  <Route path="" element={<Navigate to="messages" replace />} />

                  <Route path="messages">
                    <Route
                      path=""
                      element={<Navigate to="unanswered" replace />}
                    />
                    <Route
                      path="unanswered"
                      element={<FeatureAdminSupportThreadsUnanswered />}
                    />
                    <Route
                      path="unresolved"
                      element={<FeatureAdminSupportThreadsUnresolved />}
                    />
                  </Route>
                  <Route
                    path="prolongation"
                    element={<FeatureAdminSupportProlongation />}
                  />
                </Route>

                <Route path="suppliers">
                  <Route path="" element={<Navigate to="all" replace />} />
                  <Route path="new" element={<AdminNewSupplierPage />} />
                  <Route path="all" element={<FeatureAdminSuppliersAll />} />
                  <Route
                    path="active"
                    element={<FeatureAdminSuppliersActive />}
                  />
                  <Route
                    path="inactive"
                    element={<FeatureAdminSuppliersInactive />}
                  />
                  <Route path="late" element={<FeatureAdminSuppliersLate />} />
                  <Route path="off" element={<FeatureAdminSuppliersOff />} />
                  <Route
                    path="deleted"
                    element={<FeatureAdminSuppliersDeleted />}
                  />
                  <Route path=":id/edit" element={<AdminEditSupplierPage />} />
                </Route>

                <Route path="products">
                  <Route path="" element={<AdminProductsPage />} />
                  <Route path=":id/edit" element={<AdminEditProductPage />} />
                  <Route path="new" element={<AdminNewProductPage />} />
                </Route>

                <Route path="themes">
                  <Route path="" element={<AdminThemesPage />} />
                  <Route path="new" element={<AdminNewThemePage />} />
                  <Route path=":id/edit" element={<AdminEditThemePage />} />
                </Route>

                <Route path="reviews">
                  <Route path="" element={<Navigate to="today" replace />} />
                  <Route path="all" element={<FeatureAdminReviewsAll />} />
                  <Route path="today" element={<FeatureAdminReviewsToday />} />
                  <Route
                    path="active"
                    element={<FeatureAdminReviewsActive />}
                  />
                  <Route
                    path="inactive"
                    element={<FeatureAdminReviewsInactive />}
                  />
                  <Route
                    path="quarantine"
                    element={<FeatureAdminReviewsQuarantine />}
                  />
                  <Route
                    path="negative"
                    element={<FeatureAdminReviewsNegative />}
                  />
                  <Route path="stats" element={<FeatureAdminReviewsStats />} />
                </Route>

                <Route path="boxes">
                  <Route path="" element={<AdminBoxesPage />} />
                  <Route path=":id/edit" element={<AdminEditBoxPage />} />
                </Route>

                <Route path="users">
                  <Route path="" element={<AdminUsersPage />} />
                  <Route path="new" element={<AdminNewUserPage />} />
                </Route>

                <Route path="orders">
                  <Route path="" element={<Navigate to="b2c" replace />} />
                  <Route path="b2c" element={<FeatureAdminOrdersB2CAll />} />
                  <Route path="b2b">
                    <Route path="" element={<Navigate to="all" replace />} />
                    <Route path="all" element={<FeatureAdminOrdersB2BAll />} />
                    <Route path=":id" element={<FeatureAdminOrdersB2BItem />} />
                    <Route path="new">
                      <Route
                        path=""
                        element={<FeatureAdminOrdersB2BItemNew />}
                      />
                      <Route
                        path=":id"
                        element={<FeatureAdminOrdersB2BItemNew />}
                      />
                    </Route>

                    <Route path="eboxes">
                      <Route path="" element={<Navigate to={`all`} />} />
                      <Route
                        path="all"
                        element={<FeatureAdminOrdersB2BEboxes />}
                      />
                      <Route
                        path="actives"
                        element={<FeatureAdminOrdersB2BEboxesActives />}
                      />
                      <Route
                        path="incoming"
                        element={<FeatureAdminOrdersB2BEboxesIncoming />}
                      />
                      <Route
                        path="inactives"
                        element={<FeatureAdminOrdersB2BEboxesInactives />}
                      />
                      <Route
                        path="closed"
                        element={<FeatureAdminOrdersB2BEboxesClosed />}
                      />
                    </Route>
                    <Route path="boxes">
                      <Route path="" element={<Navigate to={`all`} />} />
                      <Route
                        path="all"
                        element={<FeatureAdminOrdersB2BBoxes />}
                      />
                      <Route
                        path="notsent"
                        element={<FeatureAdminOrdersB2BBoxesNotSent />}
                      />
                      <Route
                        path="sending"
                        element={<FeatureAdminOrdersB2BBoxesSending />}
                      />
                      <Route
                        path="sent"
                        element={<FeatureAdminOrdersB2BBoxesSent />}
                      />
                      <Route
                        path="inactives"
                        element={<FeatureAdminOrdersB2BBoxesInactives />}
                      />
                      <Route
                        path="closed"
                        element={<FeatureAdminOrdersB2BBoxesClosed />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </>
          )}
        </Routes>

        <Notifier />
      </div>
    )
  }
}

export default App
