import React from 'react'
import ShopifyImporter from './ProductForm/ShopifyImporter.js'

/*
 * Properties
 *
 * * store            [Object] - the related store for the form
 * * name             [String]
 * * tag              [String]
 * * imageUrl         [String]
 * * catalogUrl       [String]
 * * shopifyProducts  [Object]
 *
 */
export default class ProductForm extends React.Component {
  submit = e => {
    e.preventDefault()
    this.props.store.submit()
  }

  ean13codes() {
    let inputs = []

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <div className="input-group" key={`ean13-${i}`}>
          <label>
            {i + 1} Cadeaux
            <input
              type="text"
              className="text-field"
              value={this.props.ean13codes[i] || ''}
              onChange={e =>
                this.props.store.ean13codesChanged(i, e.target.value)
              }
            />
          </label>
        </div>
      )
    }

    return inputs
  }

  shopifyProducts(deliveryMethod) {
    let inputs = []

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <div className="input-group" key={`${deliveryMethod}-${i}`}>
          <label>
            {i + 1} Cadeaux
            <input
              type="text"
              className="text-field"
              value={this.props.shopifyProducts[deliveryMethod][i] || ''}
              onChange={e =>
                this.props.store.shopifyProductChanged(
                  deliveryMethod,
                  i,
                  e.target.value
                )
              }
            />
          </label>
        </div>
      )
    }

    return inputs
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <div className="input-group">
          <label>
            Nom
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.name}
              maxLength="25"
              onChange={e => this.props.store.nameChanged(e.target.value)}
            />
          </label>
          <p className="text-help">
            Maximum: 25 caractères pour les chèques d'activation
          </p>
        </div>

        <div className="input-group">
          <label>
            Label
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.tag}
              maxLength="255"
              onChange={e => this.props.store.tagChanged(e.target.value)}
            />
          </label>
          <p className="text-help">
            Ce label est utilisé pour lister les produits inclus dans le
            coffret, sur la page d'utilisation des coffrets
          </p>
        </div>

        <div className="input-group">
          <label>
            Url de l'image
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.imageUrl}
              onChange={e => this.props.store.imageUrlChanged(e.target.value)}
            />
          </label>
          <p className="text-help">Url shopify de l'image de la box</p>
        </div>

        <div className="input-group">
          <label>
            Url du catalogue
            <input
              type="text"
              required={true}
              className="text-field"
              value={this.props.catalogUrl}
              onChange={e => this.props.store.catalogUrlChanged(e.target.value)}
            />
          </label>
          <p className="text-help">Url du catalogue en ligne</p>
        </div>

        <div className="input-group">
          <label>
            Prix public pour un coffret
            <input
              type="number"
              step="0.01"
              required
              className="text-field"
              value={this.props.price || ''}
              onChange={e => this.props.store.priceChanged(e.target.value)}
              onWheel={e => e.target.blur()}
            />
          </label>
          <p className="text-help">
            Ce prix sera affiché au fournisseur pour l'aider à savoir de quelle
            offre il s'agit.
          </p>
        </div>

        <div style={{ display: 'none' }}>
          <h3 className="h3">Codes EAN13</h3>

          <p>
            Un code EAN13 est attribué dans le référentiel GS1 pour chaque
            coffret. Merci de renseigner ici ces codes pour ce produit.
          </p>

          {this.ean13codes()}
        </div>

        <h3 className="h3">Identifiants shopify</h3>

        <p>
          Les identifiants suivants permettent de lier les variantes shopify à
          ce coffret. Vous pouvez laisser certains de ces champs vides s'ils
          n'ont pas d'équivalent sur shopify, mais toutes les variantes
          existantes sur shopify pour ce coffret doivent être renseignées ici.
        </p>

        <p>
          Vous pouvez importer les identifiants depuis Shopify. Pour que cela
          fonctionne, chaque nom de variante doit contenir le chiffre qui
          correspond au nombre de box de l'offre (example : "3 cadeaux au choix
          / E-Coffret"). La livraison electronique ou physique sera déterminée
          par le fait que la variante sur shopify marque le coffret comme
          nécessitant une livraison ou non.
        </p>

        <p>En cas de doute, ne pas modifier.</p>

        <ShopifyImporter store={this.props.store} />

        <h4 className="h4">E-coffret</h4>
        {this.shopifyProducts(0)}

        <h4 className="h4">Livraison physique</h4>
        {this.shopifyProducts(1)}

        <p>
          <button className="btn">Save</button>
        </p>
      </form>
    )
  }
}
