import React from 'react'
import { Helmet } from 'react-helmet'
import MainMenu from 'components/MainMenu'
import store from 'stores/LoginPageStore.js'

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
  }

  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          unknown_email: "Pas d'utilisateur utilisant cette adresse.",
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page" id="LoginPage">
        <Helmet>
          <title>Mon compte - Ici Présent!</title>
        </Helmet>
        <MainMenu errors={this.errors()} />
        <div className="group">
          <div className="feature">
            <div>
              <h3 className="h3">Vous avez déjà un compte?</h3>
              <p>
                Connectez vous avec votre nom d'utilisateur (qui est votre
                adresse mail) et votre mot de passe.
              </p>
              <button className="btn" onClick={e => store.login()}>
                Me connecter
              </button>
            </div>
          </div>

          <div className="feature">
            <h3 className="h3">Mot de passe oublié?</h3>
            <p>
              Entrez votre adresse mail ci-dessous pour vous envoyer un lien de
              réinitialisation de votre mot de passe.
            </p>
            <form
              onSubmit={e => {
                e.preventDefault()
                store.lostPassword()
              }}
            >
              <div className="input-group">
                <label>
                  Email
                  <input
                    type="email"
                    className="text-field"
                    value={this.state.email}
                    onChange={e => store.emailChanged(e.target.value)}
                  />
                </label>
              </div>

              <p>
                <button className="btn">Envoyer</button>
              </p>
            </form>
          </div>

          <div className="feature">
            <h3 className="h3">Vous n'avez pas de compte?</h3>
            <p>
              Contactez <a href="mailto:support@icipresent.com">Ici Présent!</a>{' '}
              pour en demander l'ouverture!
            </p>
          </div>
        </div>
      </div>
    )
  }
}
