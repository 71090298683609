import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Selectbox } from 'ui'
import useDidMountEffect from 'hooks/useDidMountEffect'
import styles from './DateSelector.module.scss'

export const DateSelector = ({ disabled, min, max, value, onChange }) => {
  const refDay = useRef()
  const refMonth = useRef()
  const refYear = useRef()

  const [selected, setSelected] = useState({
    day: '',
    month: '',
    year: '',
  })
  const [years, setYears] = useState([])
  const [months, setMonths] = useState([])
  const [days, setDays] = useState([])

  useEffect(() => {
    setOptionsFromSelected(value)
    const selectedOptions = getOptionsFromDate(value)

    refDay.current.value = selectedOptions.day
    refMonth.current.month = selectedOptions.month
    refYear.current.year = selectedOptions.year

    setSelected(selectedOptions)
  }, []) //eslint-disable-line

  useEffect(() => {
    const selectedOptions = getOptionsFromDate(value)

    // Only update if the value has actually changed
    if (
      selectedOptions.day !== selected.day ||
      selectedOptions.month !== selected.month ||
      selectedOptions.year !== selected.year
    ) {
      setSelected(selectedOptions)
    }

    // always refresh min/max
    setOptionsFromSelected(value)
  }, [value, min, max]) //eslint-disable-line

  useDidMountEffect(() => {
    const newValue = getDateFromOptions(selected)
    const currentYear = parseInt(selected.year)
    const currentMonth = parseInt(selected.month)
    const currentDay = parseInt(selected.day)

    // we check if the day is correct (eg. if the month has changed, we could have a date like 31/02, which is not correct)
    const lastDayOfMonth = getLastDayOfMonth(currentYear, currentMonth)
    if (currentDay > lastDayOfMonth) {
      setSelected({ ...selected, day: lastDayOfMonth.toString() })
      return
    }

    // we check if the value is between min/max. if not we change it
    if (newValue < getDateWithoutTime(min)) {
      setSelected(getOptionsFromDate(min))
      return
    }
    if (newValue > getDateWithoutTime(max)) {
      setSelected(getOptionsFromDate(max))
      return
    }

    // we reset the available choices depending on the selected date
    setOptionsFromSelected(newValue)

    // if everything's good, we call the onChange callback
    onChange(newValue)
  }, [selected])

  const setOptionsFromSelected = value => {
    setYears(getYearOptions(min, max, value))
    setMonths(getMonthOptions(min, max, value))
    setDays(getDayOptions(min, max, value))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.day}>
        <Selectbox
          ref={refDay}
          label={'Jour'}
          onChange={day => setSelected({ ...selected, day })}
          id={'day'}
          values={days}
          initialValue={selected.day.toString()}
          disabled={disabled}
        />
      </div>
      <div className={styles.month}>
        <Selectbox
          ref={refMonth}
          label={'Mois'}
          onChange={month => setSelected({ ...selected, month })}
          id={'month'}
          values={months}
          initialValue={selected.month.toString()}
          disabled={disabled}
        />
      </div>
      <div className={styles.year}>
        <Selectbox
          ref={refYear}
          label={'Année'}
          onChange={year => setSelected({ ...selected, year })}
          id={'year'}
          values={years}
          initialValue={selected.year.toString()}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

DateSelector.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
}

const today = new Date()
let NOW = new Date(
  Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
)
NOW = new Date()
const MAX = new Date(
  Date.UTC(NOW.getFullYear() + 10, NOW.getMonth(), NOW.getDate())
)

DateSelector.defaultProps = {
  disabled: false,
  value: NOW,
  min: NOW,
  max: MAX,
}

/**
 * INTERNALS
 */

const MONTHS = [
  { id: '0', value: 'janvier' },
  { id: '1', value: 'février' },
  { id: '2', value: 'mars' },
  { id: '3', value: 'avril' },
  { id: '4', value: 'mai' },
  { id: '5', value: 'juin' },
  { id: '6', value: 'juillet' },
  { id: '7', value: 'août' },
  { id: '8', value: 'septembre' },
  { id: '9', value: 'octobre' },
  { id: '10', value: 'novembre' },
  { id: '11', value: 'décembre' },
]

const getDateWithoutTime = value => {
  return getDateFromOptions(getOptionsFromDate(value))
}

const getOptionsFromDate = value => ({
  day: value.getDate().toString(),
  month: value.getMonth().toString(),
  year: value.getFullYear().toString(),
})

const getDateFromOptions = values => {
  return new Date(Date.UTC(values.year, values.month, values.day, 0, 0, 0))
}

const getYearOptions = (min, max) => {
  const from = min.getFullYear()
  const to = max.getFullYear()
  const count = to - from + 1

  return [...Array(count).keys()].map(v => (v + from).toString())
}

const getMonthOptions = (min, max, value) => {
  const currentYear = value.getFullYear()
  const minYear = min.getFullYear()
  const minMonth = min.getMonth()
  const maxYear = max.getFullYear()
  const maxMonth = max.getMonth()

  if (currentYear === minYear) {
    return [...MONTHS].filter(m => parseInt(m.id) >= minMonth)
  }

  if (currentYear === maxYear) {
    return [...MONTHS].filter(m => parseInt(m.id) <= maxMonth)
  }

  return [...MONTHS]
}

const getDayOptions = (min, max, value) => {
  let days = [...Array(31).keys()].map(v => v + 1)

  const currentYear = value.getFullYear()
  const currentMonth = value.getMonth()
  const minYear = min.getFullYear()
  const minMonth = min.getMonth()
  const minDay = min.getDate()
  const maxYear = max.getFullYear()
  const maxMonth = max.getMonth()
  const maxDay = max.getDate()

  const lastDayOfMonth = getLastDayOfMonth(currentYear, currentMonth)
  days = days.filter(d => d <= lastDayOfMonth)

  if (currentYear === minYear && currentMonth === minMonth) {
    days = days.filter(d => d >= minDay)
  }

  if (currentYear === maxYear && currentMonth === maxMonth) {
    days = days.filter(d => d <= maxDay)
  }

  return days.map(v => v.toString())
}

const getLastDayOfMonth = (year, month) => {
  return new Date(Date.UTC(parseInt(year), parseInt(month) + 1, 0)).getDate()
}
