import React from 'react'
import PropTypes from 'prop-types'
import styles from './Pagination.module.scss'
import { Button, IconChevronLeft, IconChevronRight } from 'ui'

const BUTTON_WIDTH = 50
const ICON_SIZE = 17

export class Pagination extends React.Component {
  onPageClick(page) {
    if (this.props.onChange) this.props.onChange(page)
  }

  getLink(page, title = null, content = null, primary = false) {
    return (
      <div className={styles.item}>
        <Button
          small
          primary={primary}
          width={BUTTON_WIDTH}
          onClick={() => this.onPageClick(page)}
          title={title ? title : `Page ${page}`}
        >
          {content ? content : page}
        </Button>
      </div>
    )
  }

  render() {
    const { pagination } = this.props

    return (
      pagination?.nbPages > 1 && (
        <div className={styles.pagination}>
          {pagination.page > 1 && this.getLink(1)}

          {pagination.page > 2 &&
            this.getLink(
              pagination.page - 1,
              'Page précédente',
              <IconChevronLeft width={ICON_SIZE} height={ICON_SIZE} />
            )}

          {this.getLink(
            pagination.page,
            'Page courante',
            pagination.page,
            true
          )}

          {pagination.page < pagination.nbPages - 1 &&
            this.getLink(
              pagination.page + 1,
              'Page suivante',
              <IconChevronRight width={ICON_SIZE} height={ICON_SIZE} />
            )}

          {pagination.page < pagination.nbPages &&
            this.getLink(pagination.nbPages)}
        </div>
      )
    )
  }
}

Pagination.propTypes = {
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    nbItems: PropTypes.number,
    nbPages: PropTypes.number,
    page: PropTypes.number,
  }),
  onChange: PropTypes.func,
}
Pagination.defaultProps = {
  pagination: null,
  onChange: null,
}
