import React from 'react'
import Icon from 'ui/components/Icon/Icon'

export const IconTrash = props => (
  <Icon {...props}>
    <path d="M3.76124 19.1998L3.05125 5H20.9488L20.2388 19.1997C20.1323 21.3286 18.3752 23 16.2438 23H7.75625C5.62475 23 3.86768 21.3286 3.76124 19.1998Z" />
    <path d="M8 5H16V3C16 1.89543 15.1046 1 14 1H10C8.89543 1 8 1.89543 8 3V5Z" />
    <path d="M1 5H23" />
    <path d="M15 11V16" />
    <path d="M9 11V16" />
  </Icon>
)
