import { useState } from 'react'
import {
  Annotation,
  Button,
  Checkbox,
  Divider,
  Modal,
  Selectbox,
  TextArea,
} from 'ui'

const categoryOptions = [
  "Demande d'information générale",
  'Problème de livraison',
  "Changement d'adresse",
  'Autre',
]

export const FormContactModal = ({
  opened,
  askCategory,
  title,
  onCancel,
  onSubmit,
  askInternal = false,
}) => {
  const [message, setMessage] = useState('')
  const [category, setCategory] = useState(null)
  const [internal, setInternal] = useState(false)

  const cancel = () => {
    onCancel()
    setMessage('')
    setCategory(null)
    setInternal(false)
  }

  const submit = () => {
    onSubmit(category, message, internal)
    setMessage('')
    setCategory(null)
    setInternal(false)
  }

  const isValid = askCategory
    ? message !== '' && category !== null
    : message !== ''

  return (
    <Modal
      opened={opened}
      title={title}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={!isValid} onClick={submit}>
          Envoyer
        </Button>,
      ]}
      onClose={cancel}
    >
      {opened && (
        <>
          {askCategory && (
            <div>
              <Selectbox
                id={'Category'}
                label={'Catégorie'}
                values={categoryOptions}
                required
                initialValue={category}
                onChange={setCategory}
              />
              <Divider />
            </div>
          )}
          <TextArea
            id={'Message'}
            label={`Votre message`}
            rows={6}
            autoResize
            required
            initialValue={message}
            onChange={setMessage}
          />
          {askInternal && (
            <div>
              <Divider small />
              <Checkbox
                id={'Internal'}
                value={'internal'}
                onChange={(_, checked) => setInternal(checked)}
              >
                Échange interne
              </Checkbox>
              <Divider small />
              <Annotation>
                Cochez cette option si vous souhaitez communiquer entre
                administrateurs uniquement. Le client ne pourra pas consulter ce
                message et les administrateurs seront notifiés.
              </Annotation>
            </div>
          )}
        </>
      )}
    </Modal>
  )
}
