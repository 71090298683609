import React from 'react'
import { FeatureAdminOrdersB2BList } from './B2BList'

export const FeatureAdminOrdersB2BEboxes = props => (
  <FeatureAdminOrdersB2BList
    {...props}
    title="Commandes Entreprise E-Coffrets"
    deliveryMethod={0}
  />
)
