import React from 'react'
import styles from './ShippingCardInfos.module.scss'
import { Link } from 'ui'

export class ShippingCardInfos extends React.Component {
  render() {
    const { shipping } = this.props
    const address = `${shipping.address} ${shipping.zipcode} ${shipping.city}${
      shipping.country ? `, ${shipping.country}` : ''
    }`

    return (
      <div className={styles.infos}>
        {this.renderInfosData('ID', shipping.id)}
        {this.renderInfosData('Date', shipping.createdAt)}
        {this.renderInfosData('Origine', shipping.origin)}
        {this.renderInfosData('Entreprise', shipping.company || '/')}
        {this.renderInfosData('Prénom', shipping.firstname)}
        {this.renderInfosData('Nom', shipping.lastname)}
        {this.renderInfosData(
          'Téléphone',
          <Link to={`tel:${shipping.phone}`} external>
            {shipping.phone}
          </Link>
        )}
        {this.renderInfosData(
          'Email',
          <Link to={`mailto:${shipping.email}`} external>
            {shipping.email}
          </Link>,
          true
        )}
        {this.renderInfosData('Adresse', address, true)}
      </div>
    )
  }

  renderInfosData(label, value, full = false) {
    const modifiers = full ? styles._full : ''

    return (
      <div className={`${styles.item} ${modifiers}`}>
        <div className={styles.itemLabel}>{label}</div>
        <div className={styles.itemValue}>{value}</div>
      </div>
    )
  }
}
