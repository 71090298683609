import React from 'react'
import { formatDateTime } from 'dates'
import Supplier from './Box/Supplier.js'
import store from 'stores/Admin/BoxesStore'
import { Link } from 'react-router-dom'

/*
 * Properties:
 *
 * * id               Number
 * * createdAt        String
 * * redeemed         Boolean
 * * shopifyId        Number
 * * orderId          Number
 * * giftNumber       Number
 * * deliveryMethod   Number
 * * productName      String
 * * redeemingId      String
 * * redeemingCode    String
 * * message          String
 * * companyAddress   Object
 * * shippingAddress  Object
 * * email            String
 * * phoneNumber      String
 * * buyerEmail       String
 * * suppliers        Array
 *
 */
export default class Box extends React.Component {
  statusUrl() {
    return `/status/${this.props.id}/${this.props.redeemingId}/${this.props.redeemingCode}`
  }

  status() {
    if (this.props.redeemed) {
      return (
        <p className="redeemed">
          <a href={this.statusUrl()}>REDEEMED</a>
        </p>
      )
    }
  }

  orderId() {
    if (this.props.orderId) {
      return (
        <tr>
          <td>ID Commande:</td>
          <td>{this.props.orderId}</td>
        </tr>
      )
    }
  }

  shopifyId() {
    if (this.props.shopifyId) {
      return (
        <tr>
          <td>ID shopify:</td>
          <td>{this.props.shopifyId}</td>
        </tr>
      )
    }
  }

  deliveryMethod() {
    if (this.props.deliveryMethod === 0) {
      return 'E-Coffret'
    } else {
      return 'Coffret physique'
    }
  }

  message() {
    if (this.props.message) {
      return (
        <tr>
          <td>Message:</td>
          <td>{this.props.message}</td>
        </tr>
      )
    }
  }

  email() {
    if (this.props.email) {
      return (
        <tr>
          <td>Email:</td>
          <td>{this.props.email}</td>
        </tr>
      )
    }
  }

  phoneNumber() {
    if (this.props.phoneNumber) {
      return (
        <tr>
          <td>Téléphone:</td>
          <td>{this.props.phoneNumber}</td>
        </tr>
      )
    }
  }

  buyerEmail() {
    if (this.props.buyerEmail) {
      return (
        <tr>
          <td>Email de l'acheteur:</td>
          <td>{this.props.buyerEmail}</td>
        </tr>
      )
    }
  }

  // address where the box was shipped, for physical boxes.
  companyAddress() {
    if (this.props.deliveryMethod === 1 && this.props.companyAddress) {
      return (
        <tr>
          <td>Adresse de livraison Ici Présent! :</td>
          <td>{this.props.companyAddress}</td>
        </tr>
      )
    }
  }

  // address where the supplier's box is shipped.
  shippingAddress() {
    if (this.props.redeemed && this.props.shippingAddress) {
      let button = ''

      if (this.props.suppliers.find(s => !s.confirmedAt)) {
        button = (
          <Link to={`/a/boxes/${this.props.id}/edit`} className="edit button">
            Modifier
          </Link>
        )
      }

      return (
        <tr>
          <td>Adresse de livraison Partenaires:</td>
          <td>
            {this.props.shippingAddress} {button}
          </td>
        </tr>
      )
    }
  }

  suppliers() {
    if (this.props.suppliers.length) {
      let suppliers = this.props.suppliers.map((s, i) => (
        <Supplier key={i} {...s} boxId={this.props.id} />
      ))

      return (
        <tr className="suppliers">
          <td>Fournisseurs</td>
          <td>{suppliers}</td>
        </tr>
      )
    }
  }

  downloadButton() {
    return (
      <a href={`/orders/${this.props.shopifyInternalId}`}>
        Télécharger / Envoyer les E-Coffrets
      </a>
    )
  }

  deleteButton() {
    if (!this.props.redeemed) {
      return (
        <button
          className="btn delete"
          onClick={e => store.delete(this.props.id)}
        >
          Supprimer
        </button>
      )
    }
  }

  boxType() {
    if (this.props.productName === 'Vente directe') {
      return <span>{this.props.productName}</span>
    } else {
      return (
        <span>
          {this.deliveryMethod()} / {this.props.productName}
          &nbsp;{this.downloadButton()}
        </span>
      )
    }
  }

  buyer() {
    return (
      <div className="buyer-block">
        <h4 className="h4">Acheteur : {this.props.buyerName}</h4>
        <table>
          <tbody>
            <tr>
              <td>Box achetée le:</td>
              <td>{formatDateTime(this.props.createdAt)}</td>
            </tr>

            {this.shopifyId()}

            {this.orderId()}

            <tr>
              <td>Nombre de cadeaux :</td>
              <td>{this.props.giftNumber + 1}</td>
            </tr>

            <tr>
              <td>Type de box:</td>
              <td>{this.boxType()}</td>
            </tr>

            <tr>
              <td>Codes:</td>
              <td>
                {this.props.redeemingId} / {this.props.redeemingCode}
              </td>
            </tr>

            {this.buyerEmail()}
            {this.companyAddress()}
            {this.message()}
          </tbody>
        </table>
      </div>
    )
  }

  redeemer() {
    if (this.props.redeemed) {
      return (
        <div className="buyer-block">
          <h4 className="h4">Utilisateur : {this.props.name}</h4>
          <table>
            <tbody>
              <tr>
                <td>Box redeemed le:</td>
                <td>{formatDateTime(this.props.redeemedAt)}</td>
              </tr>

              {this.email()}
              {this.phoneNumber()}
              {this.shippingAddress()}
              {this.suppliers()}

              <tr>
                <td>
                  <a href={this.statusUrl()}>Page de status</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="box">
        {this.deleteButton()}
        {this.status()}
        <h4 className="h4">ID: {this.props.id}</h4>
        {this.buyer()}
        {this.redeemer()}
      </div>
    )
  }
}
