import React from 'react'
import Icon from './Icon'

export const IconActionClose = props => (
  <Icon {...props}>
    <circle cx="12" cy="12" r="11" />
    <path d="M9 9L15 15" />
    <path d="M9 15L15 9" />
  </Icon>
)
