import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './MenuSecondary.module.scss'
import { Divider, Dropdown, MenuLink } from 'ui'
import { useLocation } from 'react-router-dom'

export const MenuSecondary = props => {
  const location = useLocation()
  const [opened, setOpened] = useState(false)
  const activeLink = props.links.find(
    l => l.to === location.pathname + location.hash || l.to === location.hash
  )

  const getLinks = () => {
    return (
      <nav>
        <ul>
          {props.links.map((link, index) =>
            link.divider ? (
              <Divider key={index} />
            ) : (
              <li className={styles.item} key={index}>
                <MenuLink
                  to={link.to}
                  label={link.label}
                  icon={link.icon}
                  primary={link.primary}
                  light={link.light}
                  value={link.value}
                />
              </li>
            )
          )}
        </ul>
      </nav>
    )
  }

  return (
    <div className={`${styles.menu}`}>
      <div className={styles.opener}>
        <Dropdown
          isOpened={opened}
          onBackgroundClick={() => setOpened(false)}
          onButtonClick={() => setOpened(!opened)}
          onContentClick={() => setOpened(false)}
          iconStart={activeLink?.icon}
          label={activeLink?.label ?? ''}
        >
          <div className={styles.dropdown}>{getLinks()}</div>
        </Dropdown>
      </div>
      <div className={styles.links}>{getLinks()}</div>
    </div>
  )
}

MenuSecondary.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      icon: PropTypes.element,
      label: PropTypes.string,
      light: PropTypes.bool,
      primary: PropTypes.bool,
      divider: PropTypes.bool,
      value: PropTypes.number,
    })
  ),
}
