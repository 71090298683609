import React from 'react'
import styles from './withFade.module.scss'

export const withFade = WrappedComponent => {
  return props => (
    <div className={`${styles.wrapper} ${props.className || ''}`}>
      <WrappedComponent {...props} />
    </div>
  )
}
