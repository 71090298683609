import React from 'react'
import PropTypes from 'prop-types'
import styles from './Typography.module.scss'

export const Title = props => {
  const HtmlTag = props.level !== null ? `h${props.level}` : 'div'
  return <HtmlTag className={styles.title}>{props.children}</HtmlTag>
}

Title.propTypes = { level: PropTypes.number }
Title.defaultProps = { level: null }

export const Subtitle = props => {
  const HtmlTag = props.level !== null ? `h${props.level}` : 'div'
  return <HtmlTag className={styles.subtitle}>{props.children}</HtmlTag>
}

Subtitle.propTypes = { level: PropTypes.number }
Subtitle.defaultProps = { level: null }

export const Heading = props => {
  const HtmlTag = props.level !== null ? `h${props.level}` : 'div'
  return <HtmlTag className={styles.heading}>{props.children}</HtmlTag>
}

Heading.propTypes = { level: PropTypes.number }
Heading.defaultProps = { level: null }

export const Subheading = props => {
  const HtmlTag = props.level !== null ? `h${props.level}` : 'div'
  return <HtmlTag className={styles.subheading}>{props.children}</HtmlTag>
}

Subheading.propTypes = { level: PropTypes.number }
Subheading.defaultProps = { level: null }

export const Major = props => (
  <div className={styles.major}>{props.children}</div>
)

export const MajorActive = props => (
  <div className={styles.majoractive}>{props.children}</div>
)

export const Minor = props => (
  <div className={styles.minor}>{props.children}</div>
)

export const Highlight = props => (
  <div className={styles.highlight}>{props.children}</div>
)

export const Annotation = props => (
  <div className={styles.annotation}>{props.children}</div>
)

export const AnnotationActive = props => (
  <div className={styles.annotationActive}>{props.children}</div>
)
