import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './Card.module.scss'
import { withStatus } from 'ui/hoc'
import { goToAnchor } from 'utils/route'

export const Card = withStatus(props => {
  let modifiers = ''
  if (props.defaultStatus) modifiers += ` ${styles._default}`
  if (props.light) modifiers += ` ${styles._light}`
  if (props.padding) modifiers += ` ${styles._padding}`
  if (props.large) modifiers += ` ${styles._large}`
  if (props.to) modifiers += ` ${styles._link}`

  const isAnchor = props.to?.startsWith('#') || false

  const onClick = () => {
    if (isAnchor) goToAnchor(props.to.substring(1))
  }

  return (
    <div className={`${styles.card}${modifiers} ${props.className}`}>
      <div className={styles.content}>
        {props.to && (
          <Link to={props.to} className={styles.link} onClick={onClick} />
        )}
        {props.children}
      </div>
      {props.actions.length > 0 && (
        <div className={styles.actions}>
          {props.actions.map((action, index) => (
            <div className={styles.action} key={index}>
              {action}
            </div>
          ))}
        </div>
      )}
    </div>
  )
})

Card.displayName = 'Card'

Card.propTypes = {
  ...withStatus.propTypes,
  actions: PropTypes.arrayOf(PropTypes.element),
  padding: PropTypes.bool,
  large: PropTypes.bool,
  to: PropTypes.string,
}

Card.defaultProps = {
  ...withStatus.defaultProps,
  actions: [],
  padding: true,
  large: false,
  to: null,
}
