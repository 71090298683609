import { CustomerShippingResume, Grid } from 'ui'

export const ShippingListResume = ({ shippings }) => (
  <Grid
    cols={1}
    s={3}
    items={shippings.map(s => (
      <div key={s.id}>
        <CustomerShippingResume shipping={getShippingResume(s)} />
      </div>
    ))}
  />
)

const getShippingResume = s => {
  return {
    id: s.id,
    acceptedAt: s.acceptedAt,
    cancelReason: s.cancelReason,
    image: s.image,
    name: s.name,
    receivedAt: s.receivedAt,
    supplierName: s.supplierName,
    sentAt: s.sentAt,
  }
}
