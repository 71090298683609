import React from 'react'
import Icon from './Icon'

export const IconCash = props => (
    <Icon {...props}>
      <path d="M17 9V7C17 5.89543 16.1046 5 15 5H3C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H6.85714" />
      <rect x="7" y="9" width="16" height="10" rx="2" />
      <circle cx="15" cy="14" r="2" />
    </Icon>
  )
