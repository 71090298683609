import React from 'react'
import styles from './ShippingCardDescription.module.scss'
import { formatCents } from 'utils/string'
import { IconTruck } from 'ui'

export const ShippingCardDescription = props => {
  const { shipping: s } = props
  return (
    <div className={styles.description}>
      {s.price > 0 && (
        <div className={styles.price}>Prix public : {formatCents(s.price)}</div>
      )}
      <div className={styles.product}>{s.infos}</div>
      {s.trackingRef?.length > 0 && (
        <div className={styles.tracking}>
          <IconTruck />
          <span className={styles.ref}>
            {s.shipperUrl && (
              <a
                className={styles.shipperUrl}
                rel="noreferrer noopener"
                target="_blank"
                href={s.shipperUrl}
              >
                {s.trackingRef}
              </a>
            )}
            {!s.shipperUrl && <span>{s.trackingRef}</span>}
          </span>
          {s.shipperName && (
            <span className={styles.shipper}>&nbsp;par {s.shipperName}</span>
          )}
        </div>
      )}
    </div>
  )
}
