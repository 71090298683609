import { useRef, useState } from 'react'
import { Button, Divider, Modal, TextArea, TextField } from 'ui'

const INITIAL_STATE = {
  message: '',
  sourceEmail: '',
  sourceName: '',
  targetEmail: '',
}

export const FormSendModal = ({ box, onCancel, onSubmit }) => {
  const [details, setDetails] = useState(INITIAL_STATE)
  const [isValid, setIsValid] = useState(false)
  const refForm = useRef(null)

  const setFormValidity = () => {
    const isValid = refForm?.current?.checkValidity() || false
    setIsValid(isValid)
  }

  const onFieldChange = value => {
    setDetails({ ...details, ...value })
    setFormValidity()
  }

  const cancel = () => {
    onCancel()
    setDetails(INITIAL_STATE)
  }

  const submit = () => {
    onSubmit(details)
    setDetails(INITIAL_STATE)
  }

  return (
    <Modal
      opened={!!box}
      title={`Envoyez votre coffret à un proche`}
      closable
      leftActions={[<Button onClick={cancel}>Annuler</Button>]}
      actions={[
        <Button primary disabled={!isValid} onClick={submit}>
          Envoyer
        </Button>,
      ]}
      onClose={cancel}
    >
      {box && (
        <form ref={refForm}>
          {' '}
          <TextField
            id={'FormTargetEmail'}
            type={'email'}
            label={"L'adresse email de votre proche"}
            initialValue={details.targetEmail}
            required
            autoValidate
            focused
            onChange={targetEmail => onFieldChange({ targetEmail })}
          />
          <Divider small />
          <TextField
            id={'FormSourceName'}
            label={'Votre nom (pour que votre proche vous reconnaisse)'}
            initialValue={details.sourceName}
            required
            autoValidate
            onChange={sourceName => onFieldChange({ sourceName })}
          />
          <Divider small />
          <TextField
            id={'FormSourceEmail'}
            type={'email'}
            label={'Votre adresse email (optionnel, pour recevoir une copie)'}
            initialValue={details.sourceEmail}
            autoValidate
            onChange={sourceEmail => onFieldChange({ sourceEmail })}
          />
          <Divider small />
          <TextArea
            id={'Message'}
            label={`Un petit mot pour votre proche`}
            rows={6}
            autoResize
            initialValue={details.message}
            onChange={message => onFieldChange({ message })}
          />
        </form>
      )}
    </Modal>
  )
}
