import React from 'react'
import { Helmet } from 'react-helmet'
import store from 'stores/PasswordResetPageStore.js'
import UrlParser from 'urlParser.js'
import MainMenu from 'components/MainMenu'

export default class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
    let url = new UrlParser()
    let params = url.getParams()
    store.load(params.key)
  }

  componentWillUnmount() {
    store.unsubscribe(this.updated)
  }

  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          unknown_key: 'Ce lien de réinitialisation est invalide.',
          password_too_short:
            "Utilisez un mot de passe d'au moins 5 caractères s'il vous plait",
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page" id="PasswordResetPage">
        <Helmet>
          <title>Mon compte - Ici Présent!</title>
        </Helmet>
        <MainMenu errors={this.errors()} />

        <div className="group">
          <h1 className="h1">Réinitialisation de votre mot de passe</h1>

          <form
            onSubmit={e => {
              e.preventDefault()
              store.save()
            }}
          >
            <div className="input-group">
              <label>
                Nouveau mot de passe
                <input
                  type="password"
                  className="text-field"
                  value={this.state.password}
                  onChange={e => store.passwordChanged(e.target.value)}
                  required={true}
                  minLength="5"
                />
              </label>
            </div>

            <p>
              <button className="btn">Changer le mot de passe</button>
            </p>
          </form>
        </div>
      </div>
    )
  }
}
