import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import MainMenu from 'components/MainMenu.js'
import Theme from './ThemesPage/Theme.js'
import store from 'stores/Admin/ThemesStore'
import './ThemesPage.scss'

export default class ThemesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  componentDidMount() {
    store.subscribe(state => this.setState(state))
    store.load()
  }

  componentWillUnmount() {
    store.unsubscribe()
  }

  // errors transforms backend error backend to human friendly format.
  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
        }

        let err = messages[error]
        if (!err) console.error('missing translation for backend error:', error)

        return err
      })
      .filter(err => err) // remove null values
  }

  themes() {
    return this.state.themes.map(s => <Theme key={s.id} {...s} />)
  }

  render() {
    return (
      <div className="page admin-page" id="admin-themes-page">
        <Helmet>
          <title>Thèmes - Ici Présent!</title>
        </Helmet>
        <MainMenu adminMenu={true} errors={this.errors()} />

        <div className="group admin-list">
          <h1 className="h1">Liste des thèmes</h1>

          <p className="new-link">
            <Link to="/a/themes/new">Nouveau thème</Link>
          </p>
          <div id="themes" className="feature">
            {this.themes()}
          </div>
        </div>
      </div>
    )
  }
}
