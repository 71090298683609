import React from 'react'
import Icon from './Icon'

export const IconBox = props => (
  <Icon {...props}>
    <path d="M2 4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V8H2V4Z" />
    <path d="M3 8H21V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V8Z" />
    <path d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14V12ZM14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12V14ZM10 14H14V12H10V14Z" />
  </Icon>
)
