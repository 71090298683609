import React from 'react'
import PropTypes from 'prop-types'
import styles from './ReviewCard.module.scss'
import { Card } from 'ui'
import {
  ReviewCardActions,
  ReviewCardBody,
  ReviewCardHeader,
} from './components'

export const ReviewCard = props => (
  <Card padding={false} light>
    <div className={styles.card}>
      <ReviewCardHeader {...props} />
      <ReviewCardBody {...props} />
      <div className={styles.footer}>
        <div className={styles.footerActions}>
          <ReviewCardActions {...props} />
        </div>
      </div>
    </div>
  </Card>
)

ReviewCard.propTypes = {
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  review: PropTypes.shape({
    answer: PropTypes.string,
    answeredAt: PropTypes.string,
    active: PropTypes.bool,
    quarantine: PropTypes.bool,
    customer: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.number,
    inactiveReason: PropTypes.number,
    shipping: PropTypes.shape({
      id: PropTypes.number,
      redeemingCode: PropTypes.number,
      redeemingId: PropTypes.number,
    }),
    supplier: PropTypes.shape({
      name: PropTypes.string,
      uniqueKey: PropTypes.string,
    }),
    rating: PropTypes.number,
  }).isRequired,
}
