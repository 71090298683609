import React from 'react'
import PropTypes from 'prop-types'
import styles from './Figure.module.scss'

export const Figure = props => {
  let modifiers = ''
  if (props.small) modifiers += ` ${styles._small}`

  return (
    <div className={`${styles.figure}${modifiers}`}>
      <div className={styles.number}>{props.value}</div>
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}

Figure.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

Figure.defaultProps = {
  small: false,
}
