import BaseStore from 'stores/BaseStore'
import config from 'config'
import { fetch } from 'utils/api'

class EditSupplierStore extends BaseStore {
  setDefaults() {
    this.state = {
      id: 0,
      success: false,
      name: '',
      emails: '',
      supportEmails: '',
      trackingCodeRequired: false,
      useColissimo: false,
      colissimo_address: '',
      colissimo_city: '',
      colissimo_zipcode: '',
      active: true,
      removed: false,
      inactiveReason: '',
      internalInfos: '',
      errors: [],
      shopifyProducts: { 0: {}, 1: {} },
    }
  }

  async load(id) {
    id = parseInt(id, 10)
    let resp = await fetch(`${config.apiUrl}/api/admin/suppliers/info?id=${id}`)
    let data = await resp.json()

    if (data.success) {
      let {
        name,
        active,
        removed,
        inactiveReason,
        internalInfos,
        trackingCodeRequired,
        useColissimo,
        colissimo_address,
        colissimo_city,
        colissimo_zipcode,
        commission,
        publicProfileUrl,
        editProfileUrl,
        editProductsUrl,
      } = data
      this.setState({
        id,
        name,
        emails: data.emails.join(' '),
        supportEmails: data.supportEmails.join(' '),
        active,
        removed,
        inactiveReason,
        internalInfos,
        trackingCodeRequired,
        useColissimo,
        colissimo_address,
        colissimo_city,
        colissimo_zipcode,
        commission,
        publicProfileUrl,
        editProfileUrl,
        editProductsUrl,
      })
    } else {
      this.setState({ errors: data.errors })
    }
  }

  nameChanged(name) {
    this.setState({ name })
  }

  emailsChanged(emails) {
    this.setState({ emails })
  }

  supportEmailsChanged(supportEmails) {
    this.setState({ supportEmails })
  }

  activeChanged(active) {
    this.setState({ active })
  }

  removeChanged(removed) {
    this.setState({ removed })
  }

  inactiveReasonChanged(inactiveReason) {
    this.setState({ inactiveReason })
  }

  internalInfosChanged(internalInfos) {
    this.setState({ internalInfos })
  }

  commissionChanged(commission) {
    this.setState({ commission })
  }

  trackingCodeRequiredChanged(trackingCodeRequired) {
    this.setState({ trackingCodeRequired })
  }

  useColissimoChanged(useColissimo) {
    this.setState({ useColissimo })
  }

  colissimoAddressChanged(colissimo_address) {
    this.setState({ colissimo_address })
  }

  colissimoZipcodeChanged(colissimo_zipcode) {
    this.setState({ colissimo_zipcode })
  }

  colissimoCityChanged(colissimo_city) {
    this.setState({ colissimo_city })
  }

  async submit() {
    let params = {
      id: this.state.id,
      name: this.state.name.trim(),
      emails: this.state.emails.split(/\s+/).filter(e => e),
      supportEmails: this.state.supportEmails.split(/\s+/).filter(e => e),
      commission: parseFloat(this.state.commission),
      trackingCodeRequired: this.state.trackingCodeRequired,
      useColissimo: this.state.useColissimo,
      active: this.state.active,
      removed: this.state.removed,
      inactiveReason: this.state.inactiveReason.trim(),
      internalInfos: this.state.internalInfos.trim(),
    }

    if (this.state.useColissimo) {
      params.colissimo_address = this.state.colissimo_address.trim()
      params.colissimo_zipcode = this.state.colissimo_zipcode.trim()
      params.colissimo_city = this.state.colissimo_city.trim()
    }

    let body = JSON.stringify(params)

    let resp = await fetch(`${config.apiUrl}/api/admin/suppliers/update`, {
      method: 'POST',
      body,
    })

    if (!resp.ok) {
      alert(resp.statusText)
    }

    let data = await resp.json()
    if (data.exceptions === null) {
      alert('Le fournisseur a bien été mis a jour.')
      window.location.href = '/a/suppliers'
    } else {
      this.setState({ errors: data.exceptions })
    }
  }
}

const store = new EditSupplierStore()
export default store
