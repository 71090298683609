import React from 'react'
import { Helmet } from 'react-helmet'
import './AccountPage.scss'
import store from 'stores/AccountPageStore.js'
import MainMenu from 'components/MainMenu'

export default class AccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = store.state
  }

  updated = state => this.setState(state)

  componentDidMount() {
    store.subscribe(this.updated)
    store.load()
  }

  errors() {
    return this.state.errors
      .map(error => {
        let messages = {
          internal_error:
            'Erreur de serveur. Merci de ré-essayer ultérieurement.',
          incorrect_credentials: 'Mot de passe fourni incorrect.',
        }

        let err = messages[error]
        if (!err) err = error

        return err
      })
      .filter(err => err) // remove null values
  }

  render() {
    return (
      <div className="page" id="AccountPage">
        <Helmet>
          <title>Mon compte - Ici Présent!</title>
        </Helmet>
        <MainMenu errors={this.errors()} />

        <div className="group">
          <h1 className="h1">Mon Compte</h1>
          <p>
            <button className="btn" onClick={_ => store.logout()}>
              Se déconnecter
            </button>
          </p>

          <div className="feature">
            <form
              onSubmit={e => {
                e.preventDefault()
                store.updateEmail()
              }}
            >
              <h2>Mon adresse mail</h2>
              <p>
                <input
                  type="email"
                  className="text-field"
                  value={this.state.email}
                  onChange={e => store.emailChanged(e.target.value)}
                  required={true}
                />
              </p>

              <label>
                Entrez votre mot de passe pour changer d'email
                <input
                  type="password"
                  className="text-field"
                  value={this.state.passwordConfirm}
                  onChange={e => store.passwordConfirmChanged(e.target.value)}
                  required={true}
                />
              </label>

              <p>
                <button className="btn">Changer mon adresse mail</button>
              </p>
            </form>
          </div>

          <div className="feature">
            <form
              onSubmit={e => {
                e.preventDefault()
                store.updatePassword()
              }}
            >
              <h2>Mon mot de passe</h2>

              <label>
                Mot de passe actuel
                <input
                  type="password"
                  className="text-field"
                  value={this.state.oldPassword}
                  onChange={e => store.oldPasswordChanged(e.target.value)}
                  required={true}
                />
              </label>

              <label>
                Nouveau mot de passe
                <input
                  type="password"
                  className="text-field"
                  value={this.state.newPassword}
                  onChange={e => store.newPasswordChanged(e.target.value)}
                  required={true}
                />
              </label>

              <p>
                <button className="btn">Changer mon mot de passe</button>
              </p>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
