import BaseStore from 'stores/BaseStore'
import config from 'config.js'
import { fetch } from 'utils/api'

class NewUserStore extends BaseStore {
  setDefaults() {
    this.state = {
      errors: [],
      email: '',
      isAdmin: true,
      supplierId: null,
    }
  }

  emailChanged(email) {
    this.setState({ email })
  }

  isAdminChanged(isAdmin) {
    this.setState({ isAdmin })
  }

  supplierIdChanged(supplierId) {
    this.setState({ supplierId })
  }

  async submit() {
    let params = {
      email: this.state.email.trim(),
      isAdmin: this.state.isAdmin,
      supplierId: parseInt(this.state.supplierId),
    }

    let body = JSON.stringify(params)
    let resp = await fetch(`${config.apiUrl}/api/admin/users/create`, {
      method: 'POST',
      body,
    })
    let data = await resp.json()

    if (data.success) {
      alert("L'utilisateur a bien été créé.")
      window.location.href = '/a/users'
    } else {
      this.setState({ errors: data.errors })
    }
  }
}

const store = new NewUserStore()
export default store
