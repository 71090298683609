import React from 'react'
import { FeatureAdminReviewsList } from './List'

export const FeatureAdminReviewsToday = props => (
  <FeatureAdminReviewsList
    {...props}
    date={new Date()}
    title="Avis clients d'aujourd'hui"
  />
)
