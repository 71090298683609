import React from 'react'
import styles from './Notifier.module.scss'
import { NotificationManager } from 'services/notifications'
import { Notification } from './Notification'
import { Removable } from 'ui'

export class Notifier extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      removedIndex: [],
      notifications: [],
    }
  }

  componentDidMount = () =>
    NotificationManager.addChangeListener(this.onStoreChange)

  componentWillUnmount = () =>
    NotificationManager.removeChangeListener(this.onStoreChange)

  onStoreChange = notifications => {
    const removedIndex = new Map(notifications.map(item => [item.id, false]))
    this.setState({ notifications, removedIndex })
  }

  onRequestClose = id => {
    const removedIndex = this.state.removedIndex
    removedIndex.set(id, true)
    this.setState({ removedIndex })
  }

  onRemovedEnd = id => NotificationManager.remove(id)

  render() {
    return (
      <div className={styles.notifier}>
        {this.state.notifications.map(notif => (
          <Removable
            key={notif.id}
            removed={this.state.removedIndex.get(notif.id)}
            onRemoved={() => this.onRemovedEnd(notif.id)}
          >
            <div className={styles.notification}>
              <Notification
                id={notif.id}
                onRequestClose={this.onRequestClose}
                timeout={notif.timeout}
                {...notif.statuses}
              >
                {notif.message}
              </Notification>
            </div>
          </Removable>
        ))}
      </div>
    )
  }
}
