import { EventEmitter } from 'events'
import { createUUID } from 'utils/data'

const EVENT_CHANGE = 'change'
const NOTIFICATION_DEFAULT_DURATION = 8000

class InternalNotificationManager extends EventEmitter {
  constructor() {
    super()
    this.list = []
  }

  add(message, statuses = {}) {
    this.list = [
      {
        message,
        statuses,
        timeout: NOTIFICATION_DEFAULT_DURATION,
        id: createUUID(),
      },
      ...this.list,
    ]
    this.emitChange()
  }

  addPrimary(message) {
    this.add(message, { primary: true })
  }

  addInfo(message) {
    this.add(message, { info: true })
  }

  addSuccess(message) {
    this.add(message, { success: true })
  }

  addWarning(message) {
    this.add(message, { warning: true })
  }

  addError(message) {
    this.add(message, { error: true })
  }

  remove(id) {
    this.list = this.list.filter(notif => notif.id !== id)
    this.emitChange()
  }

  emitChange() {
    this.emit(EVENT_CHANGE, this.list)
  }

  addChangeListener(callback) {
    this.addListener(EVENT_CHANGE, callback)
  }

  removeChangeListener(callback) {
    this.removeListener(EVENT_CHANGE, callback)
  }
}

export const NotificationManager = new InternalNotificationManager()
