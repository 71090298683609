import React from 'react'
import PropTypes from 'prop-types'
import styles from './Table.module.scss'
import { Line } from './Line'

export const Table = ({ columns, condensed, lines }) => {
  const nbCols = columns.length || 0

  const formatHeaderCells = () => {
    return columns.map(c => ({
      align: c.align || undefined,
      children: typeof c.label === 'string' ? c.label : c,
      condensed,
      width: c.width || undefined,
    }))
  }

  const formatLineCells = line => {
    const cells = line.cells ? line.cells : line

    return cells
      .concat(new Array(nbCols - cells.length).fill(''))
      .map((children, i) => ({
        children,
        condensed,
        highlight: !!columns[i].highlight || !!line.highlight,
        italic: !!line.italic,
        align: columns[i].align,
      }))
  }

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        {columns.length > 0 && (
          <thead className={styles.head}>
            <Line cells={formatHeaderCells()} header={true} />
          </thead>
        )}
        <tbody>
          {lines.map((l, i) => (
            <Line key={i} cells={formatLineCells(l)} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        align: PropTypes.oneOf(['left', 'center', 'right']),
        label: PropTypes.string,
        width: PropTypes.number,
      }),
    ])
  ).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.shape({
        highlight: PropTypes.bool,
        cells: PropTypes.arrayOf(PropTypes.node),
      }),
    ])
  ).isRequired,
  condensed: PropTypes.bool,
}

Table.defaultProps = {
  condensed: false,
}
