import { useState } from 'react'
import { Button, DateSelector, Modal, RadioList, TextField } from 'ui'
import styles from './FormFilterModal.module.scss'

export const FormFilterModal = ({ opened, onCancel, onSubmit, search }) => {
  const parsedDateMin = parseDate(search.dateMin)
  const parsedDateMax = parseDate(search.dateMax)

  const [query, setQuery] = useState(search.query)
  const [dateMin, setMin] = useState(parsedDateMin)
  const [dateMax, setMax] = useState(parsedDateMax)

  const submit = () => {
    onSubmit({
      query,
      dateMin: dateMin ? dateMin.toLocaleDateString() : '',
      dateMax: dateMax ? dateMax.toLocaleDateString() : '',
    })
  }

  return (
    <Modal
      opened={opened}
      closable={true}
      title={'Filtrer les commandes'}
      onClose={onCancel}
      actions={[
        <Button onClick={onCancel}>Annuler</Button>,
        <Button primary onClick={submit}>
          Valider
        </Button>,
      ]}
    >
      {opened && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div>
              <TextField
                id={'Name'}
                label={'Mot clé à rechercher...'}
                initialValue={query}
                focused={true}
                resetable={true}
                onChange={value => setQuery(value)}
                onEnter={submit}
              />
            </div>
            <div className={styles.dates}>
              <FieldDate
                title="Date de début"
                id="DateMin"
                value={dateMin}
                onChange={setMin}
              />
              <FieldDate
                title="Date de fin"
                id="DateMax"
                value={dateMax}
                onChange={setMax}
              />
            </div>
            <div className={styles.subtitle}>
              Précisez les critères de votre recherche. Vous pouvez entrer un ID
              de commande, nom, prénom, produit, code postal, adresse, etc.
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

function FieldDate({ title, id, value, onChange }) {
  const start = new Date()
  start.setFullYear(start.getFullYear() - 10, 0, 1)
  start.setHours(0, 0, 0, 0)
  const end = new Date()

  return (
    <div className={styles.date}>
      <div className={styles.label}>
        <span>{title}</span>

        <RadioList
          id={id}
          inline
          options={[
            { label: 'Oui', value: 'true' },
            { label: 'Non', value: 'false' },
          ]}
          onChange={value => {
            if (value === 'true') onChange(new Date())
            else onChange(null)
          }}
          value={(!!value).toString()}
        />
      </div>
      <div className={styles.selector}>
        {!!value && (
          <DateSelector
            value={value}
            onChange={onChange}
            min={start}
            max={end}
          />
        )}
      </div>
    </div>
  )
}

function parseDate(dateStr) {
  const [day, month, year] = dateStr.split('/').map(Number)

  if (!day || !month || !year) {
    return null
  }

  const parsedDate = new Date(year, month - 1, day)

  if (
    parsedDate.getFullYear() !== year ||
    parsedDate.getMonth() !== month - 1 ||
    parsedDate.getDate() !== day
  ) {
    return null
  }

  return parsedDate
}
