import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from './ButtonLink.module.scss'
import { withStatus } from 'ui/hoc'

export const ButtonLink = withStatus(props => {
  const { children, external, icon, title } = props

  let modifiers = ''
  if (props.small) modifiers += ` ${styles._small}`
  if (props.light) modifiers += ` ${styles._light}`
  if (icon && !children) modifiers += ` ${styles._icon}`
  if (props.hideLabelOnMobile) modifiers += ` ${styles._hideLabel}`
  if (props.disabled) modifiers += ` ${styles._disabled}`

  // Status
  if (props.primary) modifiers += ` ${styles._primary}`
  else if (props.info) modifiers += ` ${styles._info}`
  else if (props.success) modifiers += ` ${styles._success}`
  else if (props.warning) modifiers += ` ${styles._warning}`
  else if (props.error) modifiers += ` ${styles._error}`
  else modifiers += ` ${styles._default}`

  const className = `${styles.button}${modifiers} ${props.className}`
  const content = (
    <div className={styles.container}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {children && <span className={styles.inner}>{children}</span>}
    </div>
  )

  return external ? (
    <a href={props.to} className={className} title={title}>
      {content}
    </a>
  ) : (
    <Link to={props.to} className={className} title={title}>
      {content}
    </Link>
  )
})

ButtonLink.displayName = 'ButtonLink'

ButtonLink.propTypes = {
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  hideLabelOnMobile: PropTypes.bool,
  icon: PropTypes.element,
  to: PropTypes.string.isRequired,
  small: PropTypes.bool,
  title: PropTypes.string,
  ...withStatus.propTypes,
}

ButtonLink.defaultProps = {
  disabled: false,
  external: false,
  hideLabelOnMobile: false,
  icon: null,
  small: false,
  title: null,
  ...withStatus.defaultProps,
}
