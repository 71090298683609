import React from 'react'
import PropTypes from 'prop-types'
import styles from './Icon.module.scss'

export const ICON_FILL_COLOR = '#262338'

export const Icon = props => {
  const title = props.label ? <title id={props.id}>{props.label}</title> : ''

  let classes = `${styles.icon}`
  if (props.stroke === null) {
    classes += ` ${styles._defaultStroke}`
  }
  if (props.fill === null) {
    classes += ` ${styles._defaultFill}`
  }

  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.stroke}
      fill={props.fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-labelledby={props.id}
      className={classes}
    >
      {title}
      {props.children}
    </svg>
  )
}

Icon.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  label: PropTypes.string,
  width: PropTypes.number,
  stroke: PropTypes.string,
}

Icon.defaultProps = {
  id: null,
  fill: null,
  height: 20,
  label: null,
  stroke: null,
  width: 20,
}

export default Icon
