import React from 'react'
import Icon from './Icon'

export const IconExport = props => (
  <Icon {...props}>
    <path d="M16.8893 6.55469L11.7294 1.36023L6.56951 6.55469" />
    <line
      x1="1"
      y1="-1"
      x2="12"
      y2="-1"
      transform="matrix(0 1 1 0 12.7286 1.25989)"
    />
    <path d="M3 13V18C3 20.7614 5.23858 23 8 23H15C17.7614 23 20 20.7614 20 18V13" />
  </Icon>
)
