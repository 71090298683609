import styles from './ContactDetails.module.scss'

export const ContactDetails = ({ details: d }) => (
  <div className={styles.infoContent}>
    <div className={styles.name}>
      {d.firstname} {d.lastname}
    </div>
    <div className={styles.address}>
      <div className={styles.company}>{d.company}</div>
      <div className={styles.street}>{d.street}</div>
      <div className={styles.city}>
        {d.zipcode} {d.city}
      </div>
      <div className={styles.country}>{d.country}</div>
    </div>
    <div className={styles.contact}>
      <div className={styles.phone}>{d.phone}</div>
      <div className={styles.email}>{d.email}</div>
    </div>
  </div>
)
