import React from 'react'
import styles from './withDisplay.module.scss'

export const withDisplay = Component => {
  return props => {
    const { edit, ...args } = props

    if (!edit) {
      const isRadioList = args.options !== undefined
      const isSelectbox = args.values && args.values.length > 0

      let value = args.initialValue
      if (isRadioList) {
        value = args.options.filter(o => o.value === args.value)[0]?.label
      } else if (isSelectbox) {
        value = args.values.filter(v => v.id == value)[0]?.value
      }

      value = value || '/'

      return (
        <div>
          <div className={styles.label}>{args.label}</div>
          <div>{value}</div>
        </div>
      )
    } else {
      return <Component {...args} />
    }
  }
}
