import React from 'react'
import { getUnresolvedThreads } from 'services/messages'
import { FeatureAdminSupportThreads } from '../components'

export const FeatureAdminSupportThreadsUnresolved = () => (
  <FeatureAdminSupportThreads
    title={'Messages non résolus'}
    callback={getUnresolvedThreads}
  />
)
