import React from 'react'
import PropTypes from 'prop-types'
import styles from './PrepaFacileResults.module.scss'
import { Card, Figure } from 'ui'

export const PrepaFacileResults = props => {
  const { assigned, exceptions } = props

  const isSucceed = exceptions === null || exceptions.length === 0

  return (
    <section className={styles.results}>
      {assigned === null && (
        <div className={styles.nofile}>
          <span className={styles.title}>Aucun fichier importé</span>
          <span className={styles.annotation}>
            Veuillez cliquer sur le bouton ci-dessus pour débuter l'import
          </span>
        </div>
      )}

      {assigned !== null && (
        <div className={styles.container}>
          <div className={styles.stats}>
            <span className={styles.title}>Résultats de l'import</span>
            <div className={styles.stats}>
              <Figure
                label={'Numéro de suivi assigné'}
                value={assigned.toString()}
                small
              />
            </div>
          </div>

          {isSucceed && (
            <div className={styles.succeed}>
              <span className={styles.title}>
                L'import s'est déroulé avec succès
              </span>
            </div>
          )}

          {!isSucceed && (
            <div className={styles.errors}>
              <span className={styles.title}>Erreurs</span>

              {exceptions?.map(ex => (
                <Card light warning key={ex}>
                  {ex}
                </Card>
              ))}
            </div>
          )}
        </div>
      )}
    </section>
  )
}

PrepaFacileResults.propTypes = {
  assigned: PropTypes.number,
  exceptions: PropTypes.arrayOf(PropTypes.string),
}
