import React from 'react'

/*
 * Properties:
 *
 * * id             [Number]
 * * name           [String]
 * * codename       [String]
 * * imageUrl       [String]
 * * catalogUrl     [String]
 * * ean13codes     [Boolean]
 *
 */
export default class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ean13: '' }
  }

  open(url) {
    window.location.href = url
  }

  hasEAN13codes() {
    return this.props.ean13codes.filter(a => a).length > 0
  }

  ean13() {
    if (this.hasEAN13codes()) {
      let options = this.props.ean13codes.map((c, i) => (
        <option key={c} value={c}>
          {i + 1} coffrets
        </option>
      ))
      return (
        <span>
          <select
            value={this.state.ean13}
            onChange={e => this.setState({ ean13: e.target.value })}
          >
            <option value="">--</option>
            {options}
          </select>
          &nbsp;
          <button
            className="btn"
            disabled={!this.state.ean13}
            onClick={e => this.open(`/packagers/ean/13/${this.state.ean13}`)}
          >
            Codes EAN13
          </button>
        </span>
      )
    }
  }

  render() {
    return (
      <div className="product list-item">
        <img src={this.props.imageUrl} alt={this.props.name} />
        <h3 className="h3">{this.props.name}</h3>

        <div className="actions">
          <button
            className="btn"
            onClick={e => this.open(`/a/products/${this.props.id}/edit`)}
          >
            Modifier
          </button>

          <button
            className="btn"
            onClick={e => this.open(this.props.catalogUrl)}
          >
            Catalogue
          </button>

          {this.ean13()}
        </div>
      </div>
    )
  }
}
